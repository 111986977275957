import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {displayJobInputStatusEnum, InputStatusEnum} from "../../services/classes/StatusEnums";
import {noop} from "rxjs";
import {JobInput} from "../../services/classes/JobProcessing";

type Props = { jobInput: JobInput };
export const JobInputStepChooser: React.FC<Props> = observer(({jobInput}) => {
    const {jobLoadingStore} = useStores();

    useEffect(() => {
        const loading = jobLoadingStore.onLoadJobInput(jobInput);
        if (loading) {
            const sub = loading.subscribe();
            return () => sub.unsubscribe();
        } else return noop;
    }, [])

    if(jobInput.current_status.status === InputStatusEnum.I_ERROR) {
        return <p>Parsing failed</p>;
    }
    return <p>job input status = {displayJobInputStatusEnum(jobInput.current_status.status)}</p>;
    return <></>;
});

