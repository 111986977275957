import React from "react";
import {observer} from "mobx-react-lite";
import {ConfigureStepView} from "./config/ConfigureStepView";

export const JobDemo: React.FC = observer(() => {
    return <>
        <p>ConfigureStepView:</p>
        <ConfigureStepView/>
    </>;
});
