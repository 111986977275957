import React from "react";
import {CURRENCY_FORMATTER, CURRENCY_SYMBOL} from "../../constants";

const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

function nFormatter(num, digits): [number, string] {
    const lookup: ({ symbol: string; value: number })[] = [
        {value: 1, symbol: ""},
        {value: 1e3, symbol: "k"},
        {value: 1e6, symbol: "M"},
        {value: 1e9, symbol: "G"},
        {value: 1e12, symbol: "T"},
        {value: 1e15, symbol: "P"},
        {value: 1e18, symbol: "E"}
    ];
    const item = lookup.slice().reverse().find(item => num >= item.value);
    const value = item ? num / item.value : num;
    const valueLeftDigits = value === 0 ? 0 : Math.ceil(Math.log10(value));
    const valueRightDigits = digits - valueLeftDigits;
    const valueRounding = Math.pow(10, valueRightDigits);
    const valueStripped = Math.round(value * valueRounding) / valueRounding;
    const postFix = item ? item.symbol : '';
    // return valueStripped.toFixed(valueRightDigits).replace(rx, "$1") + postFix;
    return [valueStripped, postFix];
}

export function toCurrency(v: number, nDigits = 3): string {
    const neg = v < 0;
    const absV = Math.abs(v);
    const [valueStripped, postFix] = nFormatter(absV, nDigits);
    return CURRENCY_SYMBOL
        + ' '
        + (neg ? '-' : '')
        + valueStripped.toFixed(nDigits).replace(rx, "$1")
        + postFix;
}

type Props = { v: number, nDigits?: number };
export const CurrencyComponent: React.FC<Props> = ({v, nDigits}) => {
    let str;
    const vanillaStr = CURRENCY_FORMATTER.format(v);
    if (nDigits === undefined) {
        str = vanillaStr;
    } else {
        str = toCurrency(v, nDigits);
    }
    return <span title={vanillaStr}>{str}</span>
    // return <>
    //     {'' + CURRENCY_FORMATTER.format(Math.round(v))}
    // </>
    // CURRENCY_FORMATTER
    //             .formatToParts(valueStripped)
    //             .map(({type, value}) => {
    //                 switch (type) {
    //                     case 'integer':
    //                         return value + postFix;
    //                     default :
    //                         return value;
    //                 }
    //             })
    //             .reduce((string, part) => string + part)
};
