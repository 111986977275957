import {colors} from "./colors";
import {ThemeOptions} from "@material-ui/core/styles/createMuiTheme";

// @see: https://material-ui.com/customization/default-theme/
export const theme: ThemeOptions = {
    palette: {...colors},
    typography: {
        h1: {
            fontSize: '6rem',
        },
        h2: {
            // Page title
            fontSize: '3.75rem',
        },
        h3: {
            fontSize: '3rem',
        },
        body1: {
            fontSize: '1rem',
        },
        body2: {
            fontSize: '0.875rem',
        },
    },
    // spacing = N * 8px (https://material-ui.com/customization/spacing/)
};
