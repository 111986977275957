import React from "react";
import {CopyrightSharp} from "@material-ui/icons";
import {Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            color: theme.palette.grey[200],
            marginTop: theme.spacing(2),
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
        },
        cIcon: {
            fontSize: '1em',
            marginRight: '0.2em',
        }
    }),
);

export const Footer: React.FC = () => {
    const classes = useStyles();
    return <Typography className={classes.footer} variant="body1" component="footer" id="main-footer">
        <CopyrightSharp className={classes.cIcon}>(c)</CopyrightSharp>
        MithraAI 2021
    </Typography>;
};
