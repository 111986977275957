import React from "react";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router";
import {Button, Grid, Typography} from "@material-ui/core";
import './KoiFacade.scss'
import {HelpComponent} from "../help-component/HelpComponent";
import {ArrowBack} from "@material-ui/icons";

type Props = {
    // avatar?: React.ReactNode;
    title: string;
    intro?: React.ReactNode;
    back?: string;
    helpContent?: React.ReactNode;
    className?: string;
}

export const KoiFacade: React.FC<Props> = observer(({children, title, back, intro, helpContent, className}) => {
    const history = useHistory();

    return <div className={"koi-facade" + (className ? ` ${className}` : '')}>
        <Grid container>
            <Grid container item className="header" alignItems="center">
                <Grid item xs={2} className="text-center">
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<ArrowBack/>}
                        onClick={() => history.push('' + back)}>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="h2" component="h1">
                        {title}
                        {helpContent ? <HelpComponent helpContent={helpContent}/> : <></>}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={2}/>
            <Grid item xs={10} className="intro">
                {intro}
            </Grid>
        </Grid>
        {children}
    </div>;
});
