import React from "react";

export const KeyTaxonomyFactsIcon: React.FC = () => {
    return <svg viewBox="0 0 456.769 456.769" width={50} height={50}>
        <g>
            <g>
                <path style={{fill: '#010002'}} d="M417.321,360.344l-56.981-93.04c14.835-6.991,24.548-21.955,24.548-38.456
			c0-23.54-19.159-42.691-42.716-42.691c-5.706,0-11.339,1.227-16.818,3.658L254.229,78.786c5.349-7.381,8.161-15.916,8.161-24.833
			c0-23.54-19.135-42.691-42.667-42.691c-23.54,0-42.691,19.151-42.691,42.691c0,10.453,3.764,20.338,10.648,28.157l-66.955,104.517
			c-2.081-0.317-4.162-0.471-6.251-0.471c-23.54,0-42.683,19.151-42.683,42.691c0,16.834,9.966,31.913,25.231,38.749l-54.746,89.357
			C18.931,357.174,0,376.243,0,399.645c0,23.54,19.151,42.691,42.683,42.691c23.54,0,42.691-19.151,42.691-42.691
			c0-16.038-8.893-30.474-22.988-37.79l52.502-85.7l53.884,87.951c-15.184,6.877-25.109,21.923-25.109,38.708
			c0,23.54,19.151,42.683,42.699,42.683c21.549,0,39.44-16.07,42.277-36.847c4.154,19.232,21.305,33.693,41.732,33.693
			c23.548,0,42.691-19.151,42.691-42.691c0-16.038-8.885-30.458-22.971-37.79l52.502-85.7l53.892,87.951
			c-15.168,6.877-25.101,21.923-25.101,38.708c0,23.54,19.135,42.683,42.691,42.683c23.532,0,42.691-19.143,42.691-42.683
			C456.785,380.308,439.601,362.01,417.321,360.344z M270.389,422.617c-12.664,0-22.947-10.307-22.947-22.971
			s10.291-22.955,22.947-22.955c12.689,0,22.971,10.299,22.971,22.955C293.36,412.318,283.078,422.617,270.389,422.617z
			 M186.38,425.779c-12.672,0-22.971-10.307-22.971-22.963s10.299-22.955,22.971-22.955c12.656,0,22.955,10.307,22.955,22.955
			C209.335,415.48,199.036,425.779,186.38,425.779z M114.467,205.885c12.672,0,22.971,10.299,22.971,22.955
			c0,12.672-10.299,22.971-22.971,22.971c-12.656,0-22.955-10.307-22.955-22.971C91.511,216.183,101.81,205.885,114.467,205.885z
			 M365.144,228.848c0,12.672-10.291,22.971-22.971,22.971c-12.664,0-22.947-10.307-22.947-22.971
			c0-12.656,10.291-22.955,22.947-22.955C354.861,205.885,365.144,216.183,365.144,228.848z M219.724,30.974
			c12.672,0,22.979,10.299,22.979,22.971s-10.307,22.971-22.979,22.971c-12.656,0-22.955-10.307-22.955-22.971
			C196.76,41.273,207.059,30.974,219.724,30.974z M414.094,425.779c-12.656,0-22.971-10.307-22.971-22.963
			s10.323-22.955,22.971-22.955c12.664,0,22.947,10.307,22.947,22.955C437.041,415.48,426.75,425.779,414.094,425.779z
			 M42.683,422.617c-12.672,0-22.971-10.307-22.971-22.971s10.299-22.955,22.971-22.955s22.971,10.299,22.971,22.955
			C65.654,412.318,55.356,422.617,42.683,422.617z M228.104,393.777c-3.885-18.184-19.289-31.994-38.481-33.425l-57.006-93.04
			c14.835-6.991,24.548-21.955,24.548-38.456c0-13.859-6.633-26.532-17.94-34.579l64.63-100.851
			c11.673,4.812,24.386,4.064,35.294-1.585l70.345,109.76c-6.454,7.706-9.99,17.273-9.99,27.263
			c0,16.834,9.966,31.913,25.215,38.749l-54.738,89.366C248.629,357.165,230.957,373.13,228.104,393.777z"/>
            </g>
        </g>
    </svg>
}
