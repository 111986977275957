import axios, {AxiosInstance} from "axios";
import {environment} from "../env";
import MithraApi from "./MithraApi";

export function buildAxiosInstance() {
    const http = axios.create({
        baseURL: environment.apiUrl,
        headers: {
            "Content-type": "application/json"
        }
    });
    if (environment.apiDelay > 0) {
        http.interceptors.request.use(c => {
            return new Promise(r => setTimeout(() => r(c), environment.apiDelay));
        });
    }
    return http;
}


/**
 * @see https://stackoverflow.com/questions/35855781/having-services-in-react-application
 */
export abstract class ApiService {
    constructor(protected http: AxiosInstance) {
    }
}

/**
 * @see https://mobx.js.org/defining-data-stores.html#example-domain-store
 */
export abstract class ApiStore<T extends ApiService = MithraApi> {
    protected constructor(protected api: T) {
    }
}
