import Toolbar from "@material-ui/core/Toolbar";
import {AppBar} from "@material-ui/core";
import React from "react";
import {UserComponent} from "../../auth/UserComponent";

export const Header: React.FC = () => {
    return <AppBar position="static" id="main-header">
        <Toolbar>
            {/*<img src="/logo_180x40/Mithra_logo.png" alt="Mithra" className="mithra-logo"/>*/}
            <img src="/logo_135x30/Mithra_logo.png" alt="Mithra" className="mithra-logo"/>
            <div style={{flexGrow: 1}}/>
            <UserComponent/>
        </Toolbar>
    </AppBar>;
}
