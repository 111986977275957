import {JobInputStatus_isPending, InputStatusEnum, JobStatusEnum} from "./StatusEnums";
import {DataInputIsReadyToStart} from "../ApiHelpers";

export declare type Job = JobSerializer;
export declare type JobInput = JobInputSerializer;

type url = string;
type file_url = string;

export interface JobStatusSerializer {
    id: number;
    data_bag: url;
    timestamp: string;
    status: JobStatusEnum;
    task: string;
    name: JobStatusEnum;
}

export interface SimpleJobStatusSerializer {
    timestamp: string;
    status: JobStatusEnum;
    task: string;
    name: JobStatusEnum;
}

export interface JobMetaDataSerializer {
    key: string;
    data: string;
}

export declare type JobMeta = JobMetaDataSerializer;

export interface JobInputStatusSerializer {
    id: number;
    data_input: url;
    timestamp: string;
    status: InputStatusEnum;
    name: InputStatusEnum;
}

export interface SimpleJobInputStatusSerializer {
    timestamp: string;
    status: InputStatusEnum;
    name: InputStatusEnum;
}

export interface JobInputSerializer {
    id: number;
    author: number; // Note: Author is always by ID
    data_bag: url;
    parser_setting: string;
    current_status: SimpleJobInputStatusSerializer;
    input_file: file_url;
    dataset_file: file_url;
    dataframe_file: file_url;
}

export interface JobSerializer {
    id: number;
    name: string;
    timestamp: string;
    merge_setting: string;
    current_status: SimpleJobStatusSerializer;
    meta: JobMetaDataSerializer[];
    inputs: JobInputSerializer[];
}

interface _JobInputCreationSerializer_POST {
    author: number; // Note: Author is always by ID
    input_file: File;
    parser_setting?: string;
}

export interface JobInputCreationSerializer_POST extends _JobInputCreationSerializer_POST {
    data_bag: number;
}

export interface JobInputCreationSerializer_RESPONSE {
    id: number;
    author: number; // Note: only here it's an ID
    input_file: file_url;

    data_bag: url;
    data_bag_obj: JobSerializer;

    parser_setting: string;

    current_status: SimpleJobInputStatusSerializer;
    dataset_file: file_url;
    dataframe_file: file_url;
}


export interface JobCreationSerializer_POST {
    name: string;
    merge_setting?: string;
}

export interface JobCreationSerializer_RESP {
    id: number;
    name: string;
    merge_setting: string;

    timestamp: string;
    current_status: SimpleJobStatusSerializer;
    meta: JobMetaDataSerializer[];
    // No inputs
}

export interface FullJobCreationSerializer_POST extends _JobInputCreationSerializer_POST {
    data_bag: JobCreationSerializer_POST;
}

export function convertJob(data: JobCreationSerializer_RESP, inputs: JobInputSerializer[]): Job {
    return {...data, inputs};
}

export function convertJobInput(data: JobInputCreationSerializer_RESPONSE): JobInput {
    const c: JobInput = {...data};
    delete c['data_bag_obj'];
    return c;
}

export declare type JobInputIndex = number; // An index of the Job.inputs array


export function getPendingJobInputs(j: Job): JobInput[] {
    return j.inputs.filter(ji => JobInputStatus_isPending(ji.current_status.status));
}

export function getStartableDataInputs(db: Job): JobInput[] {
    return db.inputs.filter(di => DataInputIsReadyToStart(di));
}

export function canChangeOnServer(j: Job) {
    switch (j.current_status.status) {
        case JobStatusEnum.B_CONFIGURING:
        case JobStatusEnum.B_PROCESSING:
        case JobStatusEnum.B_BUSY:
            return true;
        case JobStatusEnum.B_READY:
        case JobStatusEnum.B_ERROR:
        case JobStatusEnum.B_DISCARDED:
            return false;
    }
}
