import {DropTargetHookSpec} from "react-dnd/lib/interfaces";
import {NativeTypes} from "react-dnd-html5-backend";
import {DropTargetMonitor} from "react-dnd";

export type DropProps = {
    isOver: boolean,
    canDrop: boolean,
    result: File[],
};
export type DropInterfaceT = {
    onDrop: (file: File) => void,
    onError: (err: string) => void,
}
export const buildDropSpec: (i: DropInterfaceT) => DropTargetHookSpec<any, void, DropProps> =
    ({onDrop, onError}) => ({
            accept: [NativeTypes.FILE],
            drop(item: any, monitor: DropTargetMonitor) {
                if (!monitor) {
                    console.warn('DropTargetMonitor not found', monitor);
                    return;
                }
                const files: FileList = monitor.getItem().files
                if (files.length !== 1) {
                    onError('Please select only 1 file');
                    return;
                }
                const file = files[0];
                if (!isOkFile(file)) {
                    onError('Please home an Excel (.xlsx) file');
                    return;
                }
                onDrop(file);
            },
            collect: (monitor: DropTargetMonitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                result: monitor.getDropResult(),
            }),
        }
    );

export function isOkFile(file: File) {
    return file.name.endsWith('.xlsx') || file.name.endsWith('.7z');
}
