import React from "react";

export const PurchasePriceVarianceIcon: React.FC = () => {
    return <svg viewBox="0 0 64 64" width={50} height={50}>
        <g>
            <path
                d="m49 27c.552 0 1 .448 1 1h2c0-1.654-1.346-3-3-3v-2h-2v2h-3v3c0 1.654 1.346 3 3 3h2c.552 0 1 .448 1 1v1h-3c-.552 0-1-.448-1-1h-2c0 1.654 1.346 3 3 3v2h2v-2h3v-3c0-1.654-1.346-3-3-3h-2c-.552 0-1-.448-1-1v-1z"/>
            <path
                d="m48 19c-6.065 0-11 4.935-11 11s4.935 11 11 11 11-4.935 11-11-4.935-11-11-11zm0 20c-4.963 0-9-4.037-9-9s4.037-9 9-9 9 4.037 9 9-4.037 9-9 9z"/>
            <path
                d="m28 53c-.771 0-1.468.301-2 .78-.532-.48-1.229-.78-2-.78-1.654 0-3 1.346-3 3s1.346 3 3 3c.771 0 1.468-.301 2-.78.532.48 1.229.78 2 .78 1.654 0 3-1.346 3-3s-1.346-3-3-3zm-4 4c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1zm4 0c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1z"/>
            <path d="m13 57h2v2h-2z"/>
            <path d="m5 57h2v2h-2z"/>
            <path d="m9 57h2v2h-2z"/>
            <path d="m5 53h10v2h-10z"/>
            <path
                d="m63 30c0-7.934-6.195-14.431-14-14.949v-14.051h-8v1c0 .552-.448 1-1 1s-1-.448-1-1v-1h-8v1c0 .552-.448 1-1 1s-1-.448-1-1v-1h-8v1c0 .552-.448 1-1 1s-1-.448-1-1v-1h-8v40h-7c-1.654 0-3 1.346-3 3v16c0 1.654 1.346 3 3 3h28c1.654 0 3-1.346 3-3v-11h9v10c0 2.206 1.794 4 4 4s4-1.794 4-4v-14.558c6.333-1.757 11-7.557 11-14.442zm-59 13h28c.552 0 1 .448 1 1v1h-30v-1c0-.552.448-1 1-1zm29 4v2h-30v-2zm-1 14h-28c-.552 0-1-.448-1-1v-9h30v9c0 .552-.448 1-1 1zm3-14v-3c0-1.654-1.346-3-3-3h-19v-38h4.171c.413 1.164 1.525 2 2.829 2s2.416-.836 2.829-2h4.342c.413 1.164 1.525 2 2.829 2s2.416-.836 2.829-2h4.342c.413 1.164 1.525 2 2.829 2s2.416-.836 2.829-2h4.171v12.051c-7.805.518-14 7.015-14 14.949 0 6.885 4.667 12.685 11 14.442v2.558zm15 12c0 1.103-.897 2-2 2s-2-.897-2-2v-14.149c.656.088 1.32.149 2 .149s1.344-.061 2-.149zm-2-16c-7.168 0-13-5.832-13-13s5.832-13 13-13 13 5.832 13 13-5.832 13-13 13z"/>
            <path d="m45 7h-30v8h30zm-2 6h-26v-4h26z"/>
            <path d="m15 17h2v2h-2z"/>
            <path d="m19 17h12v2h-12z"/>
            <path d="m15 21h16v2h-16z"/>
            <path d="m29 25h2v2h-2z"/>
            <path d="m15 25h12v2h-12z"/>
            <path d="m15 29h16v2h-16z"/>
            <path d="m15 33h16v2h-16z"/>
            <path d="m15 37h2v2h-2z"/>
            <path d="m19 37h12v2h-12z"/>
        </g>
    </svg>
}
