import {Typography} from "@material-ui/core";
import React from "react";

export default () => <>
    <Typography color="textSecondary" gutterBottom>
        KOI: Spend concentration
    </Typography>
    {/*<Typography variant="h5">*/}
    {/*    Buy from less*/}
    {/*</Typography>*/}
    <Typography component="p">
        The lower spend concentration percentage the higher the potential captive value
    </Typography>
</>;
