import React, {useEffect} from "react";
import {useStores} from "../../../stores";
import {Button, Grid} from "@material-ui/core";
import {generatePath} from "react-router";
import {routes} from "../../../routing/routes";
import {cum_suppliers_all_groups_Response} from "../../../services/ApiTypes";
import {BarChart, BarDataPoint} from "../../../components/visualization/BarChart";
import Typography from "@material-ui/core/Typography";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import SpendConcentrationHelp from "./SpendConcentrationHelp";
import {AnimatedCombinedParetoChart, Data} from "../../../components/visualization/pereto/AnimatedCombinedParetoChart";
import './KoiSpendConcentration.scss';
import {CurrencyComponent, toCurrency} from "../../../components/currency-component/CurrencyComponent";
import {fromPromise} from "rxjs/internal-compatibility";
import {map} from "rxjs/operators";
import {forkJoin} from "rxjs";
import {DataColumn, DataTable} from "../../../components/data-table/DataTable";
import {useTheme} from "@material-ui/core/styles";
import {Job} from "../../../services/classes/JobProcessing";

const tableColumns: DataColumn[] = [
    {
        dataIndex: 'p__id',
        headerText: 'Part',
    },
    {
        dataIndex: 'p__description',
        headerText: 'Description',
        ltr: true,
    },
    {
        dataIndex: 'p__spend',
        headerText: 'Total spend',
        dataCategory: 'currency',
        help: 'Total opportunity if all transactions were at the minimal unit price',
    },
    {
        dataIndex: 'p__quantity',
        headerText: 'Quantity',
    },
]

type KeyValues = {
    uncategorizedSpend: number,
    topXSpend: number,
    purchasePriceVariance: number,
    totalSpend: number,
    totalSuppliers: number,
};
export const KOISpendConcentration: React.FC = () => {
    const theme = useTheme();

    const {jobStore} = useStores();
    const job = jobStore.job as Job;
    if (!job) throw new Error();

    const [keyValues, setKeyValues] = React.useState<KeyValues | undefined>(undefined);
    const [groupedData, setGroupedData] = React.useState<BarDataPoint[] | undefined>(undefined);

    const [selectedCategoryL1, setSelectedCategoryL1] = React.useState<BarDataPoint | undefined>(undefined);
    const [selectedSupplier, setClickedSupplier2] = React.useState<Data | undefined>(undefined);

    useEffect(() => {
        const sub = forkJoin([
            fromPromise(jobStore.getJobStats()),
            fromPromise(jobStore.getJobKoiCumSuppliersL1Summary()),
            fromPromise(jobStore.getJobKoiCumSuppliers(1)),
            fromPromise(jobStore.getTotalPriceVarianceGroups()),
        ])
            .pipe(map(([stats, l1Resp, topResp, pvvResp]) => {
                const groupedData = Object.entries(l1Resp.data.stats)
                    .sort(([, a], [, b]) => b.total_var - a.total_var)
                    .map<BarDataPoint>(([label, s]) => ({
                        value: s.top_total_var__perc,
                        valueLabel: `${toCurrency(s.total_var)}  |  ${Math.round(s.top_total_var__perc * 100) + '%'}`,
                        category: label,
                        categoryLabel: label === '' ? '<uncategorized>' : '',
                    }))
                return [{
                    uncategorizedSpend: stats.data.unclassified_spend,
                    topXSpend: topResp.data.stats.top_total_var__perc,
                    purchasePriceVariance: pvvResp,
                    totalSpend: stats.data.total_spend,
                    totalSuppliers: stats.data.n_suppliers,
                }, groupedData] as [KeyValues, BarDataPoint[]];
            }))
            .subscribe(([keyValues, groupedData]) => {
                setKeyValues(keyValues);
                setGroupedData(groupedData);
            });
        return () => sub.unsubscribe();
    }, [jobStore]);

    const [cumSuppliersL1, setCumSuppliersL1] = React.useState<cum_suppliers_all_groups_Response | undefined>(undefined);
    useEffect(() => {
        const sub = fromPromise(jobStore.getJobKoiCumSuppliersAllGroups())
            .subscribe(resp => setCumSuppliersL1(resp.data));
        return () => sub.unsubscribe();
    }, [jobStore]);

    const [supplierTable, setSupplierTable] = React.useState<any[] | undefined>(undefined);
    useEffect(() => {
        if (selectedSupplier === undefined) {
            setSupplierTable(undefined);
        } else {
            const sub = fromPromise(jobStore.getPartData(selectedSupplier.name))
                .subscribe(resp => setSupplierTable(resp.data))
            return () => sub.unsubscribe();
        }
    }, [jobStore, selectedSupplier])

    const [downloading, setDownloading] = React.useState<boolean>(false);
    const startSpendDownload = () => {
        setDownloading(true);
        // TODO: add downloadPPV
        jobStore.downloadPPV()
            .then(resp => {
                const url = URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ProductPriceVariance.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            })
            .finally(() => {
                setDownloading(false);
            })
    }

    return <KoiFacade
        title="Spend concentration"
        helpContent={<SpendConcentrationHelp/>}
        intro={<>
            {/*<Typography component="p">*/}
            {/*    The Pareto principle states that for many outcomes, roughly 80% of consequences come from 20% of the*/}
            {/*    causes (the “vital few”).[1] Other names for this principle are the 80/20 rule, the law of the vital*/}
            {/*    few, or the principle of factor sparsity.*/}
            {/*</Typography>*/}
            {/*<Typography component="p">*/}
            {/*    Below the curve is split into three graphs*/}
            {/*</Typography>*/}
        </>}
        back={generatePath(routes.job_dashboard, {id: job.id})}
        className="koi-spend-concentration-page"
    >
        <div className="page-item">
            {/* Header */}
            <Grid container justify="space-evenly">
                {/*<Grid item xs={2}>*/}
                {/*    /!*<Typography variant="subtitle1">Spend per category</Typography>*!/*/}
                {/*</Grid>*/}
                <Grid item xs={5}>
                    <Typography variant="h6" align="center">Spend concentration per category</Typography>
                </Grid>
                <Grid item xs={4}/>
            </Grid>

            <Grid container justify="space-evenly">
                {/*<Grid item xs={2}/>*/}
                <Grid item xs={5}>
                    <div style={{height: '352px'}}>
                        {!groupedData
                            ? <p>Loading</p>
                            : <BarChart
                                horizontal
                                width={500} height={350}
                                // data={groupedData.filter((_, i) => i <= 9)}
                                data={groupedData}
                                redLine={.8}
                                labelMargin={200}
                                categoryAxisTitle="L1 categories (Sorted by total spend)"
                                valueAxisPercentage
                                valueAxisTitle="Relative spend of the top 20% suppliers"
                                onClicked={setSelectedCategoryL1}
                            />
                            //            <p className="text-center mb-0" style={{marginLeft: '12%'}}>
                            //                     The number of records per category
                            //                 </p>
                        }
                    </div>

                </Grid>
                <Grid item xs={4}
                      container justify="space-around" style={{alignContent: 'space-around'}} alignItems="center">

                    <Grid className="key-value-container text-center"
                          item xs={11}
                          container spacing={4}>
                        {/* Row 1 */}
                        <Grid item
                              container
                              style={{justifyContent: "space-between"}}>
                            <Grid item>
                                <Typography variant="caption">
                                    Total<br/>
                                    Suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.totalSuppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Spend<br/>
                                    concentration
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? (Math.round(keyValues.topXSpend * 100) + '%')
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Purchase Price<br/>
                                    Variance
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? <CurrencyComponent v={keyValues.purchasePriceVariance} nDigits={3}/>
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* Row 2 */}
                        <Grid item
                              container
                              style={{justifyContent: "space-evenly"}}>
                            <Grid item>
                                <Typography variant="caption">
                                    Total spend
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? <CurrencyComponent v={keyValues.totalSpend} nDigits={3}/>
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Unclassified
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {(keyValues)
                                        ? <CurrencyComponent v={keyValues.uncategorizedSpend} nDigits={3}/>
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={9}>
                        <Typography variant="body1">
                            Select a category to see details below.
                            Spend concentration, or 80/20 analysis, shows supplier fragmentation;<br/>
                        </Typography>
                    </Grid>

                </Grid>
                {/*<Grid item xs={2}/>*/}
            </Grid>
        </div>

        <div className="pt-1">
            <Grid container justify="space-evenly" className="page-item">
                <Grid item xs={5}>
                    <Typography variant="h6" align="center">
                        {selectedCategoryL1 === undefined
                            ? 'Spend per supplier'
                            : `Spend per supplier (${selectedCategoryL1.category || '<uncategorized>'})`
                        }
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="outlined"
                        disabled={!selectedCategoryL1}
                        color="primary"
                        size="small"
                        // endIcon={<Undo>All categories</Undo>}
                        onClick={() => setSelectedCategoryL1(undefined)}>
                        Show all categories
                    </Button>
                </Grid>
            </Grid>

            <Grid container justify="space-evenly">
                <Grid item xs={8}>
                    <div style={{height: '267px'}}>
                        {!cumSuppliersL1
                            ? <></>
                            // Add a X axis to the AnimatedCombinedParetoChart
                            : <>
                                <AnimatedCombinedParetoChart
                                    data={cumSuppliersL1.suppliers}
                                    width={800} height={270}
                                    showPP={true} paretoParam={0.8}
                                    onClick={setClickedSupplier2}
                                    selectedGroup={selectedCategoryL1 === undefined ? undefined : selectedCategoryL1.category}
                                />
                            </>
                        }
                    </div>
                </Grid>
            </Grid>
        </div>

        {/*<Grid container justify="space-evenly" className="page-item">*/
        }
        {/*    <Grid item xs={12}>*/
        }
        {/*        <p>clickedSupplier2 = {JSON.stringify(selectedSupplier)}</p>*/
        }
        {/*    </Grid>*/
        }
        {/*</Grid>*/
        }

        <div className="page-item">
            <Grid container justify="flex-end">
                <Grid item xs={2} style={{textAlign: 'center', marginBottom: theme.spacing(2)}}>
                    <Button
                        style={{width: '13em'}}
                        variant="outlined"
                        color="secondary"
                        onClick={() => startSpendDownload()}
                        disabled={downloading}>
                        {downloading ? 'Downloading...' : 'Download all (*)'}
                    </Button>
                </Grid>
                <Grid item xs={2}/>
            </Grid>
            <Grid container justify="space-evenly">
                <Grid item xs={2}>
                    {selectedSupplier === undefined
                        ? <></>
                        : <Typography variant="subtitle1">
                            Transactions related to<br/>
                            {selectedSupplier.name}
                        </Typography>}
                </Grid>
                <Grid item xs={8} style={{minHeight: '10em'}}>

                    <DataTable
                        className="parts-table alt-rows"
                        tableColumns={tableColumns}
                        data={supplierTable}
                        emptyCaption="Select a supplier"
                    />
                </Grid>
                <Grid item xs={2}/>
            </Grid>
        </div>
    </KoiFacade>
};
