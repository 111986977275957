import React, {useEffect, useRef, useState} from "react";
import {useStores} from "../../stores";
import * as d3 from "d3";
import * as d3Sankey from "d3-sankey";
import {SankeyGraph, SankeyLayout} from "d3-sankey";
import {COLOR_UNCATEGORIZED, vizColor} from "../../style/colors";
import {HACKS} from "../../env";
import {GraphResponse, GraphResponseLink, GraphResponseNode, Kpi_graph_kpi} from "../../services/ApiTypes";
import {Job} from "../../services/classes/JobProcessing";

function isHighlight(name: string) {
    if (name === undefined || name === null) {
        return true;
    }
    if (name === 'uncategorized') {
        return true;
    }
    // eslint-disable-next-line eqeqeq
    if (name.trim().toLowerCase() == '<uncategorized>') {
        return true;
    }
    // case: Normal category
    return false;
}

const WIDTH = 1000;
const HEIGHT = 900;
const MARGIN = 5;
const HEADER_HEIGHT = 20;
const NODE_WIDTH = 50;

type Props = {
    kpi: Kpi_graph_kpi,
    minFilter: number,
};

export const SankeyTreeView: React.FC<Props> = ({kpi, minFilter}) => {
    const svgRef = useRef<SVGSVGElement>(null)
    const {jobStore} = useStores();
    const job = jobStore.job as Job;
    const [loadingData, setLoadingData] = useState(false);

    if (!job) throw new Error();

    const [_data, setData] = React.useState<GraphResponse>();
    // const [error, setError] = React.useState('');

    // console.log('d3s', d3s);
    // console.log('d3a', d3a);
    // const x: SankeyGraph;

    useEffect(() => {
        console.log('SankeyTreeView.reset data')
        setData(undefined);
    }, [kpi, minFilter])

    // Load data
    useEffect(() => {
        if (!loadingData && !_data) {
            console.log('SankeyTreeView.loading data')
            setLoadingData(true);
            jobStore.getGraphKpi(kpi, true, minFilter).then(resp => {
                setData(resp.data);
                setLoadingData(false);
            })
        } else {
        }
    }, [job, loadingData, kpi, minFilter, _data]);
    useEffect(() => {
        if (!_data || !svgRef.current) {
            console.log('SankeyTreeView.render: REJECT', svgRef.current, _data);
            return;
        }
        console.log('SankeyTreeView.render: ACCEPT', svgRef.current, _data);

        const svg = d3.select(svgRef.current as SVGElement);
        svg.html(''); // clear

        // type DATA = string;
        type NodeType = GraphResponseNode | {};
        type LinkType = GraphResponseLink<number> | { level: number } | {};
        type Graph = SankeyGraph<NodeType, LinkType>;
        type Layout = SankeyLayout<Graph, NodeType, LinkType>;
        const data: Graph = {
            links: _data.links.map(d => Object.assign({}, d)),
            nodes: _data.nodes.map(d => Object.assign({}, d))
        };
        console.log('data', data);
        const sankey: Layout = d3Sankey.sankey()
            .nodeAlign(d3Sankey.sankeyCenter)
            // .nodeAlign(d3Sankey.sankeyJustify)
            .nodeSort((a: any, b: any) => {

                if ('sankey_tax_switch' in HACKS) {
                    // Hack for 2021 Feb Demo
                    const _hack1 = (_a, _b) =>
                        `${_a.name}`.toLowerCase() === 'mounting'
                        && `${_b.name}`.toLowerCase() === 'metals plating';
                    const _hack2 = (_a, _b) =>
                        `${_a.name}`.toLowerCase().includes('sheet')
                        && `${_b.name}`.toLowerCase() === 'adhesive';
                    const _hack3 = (_a, _b) => `${_a.name}`.toLowerCase() === 'screws';
                    if (_hack1(a, b)) {
                        return 1;
                    } else if (_hack1(b, a)) {
                        return -1;
                    }
                    if (_hack2(a, b)) {
                        return -1;
                    }
                    if (_hack2(b, a)) {
                        return 1;
                    }
                    if (_hack3(a, b)) {
                        return -1;
                    }
                    if (_hack3(b, a)) {
                        return 1;
                    }
                }

                const aParentCode = a.code.substring(0, a.code.length - 3);
                const bParentCode = b.code.substring(0, b.code.length - 3);
                const diffP = bParentCode - aParentCode;
                if (diffP === 0) {
                    return b.value - a.value;
                }
                return diffP;
            })
            .nodeWidth(NODE_WIDTH)
            .nodePadding(0.8)
            // .nodePadding(2)
            // .nodePadding(0.2)
            // .nodePadding(0)
            .size([WIDTH, HEIGHT])
        const linksGroup = svg.append("g")
            .attr('id', 'links')
            .attr("class", "links")
            .attr("fill", "none")
            .attr("stroke", "#000")
            .attr("stroke-opacity", 0.2)
            .selectAll("path");
        const nodesGroup = svg.append("g")
            .attr('id', 'nodes')
            .attr("class", "nodes")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .selectAll("g")

        sankey(data);
        const placedData = data as Graph;
        // console.log('SankeyTreeView.loadingData', placedData.links.find((l) => (l as any).level === 1));
        console.log('SankeyTreeView.loadingData', placedData.nodes.find(n => n.index === 0)?.value);

        linksGroup
            .data(data.links, (d: any) => d.source.nodeId + '|' + d.target.nodeId)
            .enter()
            .append("path")
            .attr("d", d3Sankey.sankeyLinkHorizontal())
            .attr("stroke-width", function (d: any) {
                return Math.max(1, d.width);
            });

        const nodesUpdate = nodesGroup
            .data(data.nodes, (d: any) => d.nodeId);
        const nodes = nodesUpdate
            .enter()
            .append("g")
        nodes.on('click', function () {
            const s = d3.select(this);
            const clickedNode: any = s.datum();
            console.log('clickedNode', clickedNode);
            if (clickedNode.height === 0) {
                return;
            }

            const data = {
                links: _data.links.filter(l => (l.target as any).nodeId !== clickedNode.nodeId),
                nodes: _data.nodes
            }
            if (true) return; // HACK for demo Rasa
            sankey(data);

            const nodes2 = d3.select('#nodes')
                .selectAll('g')
                .data(data.nodes, (d: any) => d.nodeId)
                .attr('fill', (d: any) => {
                    if (d.nodeId === clickedNode.nodeId) {
                        return 'red';
                    } else {
                        return 'black';
                    }
                });
            nodes2.select('rect')
                .attr("x", function (d: any) {
                    return d.x0;
                })
                .attr("y", function (d: any) {
                    return d.y0;
                })
                .attr("height", function (d: any) {
                    return d.y1 - d.y0;
                })
                .attr("width", function (d: any) {
                    return d.x1 - d.x0;
                })


            const links = d3.select('#links')
                .selectAll('path')
                .data(data.links, (d: any) => d.source.nodeId + '|' + d.target.nodeId);
            links.exit().remove();

            // TODO: d3; Get familiar with merge(...) VS enter, exit, update
            links.enter().append("path")
                .attr("d", d3Sankey.sankeyLinkHorizontal())
                .attr("stroke-width", function (d: any) {
                    return Math.max(1, d.width);
                });

            // console.log('clicked', s);
            // console.log('clicked', s.datum());
            // console.log('links:', data.links[0].source);

            // const linksA = data.links.length;
            // const links2 = data.links.filter(l => (l.source as any).nodeId !== clickedNode.id);
            // const linksB = links2.length;
            // console.log('Links', linksA, linksB);
            // linksGroup
            //     .data(links2)
            //     .exit()
            //     .remove();

        })
        nodes.append("rect")
            .attr("x", function (d: any) {
                return d.x0;
            })
            .attr("y", function (d: any) {
                return d.y0;
            })
            .attr("height", function (d: any) {
                return d.y1 - d.y0;
            })
            .attr("width", function (d: any) {
                return d.x1 - d.x0;
            })
            .attr("fill", function (d: any) {
                // return d.value === 0 ? 'black' : (d.value < 0 ? 'red' : 'green');
                const isUncat = isHighlight(d.name);
                // return isUncat ? 'red' : '#0a4456'

                // const relDarker_K = 3.5;
                const relDarker_K = 1.5;
                const orC = d3.rgb(vizColor(d.name)).darker(relDarker_K);
                return (isUncat ? COLOR_UNCATEGORIZED : orC) as any;
                // return color(d.name.replace(/ .*/, ""));
            })
            // .attr("stroke", "#000")
            .attr('stroke-width', 0)
        nodes.append("text")
            .attr("x", function (d: any) {
                return d.x0 - 6;
            })
            .attr("y", function (d: any) {
                return (d.y1 + d.y0) / 2;
            })
            .attr("dy", "0.35em")
            .attr("text-anchor", "end")
            .text(function (d: any) {
                if (d.name === null) {
                    return 'uncategorized';
                }
                return `${d.name}`;
                // const levels = data.links.filter(l => l.target === d).map((l: any) => l.level);
                // return `[${levels}] ${d.name} (${d.nodeId})`;
                // // console.log('d', d);
                // if (d.layer > 1) {
                //     return d.value > 2500 ? d.name : '';
                // } else {
                //     return d.name;
                // }
                // // return (d.name as string).trim().toLowerCase() == ('<uncategorized>' as string) ? d.name : '';
            })
        nodes.append("title")
            .text(function (d: any) {
                return `${d.name}\n€ ${d.value}`;
            });


        const N_LEVELS = 2;
        const labelsData = [...Array(N_LEVELS)].map((_, i) => ({
            label: `L${i + 1}`,
            x: NODE_WIDTH / 2 + (i + 1) * (WIDTH - NODE_WIDTH) / N_LEVELS,
        }));
        svg.append("g")
            .attr("class", "headers")
            .selectAll("label")
            .data(labelsData)
            .join(
                // https://observablehq.com/@d3/selection-join
                // https://observablehq.com/@d3/learn-d3-joins?collection=@d3/learn-d3
                enter => enter.append('text')
                    .attr("text-anchor", "middle")
                    .attr('x', d => d.x)
                    .attr('y', -5)
                    .text(d => d.label)
            )
    }, [_data])

    return <>
        {/*<p>Selected={kpi}</p>*/}
        {/*{!rendered ? <p>Loading</p> : <></>}*/}
        <svg className="sankey-visualization" ref={svgRef}
             viewBox={`${-MARGIN} ${-MARGIN - HEADER_HEIGHT} ${WIDTH + 2 * MARGIN} ${HEIGHT + 2 * MARGIN + HEADER_HEIGHT}`}
             style={{width: '90%', marginLeft: '5%'}}/>
        {/*{taxKpis*/}
        {/*    ? <span>taxKpis loaded</span>*/}
        {/*    : <span>Nothing</span>*/}
        {/*}*/}
    </>;
};
