import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {
    BaseHierarchy,
    cum_supplier_groups_summary_StatsResponse,
    HierarchyApiResponse
} from "../../../services/ApiTypes";
import {generatePath} from "react-router";
import {routes} from "../../../routing/routes";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import './KoiSpendClassification.scss';
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import {Button, Grid, useTheme} from "@material-ui/core";
import {TreeGraph} from "../../../components/visualization/tree/TreeGraph";
import {showEstimation} from "../../../utils/time-utils";
import {CurrencyComponent, toCurrency} from "../../../components/currency-component/CurrencyComponent";
import {forkJoin} from "rxjs";
import {fromPromise} from "rxjs/internal-compatibility";
import {map} from "rxjs/operators";
import {
    SlopeGraph,
    SlopeGraphData,
    SlopeGraphDataPoint
} from "../../../components/visualization/slope-graph/SlopeGraph";
import {attentionColor, COLOR_UNCATEGORIZED} from "../../../style/colors";
import {Job} from "../../../services/classes/JobProcessing";

// TODO: This is hardcoded
const PROCESSING_DURATION_HACK_MINUTES = 23;

// TODO: This is hardcoded
const HIGHLIGHTED_TAXONOMY_MATCHES = [
    // Taken from test_dataset_ssd.TestSsdDataset2.test_print_missing_labels
    'abs',
    'additive-manufacturing',
    'al-bar',
    'al-sheet',
    'amniotic-membrane',
    'api',
    'auxiliary-cart',
    'av-carts',
    'awls-and-punches',
    'battery-charger',
    'blade-blanks',
    'blisters',
    'bone-void-filler',
    'braking-components',
    'brushless-frameless-dc-motor',
    'bur-blanks',
    'cable-accessories',
    'cameras',
    'capital-expenses',
    'cart-floor-stand',
    'cases',
    'cast-trials',
    'catalogue-items',
    'catheter-assembly',
    'ccm-bar',
    'ccm-ingots',
    'cells',
    'ceramic-implants',
    'ceramic-injection-molding',
    'chassis',
    'chemical',
    'chemicals',
    'chucks',
    'co2',
    'cobalt',
    'cocr-powder',
    'coils',
    'color-concentrates',
    'compounded',
    'compounded-tpu',
    'connected-or-cart',
    'connectors',
    'consumer-electronics',
    'contacts',
    'core-accessory-cart',
    'couplers',
    'cups',
    'custom',
    'custom-battery-pack',
    'dbm-bvf-boats',
    'dbm-bvf-fibers',
    'dbm-bvf-gel',
    'dbm-bvf-particulate',
    'dbm-bvf-sponge',
    'dc-micro-motor',
    'die-castings',
    'drapes',
    'drill-blanks',
    'dura',
    'encoders',
    'expedite-fee',
    'fa-wedges',
    'fabric',
    'fascia-lata',
    'fees-charges',
    'fiber',
    'fiber-optic-cables',
    'filters',
    'fire-barrier',
    'foam-products-and-components',
    'forged-industrial',
    'forged-plates',
    'forged-trials',
    'freight',
    'general-fabrication',
    'general-mounts-and-brackets',
    'general-spacers',
    'grinding',
    'hdpe',
    'heads',
    'high-energy-storage-capacitor',
    'hips',
    'ifu_s',
    'inductor',
    'insert-cards',
    'knits',
    'laminated-core',
    'large-structural-bone-grafts',
    'laser-general',
    'ldpe-eva',
    'lift-assembly-components',
    'light-source',
    'lights',
    'linear',
    'liquid-photopolymer',
    'load-cells',
    'lsr',
    'magnets',
    'mako-pro-cart',
    'manufacturing-materials',
    'mattress-systems-and-assembly',
    'metal-ceramic-injection-molding',
    'metal-injection-molding',
    'metal-powders',
    'micro-cart',
    'misc-blanks',
    'module',
    'monitor-stand',
    'monitors',
    'nara',
    'nasopore',
    'nerve-wraps-conduits',
    'non-wovens',
    'noses',
    'oem',
    'oem-products',
    'other-fire-barrier-service-kits-accessories-sponges-fabric',
    'other-power-supplies',
    'outside-processing',
    'paper-rolls',
    'patient-positioning',
    'pbt',
    'pc',
    'pe',
    'pebax',
    'peek',
    'peek-rods',
    'pet-30-gf',
    'photochemical-etched-products',
    'platinum',
    'polyimide',
    'pom',
    'pouches',
    'power-adapter',
    'power-cords',
    'power-isolation',
    'ppsu',
    'ppv',
    'precision-housings',
    'precision-screws',
    'pumps',
    'rasp-blanks',
    'reamers-and-planers',
    'retractor',
    'rfid',
    'rolls-for-thermoforming',
    'round-blanks',
    'router-blanks',
    'safety-bar-dual',
    'sage-cart',
    'sand-castings',
    'sbc',
    'silicone',
    'sonopet',
    'spo2',
    'stainless-steel',
    'standard-video-cart',
    'steel-etching',
    'steel-powder',
    'strykervision-mount',
    'sutures',
    'synthetic',
    'tablets',
    'thermoform',
    'ti-bar',
    'ti-plate',
    'ti-wire',
    'titanium',
    'titanium-etching',
    'titanium-powder',
    'toriodal',
    'transformers',
    'tube-sets',
    'tungsten-etching',
    'ups',
    'vacuum-pump',
    'value-added-services',
    'video-cables',
    'virgin',
    'vision-pro-display-cover',
    'washers',
    'wave-springs',
    'wheel-and-caster-components',
    'wired-cables',
    'xenografts',
]

const USE_HOVERING = false;

type TaxonomyType = HierarchyApiResponse;

type KeyValues = {
    before: {
        total_spend: number,
        unclassified_spend?: number,
    },
    after: {
        total_spend: number,
        unclassified_spend?: number,
    },
}

const CATEGORIZED_VALUES = d => {
    if (d.label === null) {
        return 9660;
    }
    switch (d.label) {
        case 'electronics':
            return 5;
        case 'direct':
            return 14092231;
        case 'facilities':
            return 80844959;
        case 'factory materials':
            return 72142593;
        case 'freight':
            return 131718873;
        case 'human resources':
            return 379937;
        case 'industrial and technical gases':
            return 4230029;
        case 'information technology':
            return 11897534;
        case 'miscellaneous expenses':
            return 4565608;
        case 'mobility':
            return 325023;
        case 'osp':
            return 35827859;
        case 'outsourced quality control':
            return 59262086;
        case 'packaging':
            return 14799772;
        case 'production equipment':
            return 49731961;
        case 'professional services':
            return 14641051;
    }
    console.log('missing', d);
    return d.value;
}

const NO_VALUE = {label: toCurrency(0, 0), value: 0};

// TODO: This is hardcoded
// For http://localhost:3008/job/3/spend_classification
// const LEVEL_SELECTION = [];
// const LEVEL_SELECTION = ['fg-oem-bio'];
// const LEVEL_SELECTION = ['electronics'];
// const LEVEL_SELECTION = ['metals'];
const LEVEL_SELECTION = ['metals', 'processes'];
// const LEVEL_SELECTION = ['metals', 'processes', 'osp'];
// const LEVEL_SELECTION = ['metals', 'processes', 'osp', 'coatings'];
const LEVEL_SELECTION_LEVELS = LEVEL_SELECTION.length;
const L_STR = `L${LEVEL_SELECTION_LEVELS}`;

export const KoiSpendClassification: React.FC = observer(() => {
    const theme = useTheme();

    const {jobStore} = useStores();
    const job = jobStore.job as Job;
    if (!job) throw new Error();

    const [activeStep, setActiveStep] = React.useState<number>(0);

    const [taxonomy, setTaxonomy] = React.useState<undefined | TaxonomyType>(undefined);
    useEffect(() => {
        jobStore.getJobTaxonomyKpis().then(resp => setTaxonomy(resp.data));
    }, [])

    const [keyValues, setKeyValues] = React.useState<KeyValues | undefined>(undefined);
    const [beforeAfterData, setBeforeAfterData] = React.useState<SlopeGraphData | undefined>(undefined);
    useEffect(() => {
        setKeyValues(undefined);
        // setBeforeAfterData(undefined);
        const sub = forkJoin([
            fromPromise(jobStore.getJobStats()),
            fromPromise(jobStore.getJobKoiCumSuppliersL1Summary()),
            fromPromise(jobStore.getJobKoiCumSuppliers(1)),
            fromPromise(
                LEVEL_SELECTION_LEVELS === 0
                    ? jobStore.getAiCompareResultFull()
                    : (LEVEL_SELECTION_LEVELS === 1
                        ? jobStore.getAiCompareResultL1(LEVEL_SELECTION[0])
                        : jobStore.getAiCompareResultByLevels(...LEVEL_SELECTION)
                    )
            ),
        ])
            .pipe(map(([stats, l1Resp, topResp, aiCompareResult]) => {
                const aiCompareResultData: BaseHierarchy<[number, number]> = aiCompareResult.data;
                const uncategorized: cum_supplier_groups_summary_StatsResponse = l1Resp.data.stats[''];
                const keyValues: KeyValues =
                    LEVEL_SELECTION_LEVELS === 0
                        ? {
                            before: {
                                total_spend: stats.data.total_spend,
                                unclassified_spend: stats.data.unclassified_spend,
                            },
                            after: {
                                total_spend: stats.data.total_spend,
                                unclassified_spend: uncategorized ? uncategorized.top_total_var : 0,
                            },
                        }
                        : {
                            before: {
                                total_spend: aiCompareResultData.value[0],
                            },
                            after: {
                                total_spend: aiCompareResultData.value[1],
                            },
                        };
                // Show combined result
                const beforeAfterData = aiCompareResultData.children.map<SlopeGraphDataPoint>((d) => {
                    console.log('received', d);
                    const r: SlopeGraphDataPoint = {
                        label: !d.label ? '<uncategorized>' : d.label,
                        key: d.label,
                        values: [
                            d.value[0] === null ? NO_VALUE : {label: toCurrency(d.value[0], 3), value: d.value[0]},
                            d.value[1] === null ? NO_VALUE : {label: toCurrency(d.value[1], 3), value: d.value[1]},
                        ],
                        color: 'black'
                    };
                    return r;
                })
                return {keyValues, beforeAfterData};
            }))
            .subscribe(({keyValues, beforeAfterData}) => {
                setKeyValues(keyValues);
                setBeforeAfterData(beforeAfterData);
            });
        return () => sub.unsubscribe();
    }, [jobStore])

    const step0 = <div className="page-item">
        {/* Header */}
        <Grid container justify="center">
            <Grid item xs={4}>
                <Typography variant="h6">Scope of the classification job</Typography>
            </Grid>
            <Grid item xs={4} container justify="flex-end">
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => setActiveStep(1)}>
                        Start classification
                    </Button>
                </Grid>
            </Grid>
        </Grid>

        <div style={{minHeight: '52em', marginTop: '2em'}}>
            {(taxonomy && <TreeGraph
                data={taxonomy}
                highlight={{
                    matches: HIGHLIGHTED_TAXONOMY_MATCHES,
                    color: attentionColor,
                }}
                legend={[
                    {label: 'In scope', color: theme.palette.primary.main},
                    {label: 'Out of scope', color: attentionColor},
                    {label: '<uncategorized>', color: COLOR_UNCATEGORIZED},
                ]}
                expand={true}/>)
            || <p>Loading...</p>}
        </div>
    </div>;

    const step1 = <div className="page-item">
        {/*/!* Header *!/*/}
        {/*<Grid container justify="space-evenly">*/}
        {/*    <Grid item xs={5}>*/}
        {/*        <Typography variant="h6" align="center">Determining the parent and child suppliers ...</Typography>*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={4}>*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}

        <Grid container justify="space-evenly">
            <Grid item xs={4}
                  container
                  justify="space-around" style={{alignContent: 'space-around'}} alignItems="center">
                <Grid item
                      container direction="column"
                      justify="space-around" style={{alignContent: 'space-around'}} alignItems="center"
                      className="text-center">
                    {/*<Grid item>*/}
                    {/*    <p>*/}
                    {/*        Estimated completion time:*/}
                    {/*    </p>*/}
                    {/*</Grid>*/}
                    <Grid item className="font-bigger">
                        <Typography variant="caption">
                            Estimated completion time:
                            {/*<br/>suppliers*/}
                        </Typography>
                        <Typography variant="h5" className="font-weight-bold key-value">
                            {showEstimation(PROCESSING_DURATION_HACK_MINUTES)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained" color="secondary"
                            style={{margin: '3em'}}
                            onClick={() => setActiveStep(2)}>Skip (Demo only)</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>;

    const step2 = <div className="page-item">
        {/* Header */}
        <Grid container justify="center">
            <Grid item xs={4}
                  container justify="space-around" style={{alignContent: 'space-around'}} alignItems="center">
                {/* Before */}
                <Grid item xs={12}><Typography variant="h6" align="center">Before</Typography></Grid>

                <Grid className="key-value-container text-center"
                      item xs={7}
                      container spacing={4}>
                    <Grid item
                          container
                          style={{justifyContent: "space-between"}}>
                        <Grid item>
                            <Typography variant="caption">
                                Total spend
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {keyValues
                                    ? <CurrencyComponent v={keyValues.before.total_spend} nDigits={3}/>
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                        {/*{(keyValues !== undefined && keyValues.before.unclassified_spend === undefined) ||*/}
                        <Grid item>
                            <Typography variant="caption">
                                Unclassified
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {keyValues
                                    ? <CurrencyComponent v={keyValues.before.unclassified_spend || 0} nDigits={3}/>
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Removed for Stryker demo */}
            {/*<Grid item xs={2}>*/}
            {/*    <a className="button" href={environment.demoFiles.spendClassificationResult.url}>*/}
            {/*        <Button variant="contained" color="secondary" style={{marginTop: '2em'}}>*/}
            {/*            Download results*/}
            {/*        </Button>*/}
            {/*    </a>*/}
            {/*</Grid>*/}
            {/* Added for Stryker demo */}
            {LEVEL_SELECTION_LEVELS === 0 || <Grid item xs={2}>
                <Typography variant="h6" align="center">{L_STR}: {LEVEL_SELECTION.join(', ')}</Typography>
            </Grid>}


            <Grid item xs={4}
                  container justify="space-around" style={{alignContent: 'space-around'}} alignItems="center">
                {/* After */}
                <Grid item xs={12}><Typography variant="h6" align="center">After</Typography></Grid>

                <Grid className="key-value-container text-center"
                      item xs={7}
                      container spacing={4}>
                    <Grid item
                          container
                          style={{justifyContent: "space-between"}}>
                        <Grid item>
                            <Typography variant="caption">
                                Total spend
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {keyValues
                                    ? <CurrencyComponent v={keyValues.after.total_spend} nDigits={3}/>
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                        {/*{(keyValues !== undefined && keyValues.after.unclassified_spend === undefined) ||*/}
                        <Grid item>
                            <Typography variant="caption">
                                Unclassified
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {keyValues
                                    ? <CurrencyComponent v={keyValues.after.unclassified_spend || 0} nDigits={3}/>
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <Typography variant="caption">*/}
                        {/*        Top change*/}
                        {/*    </Typography>*/}
                        {/*    <Typography variant="h5" className="font-weight-bold key-value">*/}
                        {/*        {keyValues*/}
                        {/*            ? <span>Outsources quality control</span>*/}
                        {/*            : <>&nbsp;</>}*/}
                        {/*    </Typography>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container justify="center">
            <Grid item xs={8}>
                {(beforeAfterData && <SlopeGraph
                    data={beforeAfterData}
                    width={960}
                    height={760}
                    labelKeyLeftOffset={-65}
                    labelKeyRightOffset={null}
                    labelGroupLeftOffset={80}
                    unfocusOpacity={USE_HOVERING ? undefined : 1}
                />) || <p>Loading</p>}
            </Grid>
        </Grid>
        {/*<Grid container justify="center">*/}
        {/*    <Grid item>*/}
        {/*        <Button variant="contained" color="secondary" style={{margin: '2em'}}>*/}
        {/*            Download results <small>(TODO)</small>*/}
        {/*        </Button>*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}
    </div>;


    return <KoiFacade
        title="Spend classification"
        intro={<></>}
        back={generatePath(routes.job_dashboard, {id: job.id})}
        className="koi-sc-page">

        <Grid container justify="space-evenly">
            <Grid item xs={8}>
                <Stepper orientation={"horizontal"} activeStep={activeStep}>
                    <Step>
                        <StepLabel>Scope classification</StepLabel>
                    </Step>

                    <Step>
                        <StepLabel>Classify data</StepLabel>
                    </Step>

                    <Step>
                        <StepLabel>Download results</StepLabel>
                    </Step>
                </Stepper>
            </Grid>
        </Grid>

        {activeStep === 0 && step0}
        {activeStep === 1 && step1}
        {activeStep === 2 && step2}

    </KoiFacade>
});
