import React, {useEffect} from "react";
import {Menu, MenuItem} from "@material-ui/core";
import {generatePath, useHistory} from "react-router";
import {environment} from "../../env";
import {DebugValue} from "./DebugValue";
import {useStores} from "../../stores";
import {observer} from "mobx-react-lite";
import Button from "@material-ui/core/Button";
import {routes} from "../../routing/routes";

export const DebugStatus: React.FC = observer(() => {
    const {debugStore, jobStore} = useStores();
    const history = useHistory();

    const serverConfig = debugStore.config;
    const celeryOk = debugStore.ping;
    const allJobs = debugStore.jobs;

    const [allJobsMenuAnchor, setAllJobsMenuAnchor] = React.useState<null | HTMLElement>(null);
    const [configsMenuA, setConfigsMenuA] = React.useState<null | HTMLElement>(null);

    useEffect(() => {
        debugStore.fetch();
    }, []);

    // // debug upload type
    // const [dbUploadType, setDbUploadType] = React.useState('naive_ds');
    // const [dbUploadTypes, setDbUploadTypes] = React.useState<string[]>(['naive_ds']);

    const allJobsMenuOpen = Boolean(allJobsMenuAnchor);
    return <div className="debug-components">
        <DebugValue name="env" val={environment.environmentName}/>
        <DebugValue name="production" val={environment.production}/>
        <DebugValue name="devLogin" val={environment.devLogin}/>

        <DebugValue name="server" true_txt="🌿" false_txt="💀" val={celeryOk}/>

        <DebugValue name="AI" true_txt="on" false_txt="off"
                    val={serverConfig ? !serverConfig.SKIP_AI_INTEGRATION : undefined}/>
        <DebugValue name="job" val={jobStore.job?.name}/>


        <DebugValue name="server.default_config" val={serverConfig ? serverConfig.default_type : undefined}/>

        <Button onClick={e => setConfigsMenuA(e.currentTarget)}>
            <DebugValue name="config" val={jobStore.parserConfig}/>
        </Button>
        <Menu id="menu-parser-config"
              anchorEl={configsMenuA}
              anchorOrigin={{vertical: 'top', horizontal: 'right',}}
              keepMounted
              transformOrigin={{vertical: 'top', horizontal: 'right',}}
              open={Boolean(configsMenuA)}
              onClose={() => setConfigsMenuA(null)}>
            {(serverConfig?.all_types || []).map(c =>
                <MenuItem key={c} onClick={() => {
                    jobStore.setParserConfig(c);
                    setConfigsMenuA(null);
                }}>{c}</MenuItem>
            )}
        </Menu>

        {/*<FormControl>*/}
        {/*    <InputLabel id="debug-upload-config_label">Config</InputLabel>*/}
        {/*    <Select*/}
        {/*        labelId="debug-upload-config_label"*/}
        {/*        id="debug-upload-config"*/}
        {/*        value={dbUploadType}*/}
        {/*        onChange={e => setDbUploadType(e.target.value as string)}*/}
        {/*    >*/}
        {/*        {dbUploadTypes.map(t => <MenuItem value={t} key={t}>*/}
        {/*        {t}*/}
        {/*    </MenuItem>)}*/}
        {/*    </Select>*/}
        {/*</FormControl>*/}

        <a target="_blank" rel="noopener noreferrer" href={environment.adminUrl}>
            <button>Admin</button>
        </a>
        <button onClick={e => setAllJobsMenuAnchor(e.currentTarget)}>Jobs</button>

        <Menu id="menu-appbar"
              anchorEl={allJobsMenuAnchor}
              anchorOrigin={{vertical: 'top', horizontal: 'right',}}
              keepMounted
              transformOrigin={{vertical: 'top', horizontal: 'right',}}
              open={allJobsMenuOpen}
              onClose={() => setAllJobsMenuAnchor(null)}>
            {allJobs.map(job =>
                <MenuItem key={job.id} onClick={() => {
                    history.push(generatePath(routes.job, {id: job.id}));
                    setAllJobsMenuAnchor(null);
                    window.location.reload();
                }}>{job.id}: {job.name}</MenuItem>
            )}
        </Menu>


    </div>;
});
