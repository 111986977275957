import {D3Selection} from "./global";

export function appendLine<D>(s: D3Selection, x1: number, y1: number, x2: number, y2: number, className?: string) {
    const line = s.append('line')
        .attr("x1", x1)
        .attr("y1", y1)
        .attr("x2", x2)
        .attr("y2", y2)
    if (className) {
        line.classed(className, true);
    }
    return line;
}
