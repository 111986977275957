import {action, makeObservable, observable} from "mobx";

export type LoadingState = undefined | 'indefinite' | 'determinate';
declare type MainState = { state: LoadingState, progress: number, error: boolean };

export default class LoadingBarStore {
    state: MainState = {state: undefined, progress: 0, error: false};

    constructor() {
        // TODO: verify correct migration of mobx5 -> 6
        makeObservable(this, {
            state: observable,
            start: action,
            startAt: action,
            setProgress: action,
            setComplete: action,
            setError: action,
        });
        // reaction(
        //     () => this.state.progress,
        //     _ => console.log('LoadingBar.main=', this.state.progress)
        // );
    }

    start(indefinite?: boolean) {
        this.state.error = false;
        if (indefinite) {
            this.state.state = 'indefinite';
        } else {
            this.state.state = 'determinate';
            this.state.progress = 0;
        }
    }

    startAt(p: number, indefinite?: boolean) {
        this.state.error = false;
        if (indefinite) {
            this.state.state = 'indefinite';
        } else {
            this.state.state = 'determinate';
            this.state.progress = p;
        }
    }

    setProgress(p: number) {
        this.state.progress = p;
    }

    setComplete() {
        if (this.state.state === 'indefinite') {
            this.state.state = undefined;
        } else {
            this.setProgress(100);
        }
    }

    setError() {
        this.state.error = true;
    }
}
