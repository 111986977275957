import {ApiService} from "./Http";
import {
    advance_job,
    advance_job_input,
    common_kpi_Response,
    cum_supplier_group_Response,
    cum_supplier_groups_complete_Response,
    cum_supplier_groups_summary_Response,
    cum_suppliers_all_groups_Response,
    DjangoUser,
    GraphResponse,
    HierarchyApiResponse,
    job_data_Response,
    JobCumSupplierResponse,
    JobPriceVarianceResp,
    KpiBuilder_tree_kpis,
    PageResponse,
    ppv_all_groups_Response, Resp_AdvanceBagAndInput, ServerConfig,
    ShallowHierarchyApiResponse
} from "./ApiTypes";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {
    FullJobCreationSerializer_POST,
    Job,
    JobCreationSerializer_POST,
    JobCreationSerializer_RESP,
    JobInput,
    JobInputCreationSerializer_POST,
    JobInputCreationSerializer_RESPONSE
} from "./classes/JobProcessing";

const USE_CACHE = false;

export default class MithraApi extends ApiService {
    private cache = new Map<string, any>();

    private cached: <T> (promise: Promise<T>, key: string) => Promise<T> = (promise, key) => {
        if (!USE_CACHE)
            return promise;
        if (this.cache.has(key)) {
            console.log('Cache hit', key);
            return Promise.resolve(this.cache.get(key));
        } else {
            console.log('Cache miss', key);
            return promise.then(resp => {
                console.log('Cache store', key);
                this.cache.set(key, resp);
                return resp;
            })
        }
    };

    // Job endpoints

    createJob(data: JobCreationSerializer_POST): Promise<AxiosResponse<JobCreationSerializer_RESP>> {
        return this.http.post(`/bag/`, data);
    }

    getJob(id: number): Promise<Job> {
        return this.http.get<Job>(`/bag/${id}/`).then(r => r.data);
    }

    createJobInput(data: JobInputCreationSerializer_POST, onUploadProgress: (progressEvent: ProgressEvent) => void)
        : Promise<AxiosResponse<JobInputCreationSerializer_RESPONSE>> {
        const formData = new FormData();
        formData.append('author', `${data.author}`);
        formData.append('input_file', data.input_file);
        if (data.parser_setting) {
            formData.append('parser_setting', data.parser_setting);
        }
        formData.append('job', `${data.data_bag}`);
        return this.http.post("/input/", formData, {
            headers: {"Content-Type": "multipart/form-data"},
            onUploadProgress,
        });
    }

    createJobAndInput(data: FullJobCreationSerializer_POST, onUploadProgress: (progressEvent: ProgressEvent) => void)
        : Promise<AxiosResponse<JobInputCreationSerializer_RESPONSE>> {
        // Create a bag and input file at once
        const formData = new FormData();
        formData.append('data_bag.name', `${data.data_bag.name}`);
        if (data.data_bag.merge_setting)
            formData.append('data_bag.merge_setting', `${data.data_bag.merge_setting}`);
        formData.append('author', `${data.author}`);
        formData.append('input_file', data.input_file);
        if (data.parser_setting)
            formData.append('parser_setting', data.parser_setting);
        return this.http.post("/bag/single/", formData, {
            headers: {"Content-Type": "multipart/form-data"},
            onUploadProgress,
        });
    }

    getDataInputList() {
        return this.http.get<Job[]>('/input/');
    }

    getJobInput(id: number): Promise<JobInput> {
        return this.http.get<JobInput>(`/input/${id}/`).then(r => r.data);
    }

    // Job processing

    advanceJob(id: number): Promise<AxiosResponse<advance_job>> {
        return this.http.get(`/bag/${id}/advance_bag/`)
    }

    advanceJobInput(id: number): Promise<AxiosResponse<advance_job_input>> {
        return this.http.get(`/input/${id}/advance_data_input/`)
    }

    advanceSingleBagAndInput(bagId: number): Promise<AxiosResponse<Resp_AdvanceBagAndInput>> {
        // Note: this works only when the bag contains a single input only
        return this.http.get(`/bag/single/${bagId}/advance_input_and_bag/`)
    }

    advanceBagAndInput(bagId: number, inputId: number): Promise<AxiosResponse<Resp_AdvanceBagAndInput>> {
        return this.http.get(`/bag/${bagId}/advance_bag_and_input/?data_input=${inputId}`);
    }

    // KPI endpoints

    getKpiTree(id: number, kpi: KpiBuilder_tree_kpis) {
        // Used to be: getJobKpiTotalSpend with kpi=total_spend
        // Now: total_spend -> spend_tree
        return this.http.get<HierarchyApiResponse>(`/bag/kpi_viz/${id}/${kpi}/?tree`);
    }

    getJobTaxonomyKpis(id: number): Promise<AxiosResponse<HierarchyApiResponse>> {
        // /taxonomy_kpis used to point to TaxonomyKpiBuilder().parts_count (name, children, value)
        // So now it's /api/bag/kpi_viz/{:id}/count_tree/?tree (label, children, value)
        return this.getKpiTree(id, 'count_tree');
    }

    getJobStats(id: number): Promise<AxiosResponse<common_kpi_Response>> {
        return this.cached(this.http.get<common_kpi_Response>(`/bag/kpi_common/${id}/all/`), `common-${id}`);
    }

    getJobKoiCumSuppliers(id: number, top: number = 0) {
        return this.cached(this.http.get<JobCumSupplierResponse>(`/bag/kpi_koi/${id}/cum_suppliers/?top=${top}`), `cum-${id}-${top}`);
    }

    getJobKoiCumSuppliersL1Complete(id: number) {
        return this.http.get<cum_supplier_groups_complete_Response>(`/bag/kpi_koi/${id}/cum_supplier_groups_complete/`);
    }

    getJobKoiCumSuppliersL1Summary(id: number) {
        // TODO: deprecated
        return this.http.get<cum_supplier_groups_summary_Response>(`/bag/kpi_koi/${id}/cum_supplier_groups_summary/`);
    }

    getJobKoiCumSuppliersAllGroups(id: number) {
        return this.http.get<cum_suppliers_all_groups_Response>(`/bag/kpi_koi/${id}/cum_suppliers_all_groups/`);
    }

    getJobKoiCumSuppliersL1(id: number, labelL1: string) {
        // TODO: deprecated
        return this.http.get<cum_supplier_group_Response>(`/bag/kpi_koi/${id}/cum_supplier_group/?level1=${labelL1}`);
    }

    getTotalPriceVarianceGroups(id: number): Promise<number> {
        return this.getPriceVarianceGroups(id)
            .then(resp => resp.data.total_opportunity);
    }

    getPriceVarianceGroups(id: number) {
        return this.cached(this.http.get<JobPriceVarianceResp>(`/bag/kpi_koi/${id}/supplier_pv/?top_groups=0`), `koi-ppv-${id}`);
    }

    getPriceVarianceGroupedGroups(id: number) {
        return this.http.get<ppv_all_groups_Response>(`/bag/kpi_koi/${id}/ppv_all_groups/`)
    }

    downloadPPV(id: number) {
        // Tip check: https://gist.github.com/jbutko/d7b992086634a94e84b6a3e526336da3
        return this.http.get(`/bag/kpi_koi_data/${id}/purchase_price_variance_sheet/`, {responseType: 'blob'})
    }

    getPPVData(id: number, page: number = 1): Promise<AxiosResponse<PageResponse<any>>> {
        return this.http.get(`/bag/kpi_koi_data/${id}/purchase_price_variance_data/?page=${page}&page_size=30`);
    }

    getPPVDataAll(id: number): Promise<AxiosResponse<any[]>> {
        return this.http.get(`/bag/kpi_koi_data/${id}/purchase_price_variance_data/?no_page`);
    }

    getPPVBoxplot(id: number) {
        return this.http.get<any[]>(`/bag/kpi_koi_data/${id}/purchase_price_variance_boxplot/`)
    }

    getPartData(id: number, supplier_name: string): Promise<AxiosResponse<job_data_Response[]>> {
        return this.http.get<job_data_Response[]>(`/bag/${id}/data/?supplier=${encodeURIComponent(supplier_name)}`)
    }

    getGraphKpi(id: number, kpi: KpiBuilder_tree_kpis, ensurePositive = true, filterBelow = 100) {
        const req = this.http.get<GraphResponse>(`/bag/kpi_viz/${id}/${kpi}/`);
        return (!ensurePositive && filterBelow === 0) ? req : req.then(resp => {
            resp.data.links.forEach(l => l.value = Math.abs(l.value));
            if (filterBelow > 0) {
                resp.data.links = resp.data.links.filter(l => l.value >= filterBelow);
            }
            return resp;
        })
    }

    registerInterceptor(onFulfilled: (config: AxiosRequestConfig) => (Promise<AxiosRequestConfig> | AxiosRequestConfig)) {
        this.http.interceptors.request.use(onFulfilled)
    }

    getUser(user: number) {
        return this.http.get<DjangoUser>(`/users/${user}/`)
    }

    getAiCompareResultFull(id: number) {
        // Value compares old (index 0) to new (index 1)
        return this.http.get<HierarchyApiResponse<[number, number]>>(`/bag/kpi_ai_tree/${id}/category_result`);
    }

    getAiCompareResultByLevel(id: number, ...labels: string[]) {
        // Value compares old (index 0) to new (index 1)
        type Resp = ShallowHierarchyApiResponse<[number, number]>;
        const params = {shallow: true};
        for (let i = 0; i < labels.length; i++) {
            if (labels[i] === undefined) {
                break;
            }
            params[`l${i + 1}`] = labels[i];
        }
        return this.http.get<Resp>(`/bag/kpi_ai_tree/${id}/category_result`, {params});
    }

    getServerConfig() {
        return this.http.get<ServerConfig>('/config/');
    }

    getServerPing() {
        return this.http.get<boolean>('/config/ping/');
    }

    getDataBagList() {
        return this.http.get<Job[]>('/bag/');
    }
}
