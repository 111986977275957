import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {useTheme} from "@material-ui/core/styles";
import {generatePath} from "react-router";
import {routes} from "../../../routing/routes";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import './KoiTaxonomyFacts.scss';
import {TreeGraph} from "../../../components/visualization/tree/TreeGraph";
import {lighten} from "@material-ui/core/styles/colorManipulator";
import {Job} from "../../../services/classes/JobProcessing";
import {HierarchyApiResponse} from "../../../services/ApiTypes";

type TaxonomyType = HierarchyApiResponse;

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         acceptButton: {
//             margin: theme.spacing(2),
//         },
//         textRight: {
//             textAlign: 'right',
//         },
//     }),
// );

export const KoiTaxonomyFacts: React.FC = observer(() => {
    const theme = useTheme();

    const {jobStore} = useStores();
    const job = jobStore.job as Job;
    if (!job) throw new Error();

    const [taxonomy, setTaxonomy] = React.useState<undefined | TaxonomyType>(undefined);

    useEffect(() => {
        jobStore.getJobTaxonomyKpis().then(resp => setTaxonomy(resp.data));
    }, [])

    return <KoiFacade
        title="Spend taxonomy overview"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={generatePath(routes.job_dashboard, {id: job.id})}
        className="koi-tf-page"
    >
        {taxonomy && <TreeGraph
            data={taxonomy}
            highlight={{
                matches: ['operating supplies', 'information technology : others'],
                color: lighten(theme.palette.primary.main, 0.5),
            }}
        />}
    </KoiFacade>
});

