import {ApiService, ApiStore} from "../services/Http";
import {action, makeObservable, observable} from "mobx";
import {ServerConfig} from "../services/ApiTypes";
import {AxiosInstance} from "axios";
import MithraApi from "../services/MithraApi";
import {Job} from "../services/classes/JobProcessing";

export default class DebugStore<T extends MithraApi> extends ApiStore<T> {
    constructor(protected api: T) {
        super(api);
        // TODO: verify correct migration of mobx5 -> 6
        makeObservable(this, {
            config: observable,
            ping: observable,
            jobs: observable
        });
    }

    /**
     * Debugging purposes only
     */
    private get http(): AxiosInstance {
        return (this.api as any).http;
    }

    config?: ServerConfig;

    ping = false;

    jobs: Job[] = [];

    public fetch() {
        Promise.all([
            this.api.getServerConfig(),
            this.api.getServerPing(),
            this.api.getDataBagList(),
        ]).then(
            action('DebugStore.success', ([sc, p, js]) => {
                this.config = sc.data;
                this.ping = p.status === 200;
                this.jobs = js.data.reverse();
            }),
            action('DebugStore.reject', console.error)
        )
    }
}
