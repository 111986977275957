import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid, Hidden, Typography} from "@material-ui/core";
import {generatePath} from "react-router";
import {routes} from "../../routing/routes";
import {useStores} from "../../stores";
import {DashBoardCard} from "../../components/dashboard-card/DashBoardCard";
import './JobDashBoard.scss';
import SpendConcentrationHelp from "../koi/spend-concentration/SpendConcentrationHelp";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {KeyDataFactsIcon} from "../../components/icons/KeyDataFactsIcon";
import {PurchasePriceVarianceIcon} from "../../components/icons/PurchasePriceVarianceIcon";
import {SpendConcentrationIcon} from "../../components/icons/SpendConcentrationIcon";
import {SpendClassificationIcon} from "../../components/icons/SpendClassificationIcon";
import {SupplierNormalizationIcon} from "../../components/icons/SupplierNormalizationIcon";
import PurchasePriceVarianceHelp from "../koi/purchase-price-variance/PurchasePriceVarianceHelp";
import {KeyTaxonomyFactsIcon} from "../../components/icons/KeyTaxonomyFactsIcon";
import {common_kpi_Response} from "../../services/ApiTypes";

export const JobDashBoard: React.FC = observer(() => {
    const {jobStore} = useStores();
    const job = jobStore.getJob();

    // Metric from PVV
    const [totalPPVOpportunity, setTotalPPVOpportunity] = React.useState<number | undefined>(undefined);

    // Metric from Spend Concentration
    const [topXSpend, setTopXSpend] = React.useState<number | undefined>(undefined);

    // Data metrics
    const [jobStats, setJobStats] = React.useState<common_kpi_Response | undefined>(undefined);

    useEffect(() => {
        jobStore.getTotalPriceVarianceGroups().then(setTotalPPVOpportunity);
        jobStore.getJobKoiCumSuppliers(1).then(r => setTopXSpend(r.data.stats.top_total_var__perc * 100));
        jobStore.getJobStats().then(resp => setJobStats(resp.data))
    }, []);

    const buildHeader = text =>
        <Grid className="dashboard-header"
              item
              container>
            <Hidden smDown><Grid item md={1} lg={2}/></Hidden>
            <Grid item md={10} lg={8}>
                <Typography variant="h4" component="h2">
                    {text}
                </Typography>
            </Grid>
        </Grid>

    const buildGrid = elements =>
        <Grid className="overview-cards"
              item
              container>
            <Hidden mdDown><Grid item lg={1}/></Hidden>
            <Grid item md={12} lg={10}
                  container spacing={2} justify="space-evenly" alignItems="stretch">
                {elements}
            </Grid>
        </Grid>

    return <Grid className="dashboard-wrapper"
                 container direction="column" spacing={8}>

        {buildHeader('Spend insights')}
        {buildGrid(<>
            <DashBoardCard
                avatar={<PurchasePriceVarianceIcon/>}
                title="Purchase price variance"
                body={<Typography variant="h5">

                    {totalPPVOpportunity === undefined
                        ? '...'
                        // : <span >{CURRENCY_FORMATTER.format(Math.round(totalPPVOpportunity / 1000000) * 1000000)}</span>}
                        : <span className="highlight">
                                <CurrencyComponent v={totalPPVOpportunity} nDigits={3}/>
                            </span>
                    }
                    <span> variance cost</span>

                </Typography>}
                helpContent={<PurchasePriceVarianceHelp/>}
                link={generatePath(routes.job_koi_ppv, {id: job.id})}
            />
            <DashBoardCard
                avatar={<SpendConcentrationIcon/>}
                className="spend-concentration"
                title="Spend concentration"
                body={
                    <Typography variant="h5">

                        {topXSpend === undefined
                            ? '...'
                            : <span className={'highlight'}>{'' + Math.round(topXSpend)}%</span>}
                        <span> of the total spend is with the top 20% suppliers</span>

                    </Typography>
                }
                helpContent={<SpendConcentrationHelp/>}
                link={generatePath(routes.job_koi_sc, {id: job.id})}
            />
        </>)}

        {buildHeader('Spend overview')}
        {buildGrid(<>
            <DashBoardCard
                avatar={<KeyDataFactsIcon/>}
                title="Key spend summary"
                body={<Typography variant="h5">

                    <span>Total spend: </span>
                    {jobStats === undefined ? '...' :
                        <span className={'highlight'}>
                            <CurrencyComponent v={jobStats.total_spend} nDigits={3}/>
                        </span>}
                    <br/>

                    <span>Number of suppliers: </span>
                    {jobStats === undefined ? '...' :
                        <span className={'highlight'}>{'' + jobStats.n_suppliers}</span>}
                    <br/>

                    <span>Unclassified spend: </span>
                    {jobStats === undefined ? '...' :
                        <span className={'highlight'}>
                            <CurrencyComponent v={jobStats.unclassified_spend} nDigits={3}/>
                        </span>}

                </Typography>}
                helpContent={"This is the overview of your uploaded data."}
                link={generatePath(routes.job_koi_kdf, {id: job.id})}
            />
            <DashBoardCard
                avatar={<KeyTaxonomyFactsIcon/>}
                title="Taxonomy summary"
                body={<Typography variant="h5">

                    <span>Number of classes: </span>
                    {jobStats === undefined ? '...' :
                        <span className={'highlight'}>{'' + jobStats.n_categories}</span>}
                    <br/>

                    <span>Number of levels: </span>
                    {jobStats === undefined ? '...' :
                        <span className={'highlight'}>{'' + jobStats.n_levels}</span>}
                    <br/>

                </Typography>}
                helpContent={"Here you see the categorization overview"}
                // buttonText="Coming soon"
                link={generatePath(routes.job_koi_tf, {id: job.id})}
            />
        </>)}

        {buildHeader('Mithra AI Services')}
        {buildGrid(<>
            <DashBoardCard
                avatar={<SpendClassificationIcon/>}
                title="Spend classification"
                body={<Typography variant="h5">

                    <span>Unclassified spend: </span>
                    {jobStats === undefined ? '...' :
                        <span className={'highlight'}>
                            <CurrencyComponent v={jobStats.unclassified_spend} nDigits={3}/>
                        </span>}

                </Typography>}
                helpContent={"Using your current data, the unclassified data will be automatically classified in almost no time"}
                link={generatePath(routes.job_koi_scl, {id: job.id})}
            />
            <DashBoardCard
                avatar={<SupplierNormalizationIcon/>}
                title="Supplier hierarchy finder"
                body={<Typography variant="h5">

                    <span>Number of suppliers: </span>
                    {jobStats === undefined ? '...' :
                        <span className={'highlight'}>{'' + jobStats.n_suppliers}</span>}
                    <br/>

                </Typography>}
                helpContent={"Using our AI algorithm we identify all the parent suppliers. This gives you a clear overview of your supplier base, towards a preferred supplier list"}
                link={generatePath(routes.job_koi_sh, {id: job.id})}
            />
        </>)}

        {/*{buildHeader('Coming up!')}*/}
        {/*{buildGrid(<>*/}
        {/*    <DashBoardCard*/}
        {/*        // avatar={<SpendClassificationIcon/>}*/}
        {/*        title="Sustainability analysis"*/}
        {/*        body={<Typography variant="h5">*/}
        {/*            <span>Unclassified spend: </span>*/}
        {/*            {jobStats === undefined ? '...' :*/}
        {/*                <span className={'highlight'}>*/}
        {/*                    <CurrencyComponent v={jobStats.unclassified_spend} nDigits={3}/>*/}
        {/*                </span>}*/}
        {/*        </Typography>}*/}
        {/*        // helpContent={""}*/}
        {/*        // link={generatePath(routes.job_koi_scl, {id: job.id})}*/}
        {/*    />*/}
        {/*    <DashBoardCard*/}
        {/*        // avatar={<SupplierNormalizationIcon/>}*/}
        {/*        title="Synergy analysis"*/}
        {/*        body={<Typography variant="h5">*/}
        {/*            <span>Number of suppliers: </span>*/}
        {/*            {jobStats === undefined ? '...' :*/}
        {/*                <span className={'highlight'}>{'' + jobStats.n_suppliers}</span>}*/}
        {/*            <br/>*/}
        {/*        </Typography>}*/}
        {/*        // helpContent={""}*/}
        {/*        // link={generatePath(routes.job_koi_sh, {id: job.id})}*/}
        {/*    />*/}
        {/*</>)}*/}
    </Grid>
});
