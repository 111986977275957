import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
import * as d3Voronoi from "d3-voronoi";
import './SlopeGraph.scss';
import {getColorByCat} from "../../../style/colors";

export type SlopeGraphDataPoint = {
    key: string,
    label?: string,
    values: { value: number, label?: string }[],
    color?: string,
}
export type SlopeGraphData = SlopeGraphDataPoint[];

type Props = {
    data: SlopeGraphData,
    height: number,
    width?: number,
    margin?: { left?: number, right?: number, top?: number, bottom?: number },
    labelPositioning?: { alpha: number, spacing: number, iterations: number },
    leftTitle?: string,
    rightTitle?: string,
    labelGroupOffset?: number,
    labelGroupLeftOffset?: number,
    labelGroupRightOffset?: number,
    labelKeyOffset?: number | null,
    labelKeyLeftOffset?: number | null,
    labelKeyRightOffset?: number | null,
    radius?: number,
    unfocusOpacity?: number,
};

const DEFAULT_MARGIN = {
    left: 275,
    right: 275,
    top: 100,
    bottom: 40,
};

type VSlopeGraphDataPoint = SlopeGraphDataPoint & {
    xLeftPosition: number;
    yLeftPosition: number;
    xRightPosition: number;
    yRightPosition: number;
};


// https://bl.ocks.org/tlfrd/042b2318c8767bad7a485098fbf760fc
export const SlopeGraph: React.FC<Props> = (
    {
        data,
        height,
        width,
        margin,
        labelPositioning,
        leftTitle,
        rightTitle,
        labelGroupOffset,
        labelGroupLeftOffset,
        labelGroupRightOffset,
        labelKeyOffset,
        labelKeyLeftOffset,
        labelKeyRightOffset,
        radius,
        unfocusOpacity,
    }
) => {
    if (width === undefined) {
        width = 255;
    }
    if (labelGroupOffset) {
        console.assert(labelGroupLeftOffset === undefined && labelGroupRightOffset === undefined);
        labelGroupLeftOffset = labelGroupOffset;
        labelGroupRightOffset = labelGroupOffset;
    }
    const LABEL_GROUP_OFFSET_L = labelGroupLeftOffset || 5;
    const LABEL_GROUP_OFFSET_R = labelGroupRightOffset || 5;
    if (labelKeyOffset !== undefined) {
        console.assert(labelKeyLeftOffset === undefined && labelKeyRightOffset === undefined);
        labelKeyLeftOffset = labelKeyOffset;
        labelKeyRightOffset = labelKeyOffset;
    }
    const LABEL_KEY_OFFSET_L = labelKeyLeftOffset === undefined ? 50 : labelKeyLeftOffset;
    const LABEL_KEY_OFFSET_R = labelKeyRightOffset === undefined ? 50 : labelKeyRightOffset;
    const RADIUS = radius || 6;
    const LABEL_POSITIONING = labelPositioning || {alpha: 0.5, spacing: 16, iterations: 10};
    const UN_FOCUS_OPACITY = unfocusOpacity || 0.5;
    const defaultMargin = {...DEFAULT_MARGIN}
    if (!leftTitle && !rightTitle) {
        defaultMargin.top /= 2;
    }
    const MARGIN = {...defaultMargin};
    if (margin) {
        if (margin.left !== undefined) {
            MARGIN.left = margin.left;
        }
        if (margin.right !== undefined) {
            MARGIN.right = margin.right;
        }
        if (margin.top !== undefined) {
            MARGIN.top = margin.top;
        }
        if (margin.bottom !== undefined) {
            MARGIN.bottom = margin.bottom;
        }
    }
    const graphWidth = width - MARGIN.left - MARGIN.right;
    const graphHeight = height - MARGIN.top - MARGIN.bottom;

    const svgRef = useRef<SVGSVGElement>(null);

    // Function to reposition an array selection of labels (in the y-axis)
    function relax(labels, position) {
        let nTries = 0;
        relaxImpl(labels, position, nTries);
        // hideTooDenseLabels(labels, position);
    }

    function hideTooDenseLabels(labels, position) {
        let done = false;
        while (!done) {
            let oneIsHidden = false;
            labels.each(function () {
                if (oneIsHidden) return;
                // @ts-ignore
                const a = this;
                const da = d3.select(a).datum() as any;
                if (da.hidden) return;
                const y1 = da[position];
                labels.each(function () {
                    if (oneIsHidden) return;
                    // @ts-ignore
                    const b = this;
                    if (a === b) return;
                    const db = d3.select(b).datum() as any;
                    if (db.hidden) return;
                    const y2 = db[position];
                    const deltaY = y1 - y2;
                    if (deltaY === undefined) return;

                    if (Math.abs(deltaY) > LABEL_POSITIONING.spacing) {
                        // Far enough apart
                        return;
                    }
                    // They are very closely together, remove EGO
                    db.hidden = true
                    oneIsHidden = true;
                })
            })
            if (!oneIsHidden) {
                done = true;
            }
        }
    }

    function relaxImpl(labels, position, nTries) {
        // console.log('relaxing constraints on:', labels);
        labels.each(function () {
            // @ts-ignore
            const a = this;
            const da = d3.select(a).datum() as any;
            const y1 = da[position];
            labels.each(function () {
                // @ts-ignore
                const b = this;
                if (a === b) return;
                const db = d3.select(b).datum() as any;
                const y2 = db[position];
                const deltaY = y1 - y2;
                if (deltaY === undefined) return;

                if (Math.abs(deltaY) > LABEL_POSITIONING.spacing) {
                    // Far enough apart
                    return;
                }
                // They are very closely together

                nTries += 1;
                const sign = deltaY > 0 ? 1 : -1;
                const adjust = sign * LABEL_POSITIONING.alpha;
                da[position] = +y1 + adjust;
                db[position] = +y2 - adjust;

                if (nTries <= LABEL_POSITIONING.iterations) {
                    relaxImpl(labels, position, nTries);
                }
            })
        })
    }

    function mouseover(event, d) {
        const data = d.data;
        const group = data.values[data.dataI].group;
        d3.select(group).attr("opacity", 1);
    }

    function mouseout() {
        d3.selectAll(".slope-group").attr("opacity", UN_FOCUS_OPACITY);
    }

    function mouseclick(event, d) {
        const data = d.data;
        console.log('clicked', data);
    }

    const getValueLabel = (d: { value: number; label?: string }) =>
        d.label ? d.label : (d.value).toPrecision(3);

    useEffect(() => {
        if (!data || !svgRef.current) {
            console.log('SlopeGraph.render: REJECT', svgRef.current, data);
        }
        console.log('SlopeGraph.render: ACCEPT', svgRef.current, data);

        const svg = d3.select(svgRef.current as SVGElement);
        svg.html(''); // clear

        // // DEBUG: show margins
        // svg.append('rect')
        //     .attr('x', MARGIN.left)
        //     .attr('y', MARGIN.top)
        //     .attr('width', graphWidth)
        //     .attr('height', graphHeight);

        const root = svg
            .append("g")
            .attr("transform", "translate(" + MARGIN.left + "," + MARGIN.top + ")");

        if (!data) return;
        // DATA DEPENDENT

        const nestedByName = data as VSlopeGraphDataPoint[];
        const allData: any[] = [];
        data.forEach(d => d.values.forEach((dd, dataI) => {
            const cd: any = {...d, dataI};
            cd.data = dd;
            allData.push(cd);
        }))
        const valMin = d3.min(nestedByName, d => d3.min(d.values, dd => dd.value)) || 0;
        const valMax = d3.max(nestedByName, d => d3.max(d.values, dd => dd.value)) || 1;

        const y1 = d3.scaleLinear()
            .domain([valMin, valMax])
            .range([graphHeight, 0]);
        const yScale = y1;

        const voronoi = d3Voronoi.voronoi<any>()
            .x(d => d.dataI === 0 ? 0 : graphWidth)
            .y(d => yScale(d.data.value))
            .extent([
                [-MARGIN.left, -MARGIN.top],
                [graphWidth + MARGIN.left + MARGIN.right, graphHeight + MARGIN.top + MARGIN.bottom],
            ])

        const borderLines = root.append("g")
            .attr("class", "border-lines")
        borderLines.append("line")
            .attr("x1", 0).attr("y1", 0)
            .attr("x2", 0).attr("y2", graphHeight);
        borderLines.append("line")
            .attr("x1", graphWidth).attr("y1", 0)
            .attr("x2", graphWidth).attr("y2", graphHeight);

        const slopeGroups = root.append("g")
            .selectAll("g")
            .data(nestedByName)
            .enter().append("g")
            .attr("class", "slope-group")
            .attr("id", function (d: any, i) {
                d.id = "group" + i;
                d.values[0].group = this;
                d.values[1].group = this;
                return d.id;
            });

        // slopeLines
        slopeGroups.append("line")
            .attr("class", "slope-line")
            .attr("x1", 0)
            .attr("y1", function (d) {
                return y1(d.values[0].value);
            })
            .attr("x2", graphWidth)
            .attr("y2", function (d) {
                return y1(d.values[1].value);
            })
            .attr('stroke', d => getColorByCat(d.key))

        // leftSlopeCircle
        slopeGroups.append("circle")
            .attr("r", RADIUS)
            .attr("cy", d => y1(d.values[0].value))
            .attr('fill', d => getColorByCat(d.key))

        // leftSlopeLabels
        const leftSlopeLabels = slopeGroups.append("g")
            .attr("class", "slope-label-left")
            .each(function (d: any) {
                d.xLeftPosition = -LABEL_GROUP_OFFSET_L;
                d.yLeftPosition = y1(d.values[0].value);
            });

        leftSlopeLabels.append("text")
            .attr("class", "label-figure")
            .attr("x", d => d.xLeftPosition)
            .attr("y", d => d.yLeftPosition)
            .attr("dx", -10)
            .attr("dy", 3)
            .attr("text-anchor", "end")
            .text(d => d.label !== undefined ? d.label : (d.values[0].value).toPrecision(3));

        if (LABEL_KEY_OFFSET_L !== null)
            leftSlopeLabels.append("text")
                .attr("x", d => d.xLeftPosition)
                .attr("y", d => d.yLeftPosition)
                .attr("dx", -LABEL_KEY_OFFSET_L)
                .attr("dy", 3)
                .attr("text-anchor", "end")
                .text(d => getValueLabel(d.values[0]));

        // rightSlopeCircle
        slopeGroups.append("circle")
            .attr("r", RADIUS)
            .attr("cx", graphWidth)
            .attr("cy", d => y1(d.values[1].value))
            .attr('fill', d => getColorByCat(d.key))

        const rightSlopeLabels = slopeGroups.append("g")
            .attr("class", "slope-label-right")
            .each(function (d) {
                d.xRightPosition = graphWidth + LABEL_GROUP_OFFSET_R;
                d.yRightPosition = y1(d.values[1].value);
            });

        rightSlopeLabels.append("text")
            .attr("class", "label-figure")
            .attr("x", d => d.xRightPosition)
            .attr("y", d => d.yRightPosition)
            .attr("dx", 10)
            .attr("dy", 3)
            .attr("text-anchor", "start")
            .text(d => getValueLabel(d.values[1]));

        if (LABEL_KEY_OFFSET_R !== null)
            rightSlopeLabels.append("text")
                .attr("x", d => d.xRightPosition)
                .attr("y", d => d.yRightPosition)
                .attr("dx", LABEL_KEY_OFFSET_R)
                .attr("dy", 3)
                .attr("text-anchor", "start")
                .text(d => d.key);

        const titles = root.append("g")
            .attr("class", "title");

        if (leftTitle)
            titles.append("text")
                .attr("text-anchor", "end")
                .attr("dx", -10)
                .attr("dy", -MARGIN.top / 2)
                .text(leftTitle);

        if (rightTitle)
            titles.append("text")
                .attr("x", graphWidth)
                .attr("dx", 10)
                .attr("dy", -MARGIN.top / 2)
                .text(rightTitle);

        relax(leftSlopeLabels, "yLeftPosition");
        leftSlopeLabels.selectAll<SVGTextElement, VSlopeGraphDataPoint>("text")
            .attr("y", d => d.yLeftPosition);

        relax(rightSlopeLabels, "yRightPosition");
        rightSlopeLabels.selectAll<SVGTextElement, VSlopeGraphDataPoint>("text")
            .attr("y", d => d.yRightPosition);

        d3.selectAll(".slope-group")
            .attr("opacity", UN_FOCUS_OPACITY);

        const voronoiGroup = root.append("g")
            .attr("class", "voronoi");

        voronoiGroup.selectAll("path")
            .data(voronoi.polygons(allData))
            .enter().append("path")
            .attr("d", function (d) {
                return d ? "M" + d.join("L") + "Z" : null;
            })
            .on("mouseover", mouseover)
            .on("mouseout", mouseout)
            .on("click", mouseclick);
    }, [data])

    return <svg
        className="slope-graph-chart"
        ref={svgRef}
        viewBox={`0 0 ${width} ${height}`}
        style={{width: '100%', height: 'auto'}}/>;
};
