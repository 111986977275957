import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
import {PieArcDatum} from "d3";
import {COLOR_UNCATEGORIZED, vizColor} from "../../style/colors";
import {toCurrency} from "../currency-component/CurrencyComponent";

export type PieDataPoint = { name: string, value: number }
export type PieData = PieDataPoint[];
type Props = {
    data?: PieData;
    loading?: boolean;
};

const WIDTH = 255;
const HEIGHT = 255;
const MARGIN = 5;

export const PieView: React.FC<Props> = ({data, loading}) => {
    const svgRef = useRef<SVGSVGElement>(null)

    const toLabel = (d: PieArcDatum<PieDataPoint>) => d.data.name || 'uncategorized';

    useEffect(() => {
        if ((!data && !loading) || !svgRef.current) {
            console.log('PieView.render: REJECT', data, loading, svgRef.current);
            return;
        }
        console.log('PieView.render: ACCEPT', svgRef.current, data);
        const svg = d3.select(svgRef.current as SVGElement);
        svg.html(''); // clear
        const radius = Math.min(WIDTH, HEIGHT) / 2 - 1;
        const labelRadius = Math.min(WIDTH, HEIGHT) / 2 * 0.8;

        const arc = d3.arc()
            .innerRadius(0)
            .outerRadius(radius);

        if (loading || true) {
            svg.append('g')
                .selectAll('path')
                .data(d3.pie()([1]))
                .join('path')
                // .attr("fill", vizColor('loading'))
                .attr("fill", 'grey')
                .attr('d', arc as any);
            svg.append('g')
                .attr("font-size", 12)
                .attr("text-anchor", "middle")
                .append('text')
                .text('Loading')
        }

        if (data) {
            const arcs = d3.pie<PieDataPoint>()
                .value(d => d.value)
                (data);
            const labelArc = d3.arc<PieArcDatum<PieDataPoint>>()
                .innerRadius(labelRadius)
                .outerRadius(labelRadius);

            const pathS = svg.append('g')
                .selectAll('path')
                .data(arcs)
                .join('path');
            pathS
                // eslint-disable-next-line eqeqeq
                .attr("fill", d => (d.data.name == 'uncategorized' || !d.data.name) ? COLOR_UNCATEGORIZED : vizColor(d.data.name))
                .attr('d', arc as any);
            pathS.append('title')
                .text(d => `${toLabel(d)}: ${toCurrency(d.data.value, 3)}`);

            svg.append('g')
                // .attr("font-family", "sans-serif")
                .attr("font-size", 12)
                .attr("text-anchor", "middle")
                .selectAll("text")
                .data(arcs.filter(d => !d.data.name || Math.abs(d.endAngle - d.startAngle) > Math.PI / 11))
                .join("text")
                .attr("transform", d => `translate(${labelArc.centroid(d)})`)
                .call(text => text.append("tspan")
                    .attr("y", "-0.4em")
                    .attr("font-weight", "bold")
                    .text(d => toLabel(d))
                )
                .call(text => text
                    // Filters small values:
                    // .filter(d => (d.endAngle - d.startAngle) > 0.25)
                    .append("tspan")
                    .attr("x", 0)
                    .attr("y", "0.7em")
                    .attr("fill-opacity", 0.7)
                    .text(d => toCurrency(d.data.value))
                );
        }

        // setRendered(true);
    }, [data, loading])

    return <svg
        ref={svgRef}
        viewBox={`${-MARGIN - WIDTH / 2} ${-MARGIN - HEIGHT / 2} ${WIDTH + 2 * MARGIN} ${HEIGHT + 2 * MARGIN}`}
        style={{width: '100%', height: '100%'}}
    />;
};
