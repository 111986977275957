import {AxiosError} from "axios";
import {KpiBuilder_kpis} from "./ApiTypes";
import {Job, JobInput, JobMeta} from "./classes/JobProcessing";
import {InputStatusEnum, JobStatusEnum} from "./classes/StatusEnums";


// export function processJobResponse(p: Promise<AxiosResponse<Job>>) {
//     return p.then(resp => {
//         const job = resp.data;
//         const uniqueMeta: JobMeta[] = [];
//
//         if (job.meta) {
//             job.meta.reverse().forEach(m => {
//                 if (!uniqueMeta.find(m2 => m2.key === m.key)) {
//                     uniqueMeta.push(m);
//                 }
//             });
//             job.meta = uniqueMeta.reverse();
//             job.meta = uniqueMeta.map(m => processMeta(m)).filter(m => m);
//             const metrics = job.meta.find(m => m.key === 'metrics');
//             if (metrics) {
//                 let m = JSON.parse(metrics.data);
//                 if (m.length > 0) {
//                     m = m[0];
//                     // const accuracy = '' + m.matrics.best_epoch_metrics.accuracy;
//                     // const newly_categorized = '?'; // m.performance?.newly_categorized
//                     // // const accuracy = (Math.round(m.matrics.best_epoch_metrics.accuracy * 100) / 100).toFixed(2)
//                     job.metrics = {
//                         completed_date: {title: 'Completed at', value: new Date(m.date)},
//                         // accuracy: {title: 'Newly categorized', value: newly_categorized},
//                         // rest: {title: 'Rest', value: JSON.stringify(m.matrics)}, // For debugging print all metrics
//                     };
//                 } else {
//                     job.metrics = {};
//                 }
//             } else {
//                 job.metrics = {};
//             }
//         } else {
//             job.metrics = {};
//         }
//         return job;
//     });
// }

export function isTokenExpired(err: AxiosError) {
    if (err.response) {
        const resp = err.response;
        if (resp.status === 401 && resp.data.code === 'token_not_valid') {
            return true;
        }
    }
    return false;
}

export function processMeta(m: JobMeta): any {
    switch (m.key) {
        case 'parts_count':
            m.key = 'Number of records';
            break;
        case 'suppliers_count':
            m.key = 'Number of suppliers';
            break;
        case 'unparsable_count':
            m.key = 'Number of incomplete records';
            m.data = m.data + ' (not used for classification)';
            break;
        case 'labeled_parts_count':
            m.key = 'Number of unclassified records';
            m.data = '' + 150;
            break;
    }
    return m;
}


export function filterKpi(kpi: KpiBuilder_kpis): boolean {
    switch (kpi) {
        default:
            return true;
    }
}

export function kpiToReadableString(kpi: KpiBuilder_kpis): string {
    switch (kpi) {
        case 'count_tree':
            return 'Number of records';
        case 'spend_tree':
            return 'Spend (€)';
        default:
            return kpi;
    }
}

export function displayJobMessage(job: Job) {
    switch (job.current_status.status) {
        case JobStatusEnum.B_CONFIGURING:
            if (job.inputs.length > 1) {
                // TODO
                return 'Processing multiple jobs...'
            }
            const jobInput = job.inputs[0]; // Assume we have only one JobInput
            switch (jobInput.current_status.status) {
                case InputStatusEnum.I_CONFIGURING: // Goes to PARSING immediately
                case InputStatusEnum.I_PARSING:
                    return 'Reading and optimizing file'
                case InputStatusEnum.I_READY:
                    return 'Combining files'
            }
            return 'Processing'
        case JobStatusEnum.B_PROCESSING:
            return 'Combining files'
        case JobStatusEnum.B_BUSY:
            return 'Creating overview'
    }
    return '';
}

export function DataInputIsReadyToStart(di: JobInput) {
    return di.parser_setting && di.current_status.status === InputStatusEnum.I_CONFIGURING;
}
