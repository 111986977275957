import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {matchPath, useRouteMatch} from "react-router-dom";
import {useLocation} from "react-router";
import {useStores} from "../../stores";
import {LoadingJobComponent} from "../../components/loading-job/LoadingJobComponent";
import {JobHistoryState, JobRouteMatch, routes} from "../../routing/routes";
import {KOISpendConcentration} from "../koi/spend-concentration/KOISpendConcentration";
import {KOIPurchasePriceVariance} from "../koi/purchase-price-variance/KOIPurchasePriceVariance";
import {JobDashBoard} from "../dashboard/JobDashBoard";
import {NotFound} from "../../routing/NotFound";
import {JobStatusEnum} from "../../services/classes/StatusEnums";
import {noop} from "rxjs";
import {JobInputStepChooser} from "./JobInputStepChooser";
import {displayJobMessage} from "../../services/ApiHelpers";

export const JobStepChooser: React.FC = observer(() => {
    const match = useRouteMatch<JobRouteMatch>();
    const location = useLocation<JobHistoryState>();
    const {jobStore, jobLoadingStore} = useStores();
    const job = jobStore.getJob();
    const inputs = job.inputs;

    useEffect(() => {
        const loading = jobLoadingStore.onLoadJob();
        if (loading) {
            const sub = loading.subscribe();
            return () => sub.unsubscribe();
        } else return noop;
    }, [jobLoadingStore, job])

    switch (job.current_status.status) {
        case JobStatusEnum.B_CONFIGURING:
            // We should focus on the active job inputs
            return <>
                {/*<p>job status = {displayJobStatusEnum(job.current_status.status)}</p>*/}
                <LoadingJobComponent message={displayJobMessage(job)}/>
                {inputs.map(ji => <JobInputStepChooser key={ji.id + '-' + ji.current_status.timestamp} jobInput={ji}/>)}
            </>;
        case JobStatusEnum.B_PROCESSING:
            // No input can be changed
            return <>
                {/*<p>job status = {displayJobStatusEnum(job.current_status.status)}</p>*/}
                <LoadingJobComponent message={displayJobMessage(job)}/>
            </>;
        case JobStatusEnum.B_READY:
            if (match.isExact) {
                return <JobDashBoard/>
            }
            if (matchPath(location.pathname, {path: routes.job_koi_sc})) {
                return <KOISpendConcentration/>
            }
            if (matchPath(location.pathname, {path: routes.job_koi_ppv})) {
                return <KOIPurchasePriceVariance/>
            }
            if (matchPath(location.pathname, {path: routes.job_koi_bu})) {
                return <p>WIP: {routes.job_koi_bu}</p>
            }
            return <NotFound msg={"Could not find " + location.pathname}/>
        case JobStatusEnum.B_BUSY:
            // No input can be changed
            return <LoadingJobComponent message={displayJobMessage(job)}/>;
        case JobStatusEnum.B_ERROR:
            console.error('B_ERROR not implemented yet');
            break;
        case JobStatusEnum.B_CANCELLED:
            break;
        case JobStatusEnum.B_DISCARDED:
            break;
    }
    console.error('Unknown job status:', {...job.current_status});
    return <p>Error</p>
});
