import React, {useEffect, useRef} from "react";
import {useTheme} from "@material-ui/core/styles";
import * as d3 from "d3";
import * as d3Hierarchy from "d3-hierarchy";
import {COLOR_UNCATEGORIZED} from "../../../style/colors";
import {darken} from "@material-ui/core";
import './TreeGraph.scss';


type Data = {
    children: Data[];
    value: number;
    label: string;
}
let i = 0;
const DURATION = 400;
export const TreeGraph: React.FC<{
    data: Data,
    highlight?: {
        matches: string[],
        color: string,
    }
    legend?: { label: string, color: string }[],
    expand?: boolean,
}> = ({data, highlight, legend, expand}) => {
    const theme = useTheme();

    // Nice aspect ration
    const width = 500;
    const height = 750;

    // TODO: Remove customer specific modifications
    // // Stryker hardcoded aspect ration
    // const width = 715;
    // // const height = 1000;
    // const height = 2000;

    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (!data || !svgRef.current) {
            console.log('TreeGraph.render: REJECT', svgRef.current, data);
        }
        console.log('TreeGraph.render: ACCEPT', svgRef.current, data);

        const svg = d3.select(svgRef.current as SVGElement);
        svg.html(''); // clear

        const rootEl = svg.append('g')

        const treemap = d3Hierarchy.tree().size([height, width]);
        const root = d3Hierarchy.hierarchy(data, d => d.children);
        (root as any).x0 = height / 2;
        (root as any).y0 = 0;
        root.data.label = 'Taxonomy tree';

        // Collapse after the second level
        console.assert(root.children);
        if (expand) {
            // Expanded by default
        } else {
            root.children?.forEach(collapse);
        }

        update(root);

        // Collapse the node and all it's children
        function collapseAll(d) {
            if (d.children) {
                d._children = d.children
                d._children.forEach(collapseAll)
                d.children = null
            }
        }

        // Collapse the node and all it's children
        function collapse(d) {
            if (d.children) {
                d._children = d.children
                d.children = null
            }
        }

        function update(source) {

            // Assigns the x and y position for the nodes
            var treeData = treemap(root);

            // Compute the new tree layout.
            var nodes = treeData.descendants(),
                links = treeData.descendants().slice(1);

            // Normalize for fixed-depth.
            nodes.forEach(function (d) {
                d.y = d.depth * 180
            });

            // ****************** Nodes section ***************************

            // Update the nodes...
            var node = svg.selectAll('g.node')
                .data(nodes, function (d: any) {
                    return d.id || (d.id = ++i);
                });

            // Enter any new modes at the parent's previous position.
            var nodeEnter = node.enter().append('g')
                .attr('class', 'node clickable')
                .attr("transform", function (d) {
                    return "translate(" + source.y0 + "," + source.x0 + ")";
                })
                .on('click', click);

            // Add Circle for the nodes
            const setFill = function (d) {
                if ((d.data as any).label === '') {
                    return COLOR_UNCATEGORIZED;
                }
                if (highlight) {
                    if (highlight.matches.includes((d.data as any).label)) {
                        return highlight.color;
                    }
                }
                return (d as any)._children
                    ? theme.palette.primary.main
                    : darken(theme.palette.primary.main, 0.5);
            };
            nodeEnter.append('circle')
                .attr('class', 'node')
                .attr('r', 1e-6)
                .style("fill", setFill);

            // Add labels for the nodes
            nodeEnter.append('text')
                .attr("dy", ".35em")
                .attr("x", function (d) {
                    // TODO: Remove customer specific modifications
                    // For STRYKER:
                    return 13;

                    // Show end nodes with label on the right
                    // return d.children || (d as any)._children ? -13 : 13;
                })
                .attr("text-anchor", function (d) {
                    // TODO: Remove customer specific modifications
                    // For STRYKER:
                    return "start"

                    // Show end nodes with label on the right
                    // return d.children || (d as any)._children ? "end" : "start";
                })
                .text(function (d) {
                    // TODO: Make coloring a fixed constant
                    if ((d.data as any).label === '') {
                        console.log('UNCAT', d);
                        return '<uncategorized>';
                    }
                    return (d.data as any).label;
                });

            // UPDATE
            var nodeUpdate = nodeEnter.merge(node as any);

            // Transition to the proper position for the node
            nodeUpdate.transition()
                .duration(DURATION)
                .attr("transform", function (d) {
                    return "translate(" + d.y + "," + d.x + ")";
                });

            // Update the node attributes and style
            nodeUpdate.select('circle.node')
                .attr('r', 10)
                .style("fill", setFill)
                .attr('cursor', 'pointer');


            // Remove any exiting nodes
            var nodeExit = node.exit().transition()
                .duration(DURATION)
                .attr("transform", function (d) {
                    return "translate(" + source.y + "," + source.x + ")";
                })
                .remove();

            // On exit reduce the node circles size to 0
            nodeExit.select('circle')
                .attr('r', 1e-6);

            // On exit reduce the opacity of text labels
            nodeExit.select('text')
                .style('fill-opacity', 1e-6);

            // ****************** links section ***************************

            // Update the links...
            var link = svg.selectAll('path.link')
                .data(links, function (d) {
                    return (d as any).id;
                });

            // Enter any new links at the parent's previous position.
            var linkEnter = link.enter().insert('path', "g")
                .attr("class", "link")
                .attr('d', function (d) {
                    var o = {x: source.x0, y: source.y0}
                    return diagonal(o, o)
                });

            // UPDATE
            var linkUpdate = linkEnter.merge(link as any);

            // Transition back to the parent element position
            linkUpdate.transition()
                .duration(DURATION)
                .attr('d', function (d) {
                    return diagonal(d, d.parent)
                });

            // Remove any exiting links
            var linkExit = link.exit().transition()
                .duration(DURATION)
                .attr('d', function (d) {
                    var o = {x: source.x, y: source.y}
                    return diagonal(o, o)
                })
                .remove();

            // Store the old positions for transition.
            nodes.forEach(function (d) {
                (d as any).x0 = d.x;
                (d as any).y0 = d.y;
            });

            // Creates a curved (diagonal) path from parent to the child nodes
            function diagonal(s, d) {
                const path = `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`
                return path
            }

            // Toggle children on click.
            function click(event, d) {
                if (d.children) {
                    d._children = d.children;
                    d.children = null;
                } else {
                    d.children = d._children;
                    d._children = null;
                }
                update(d);
            }

            if (legend && !svg.select('g.legend').node()) {
                const legendOffset = -100;
                const FONT_SIZE = 18;

                const legendGroup = svg.append('g')
                    .classed('legend', true);

                legendGroup.append('text')
                    .classed('legend-header', true)
                    .text('Legend')
                    .attr('x', legendOffset + 12)
                    .attr('y', FONT_SIZE * 1.3 + 1)
                    .attr('alignment-baseline', 'middle')

                const legendItems = legendGroup.selectAll<SVGGElement, any>('g.legend-item')
                    .data(legend, d => d.label)
                    .join('g')
                    .classed('legend-item', true);

                legendItems.append('circle')
                    .attr('cx', legendOffset)
                    .attr('cy', (_, i) => (i + 2) * FONT_SIZE * 1.3)
                    .attr('r', 6)
                    .style("fill", d => d.color)
                legendItems.append('text')
                    .text(d => d.label)
                    .attr('x', legendOffset + 12)
                    .attr('y', (_, i) => (i + 2) * FONT_SIZE * 1.3 + 1)
                    .attr('alignment-baseline', 'middle')
                    .style("fill", d => d.color)

                // // Enter any new links at the parent's previous position.
                // var linkEnter = link.enter().insert('path', "g")
                //     .attr("class", "link")
                //     .attr('d', function (d) {
                //         var o = {x: source.x0, y: source.y0}
                //         return diagonal(o, o)
                //     });
                //
                // // UPDATE
                // var linkUpdate = linkEnter.merge(link as any);
            }

        }
    }, [data]);

    return <svg
        className="tree-graph"
        ref={svgRef}
        viewBox={`0 0 ${width} ${height}`}
        style={{width: '100%', height: height + 'px'}}/>;
};
