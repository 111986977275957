import {action, autorun, computed, makeObservable, observable, reaction, runInAction,} from 'mobx';
import {HierarchyApiResponse, KpiBuilder_tree_kpis} from "../services/ApiTypes";
import {ApiStore} from "../services/Http";
import MithraApi from "../services/MithraApi";
import {BoxplotDataPoint, interpretBoxPlotData} from '../components/visualization/boxplot/Boxplot';
import {noop} from "rxjs";
import {Job, JobMeta} from "../services/classes/JobProcessing";
import {InputStatusEnum, JobStatusEnum} from "../services/classes/StatusEnums";

export class JobStore extends ApiStore {

    job: Job | undefined = undefined;

    parserConfig = '';

    constructor(api: MithraApi) {
        super(api)
        makeObservable(this, {
            job: observable,
            parserConfig: observable,
            setJob: action,
            setParserConfig: action,
            jobKpiTotalSpend: observable,
            meta: computed,
            // jobInputs: computed,
        });
        reaction(
            () => this.parserConfig,
            _ => console.log('parserConfig:', this.parserConfig)
        );
        // reaction(
        //     () => this.jobInputs,
        //     _ => console.debug('JobStore.jobInputs changed')
        // );
        autorun(() => {
            console.log('job.meta=', JSON.parse(JSON.stringify(this.meta)));
        });
    }

    get jobId() {
        if (!this.job) throw new Error();
        return this.job.id;
    }

    setJob(job: Job) {
        const inputs_str = job.inputs.map(i => `${i.id}(${InputStatusEnum[i.current_status.status]})`);
        console.debug(`JobStore.setJob: job=${job.id}(${JobStatusEnum[job.current_status.status]}), inputs=[${inputs_str}]`, {...job});
        this.job = job;
    }

    getJob(): Job {
        if (!this.job) throw new Error();
        return this.job;
    }

    // get jobInputs(): JobInput[] {
    //     if (!this.job) throw new Error();
    //     return this.job.inputs;
    // }

    getJobId(): number {
        if (!this.job) throw new Error();
        if (!this.job.id) throw new Error();
        return this.job.id;
    }

    // @action
    // setLoadingJobMeta(isLoadingJobMeta: boolean) {
    //     this.isLoadingJobMeta = isLoadingJobMeta;
    //     if (isLoadingJobMeta)
    //         this.mainProgress = 101
    //     else
    //         this.mainProgress = -1;
    // }

    // @action
    // setMainProgress(p: number) {
    //     this.mainProgress = p;
    // }
    // TODO: Replace with loadingBar(Store).[...]();

    setParserConfig = (parserConfig: string) => {
        this.parserConfig = parserConfig;
    };

    jobKpiTotalSpend: HierarchyApiResponse[] = [];

    loadKpis() {
        console.log(`JobStore.loadKpis(), this.jobId=${this.jobId}`);
        this.api.getKpiTree(this.jobId, 'spend_tree').then(resp => runInAction(() => {
            this.jobKpiTotalSpend = resp.data.children
        }))
    }

    get meta(): JobMeta[] {
        return this.job?.meta || [];
    }

    getGraphKpi(kpi: KpiBuilder_tree_kpis, ensurePositive = true, filterBelow = 100) {
        return this.api.getGraphKpi(this.jobId, kpi, ensurePositive, filterBelow);
    }

    ppvData = page => this.api.getPPVData(this.jobId, page);
    ppvDataAll = () => this.api.getPPVDataAll(this.jobId);
    ppvBoxplotData: (limit: number) => Promise<BoxplotDataPoint[]> = (limit) => this.api.getPPVBoxplot(this.jobId).then(
        resp => resp.data
            .map(d => interpretBoxPlotData(d, 'unit_cost'))
            .slice(0, limit)
    );
    downloadPPV = () => this.api.downloadPPV(this.jobId);
    getJobStats = () => this.api.getJobStats(this.jobId);
    getTotalPriceVarianceGroups = () => this.api.getTotalPriceVarianceGroups(this.jobId);
    getPriceVarianceGroupedGroups = () => this.api.getPriceVarianceGroupedGroups(this.jobId);
    getJobKpiTotalSpend = () => this.api.getKpiTree(this.jobId, 'spend_tree');
    getJobTotalSpendL1 = () => this.api.getKpiTree(this.jobId, 'spend_tree')
        .then(resp => {
            // Clean deeper levels
            resp.data.children.forEach(c => c.children ? delete (c as any).children : noop());
            return resp.data.children as { label: string, value: number }[];
        });
    getJobTotalOutSpendL1 = () => this.api.getKpiTree(this.jobId, 'out_spend_tree')
        .then(resp => {
            // Clean deeper levels
            resp.data.children.forEach(c => c.children ? delete (c as any).children : noop());
            return resp.data.children as { label: string, value: number }[];
        });
    getAiCompareResultFull = () => this.api.getAiCompareResultFull(this.jobId);
    getAiCompareResultL1 = (l1) => this.api.getAiCompareResultByLevel(this.jobId, l1);
    getAiCompareResultByLevel = (l1, l2, l3, l4) => this.api.getAiCompareResultByLevel(this.jobId, l1, l2, l3, l4);
    getAiCompareResultByLevels = (...ls) => this.api.getAiCompareResultByLevel(this.jobId, ...ls);
    getJobKoiCumSuppliers = (top) => this.api.getJobKoiCumSuppliers(this.jobId, top);
    getJobKoiCumSuppliersL1Complete = () => this.api.getJobKoiCumSuppliersL1Complete(this.jobId);
    getJobKoiCumSuppliersL1Summary = () => this.api.getJobKoiCumSuppliersL1Summary(this.jobId);
    getJobKoiCumSuppliersAllGroups = () => this.api.getJobKoiCumSuppliersAllGroups(this.jobId);
    getJobKoiCumSuppliersL1 = (labelL1: string) => this.api.getJobKoiCumSuppliersL1(this.jobId, labelL1);
    getPartData = (supplier_name) => this.api.getPartData(this.jobId, supplier_name);
    getJobTaxonomyKpis = () => this.api.getJobTaxonomyKpis(this.jobId);
}
