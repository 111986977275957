
const API_HOST = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8500'
// const API_HOST = 'http://localhost:8500' || process.env.REACT_APP_BACKEND_URL || 'http://localhost:8500'
const staticFilesUrl =
    process.env.REACT_APP_STATIC_URL
    || (process.env.REACT_APP_DJANGO_GCP_STATIC_BUCKET ? `https://storage.googleapis.com/${process.env.REACT_APP_DJANGO_GCP_STATIC_BUCKET}/static` : '')
    || 'http://localhost:8500/static';
const environmentName = process.env.REACT_APP_ENVIRONMENT || '?';
const IS_DEV = process.env.REACT_APP_ENVIRONMENT === 'dev';
// const IS_DEV = true;
const baseUrl = process.env.REACT_APP_URL || 'http://localhost:3008';
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

const staticIo = {
    inFilename: '20210409 Spend Analysis.xlsx',
    outFilename: '20210409 Spend Analysis_result.xlsx',
    outFileUrl: 'https://storage.googleapis.com/mithra-cat-production-static/demo/20210409%20Spend%20Analysis_result.xlsx',
}

const demoFiles = {
    spendClassificationResult: {
        name: 'LibertyGlobal-Categorization.xlsx',
        url: 'https://storage.googleapis.com/mithra-cat-production-static/demo/LibertyGlobal-Categorization.xlsx',
    },
    supplierHierarchy: {
        name: 'LibertyGlobal-SupplierHierarchy.xlsx',
        url: 'https://storage.googleapis.com/mithra-cat-production-static/demo/LibertyGlobal-SupplierHierarchy.xlsx',
    }
}

export const DEV_USER_ID = 5;

export const environment = {
    environmentName,
    production: !IS_DEV,
    devMenu: IS_DEV,
    devLogin: IS_DEV, // Automatically login with DEV_USER_ID
    // devLogin: false,
    authUrl: `${API_HOST}/auth`,
    apiUrl: `${API_HOST}/api`,
    adminUrl: `${API_HOST}/admin`,
    staticFilesUrl,
    apiDelay: IS_DEV ? 1500 : 0,
    asyncPollDelay: IS_DEV ? 2500 : 500,
    baseUrl,
    googleClientId,
    staticIo,
    demoFiles,
};

export const TEMPLATE_URL = environment.staticFilesUrl + '/Mithra%20template%20v1.xlsx';
export const TEMPLATE_NAME = 'Mithra template v1.xlsx';

export const HACKS = [
    // 'sankey_tax_switch',
];

export const TIMINGS = {
    'input_loading': [
        1, // Uploading
        15, // Parsing Excel
        7, // Optimizing Excel
        3, // Merging File(s)
    ], // seconds
}
