import React from 'react'
import {createStyles, Paper, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {Alert} from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import {DropProps} from "./dropSpecification";
import './DropFile.scss';

type Props = {
    droppedFile?: File,
    isBusy: boolean,
    uploadErrorMessage: string,
    dropProps: DropProps,
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
        },
        dropActive: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        rootDragFail: {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.contrastText,
        },
        rootDragBusy: {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info.contrastText,
        }
    }),
);


// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/function_components
export const DropFile: React.FC<Props> = ({droppedFile, isBusy, uploadErrorMessage, dropProps}) => {
    const classes = useStyles();

    // droppedFile = {name: 'Anonymized_dataset_Zqjh8tR (1).xlsx'} as File;
    // isBusy = true;

    const paperClass = dropProps.canDrop
        ? classes.dropActive
        : uploadErrorMessage
            ? classes.rootDragFail
            : droppedFile
                ? (isBusy ? classes.dropActive : classes.rootDragBusy)
                : (dropProps.canDrop ? classes.dropActive : classes.root);

    return <Paper className={paperClass + ' drop-file-component'} elevation={4}>
        {dropProps.canDrop ? <Typography>Drop Excel file</Typography>
            : <>
                {droppedFile
                    ? <Typography>{droppedFile.name}</Typography>
                    : <Typography>Drag an Excel file here</Typography>}
                {isBusy ? <Typography>Uploading, please wait</Typography> : <></>}
                {uploadErrorMessage ? <Alert severity="error">{uploadErrorMessage}</Alert> : <></>}
            </>
        }
    </Paper>
}
