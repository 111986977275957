import React, {useEffect} from "react";
import {Button, Grid, ListItem, ListItemText} from "@material-ui/core";
import {useStores} from "../../stores";
import {JobKpis} from "./JobKpis";
import {BarChart} from "../../components/visualization/BarChart";
import * as d3 from "d3";
import {PieView} from "../../components/visualization/PieView";
import {PreVisualizationView} from "./PreVisualization";
import {environment} from "../../env";
import {observer} from "mobx-react-lite";
import {Job} from "../../services/classes/JobProcessing";

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         acceptButton: {
//             margin: theme.spacing(2),
//         },
//         textRight: {
//             textAlign: 'right',
//         },
//     }),
// );

export const ConfigureStepView = observer(() => {
    // TODO: Needs to be revised
    const {jobStore} = useStores();
    const job = jobStore.job as Job;
    if (!job) throw new Error();

    useEffect(() => {
        jobStore.loadKpis();
    }, [])

    const barKpis = ['is_labelled_l1', 'is_labelled_l2', 'is_labelled_l3'];
    const barData = jobStore.meta
        .filter(m => barKpis.includes(m.key))
        .map((m, i) => ({
            value: Number(m.data),
            category: `L${i + 1}`,
        }))
        .sort((a, b) => d3.descending(a.category, b.category))
        .concat([{
            value: Number(job.meta.find(m => m.key === 'n_parts')?.data),
            category: 'Total',
        }]);
    const pieData = jobStore.jobKpiTotalSpend.map(tl => ({
        'name': tl.label,
        'value': tl.value,
    }));

    const clickContinue = () => {
        // jobStore.setStep(2);
        // // // Start enrichment
        // // const oldJob = job;
        // // loadingBar.start(true);
        // // // Note: skipped enriching for now
        // // JobService.startJobCategorization(oldJob.id).then(nextJob => {
        // //     jobStore.setJob(nextJob);
        // // }).finally(() => {
        // //     loadingBar.setComplete();
        // // })
    };

    return <>
        <ListItem>
            <ListItemText
                primary={<h2 className={'m-0'}>Visualization</h2>}
                secondary={<p>Filename: {environment.staticIo?.inFilename || job.name}</p>}
            />
        </ListItem>
        {/*<h2>Spend analysis 2020 Final.xlsx</h2>*/}

        <Grid container spacing={1} direction="row">
            <Grid item xs={6} sm={3}>
                <JobKpis/>
            </Grid>
            {/*<BarChart data={barData}/>*/}
            <Grid item xs={6} sm={4}>
                {barData ? <>
                        <BarChart data={barData} height={85}/>
                        <p className="text-center mb-0" style={{marginLeft: '12%'}}>
                            The number of records per category
                        </p>
                    </>
                    : <p>Loading</p>}
            </Grid>
            <Grid item xs={6} sm={5}>
                <div style={{height: '250px'}}>
                    <PieView data={pieData} loading={!pieData}/>
                </div>
                <p className="text-center mb-0">The spend per category (of L1)</p>
            </Grid>
        </Grid>
        <hr/>

        <PreVisualizationView/>
        <hr/>

        {/*<div className="text-right">*/}
        {/*    <Button className="accept-button" variant="contained" color="secondary"*/}
        {/*            disabled={!!error}*/}
        {/*            onClick={clickContinue}>*/}
        {/*        Continue*/}
        {/*    </Button>*/}
        {/*</div>*/}

        <div className="text-right" style={{margin: '2em'}}>
            <Button className="accept-button" variant="contained" color="secondary"
                    onClick={clickContinue}>
                Continue
            </Button>
        </div>
    </>
});
