let seed = 4798;
const myR = () => {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
}

export const fakeSuppliersData = [...Array(100)].map(() => {
    return `supplier_${Math.round(myR() * 3728)}`;
});
export const fakeItemNumberData = [...Array(100)].map(() => {
    return `${Math.round(myR() * 4564893468)}`;
});
