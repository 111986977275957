export function arrCopyAdd<T>(array: T[], value: T): T[] {
    const copy = [...array];
    copy.push(value);
    return copy;
}

export function arrCopyRemoveIndex<T>(array: T[], index: number): T[] {
    const copy = [...array];
    copy.splice(index, 1);
    return copy;
}

export function normalize_arr(arr: number[]) {
    const sum = arr.reduce((s, i) => s + i, 0);
    return arr.map(i => i / sum);
}

export function cum_sum(arr: number[]) {
    const c = [] as number[];
    arr.forEach((value, index) => {
        if (index === 0)
            c.push(value)
        else
            c.push(c[index - 1] + value);
    })
    return c;
}
