import React from "react";
import Container from "@material-ui/core/Container";
import {Header} from "./Header";
import {Footer} from "./Footer";

type Props = {
    dropElement?,
}

export const MainComponent: React.FC<Props> = ({dropElement, children}) => {
    return (
        <div ref={dropElement} className="main-component min-vh-100">
            <Container>
                <Header/>
                {children}
                <Footer/>
            </Container>
        </div>
    )
};
