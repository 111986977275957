import {Typography} from "@material-ui/core";
import React from "react";

export default () => <>
    <Typography color="textSecondary" gutterBottom>
        KOI: Purchase price variance
    </Typography>
    {/*<Typography variant="h5">*/}
    {/*    Buy from less*/}
    {/*</Typography>*/}
    <Typography component="p">
        The Product price variance shows
        the difference between the average and the minimum price of a product.
    </Typography>
</>;
