import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
import './Boxplot.scss';
import {vizColor} from "../../../style/colors";
import Margin from "../../../utils/margin";

export type BoxData = {
    q0: number;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    outliers: number[];
}
export type BoxplotDataPoint = {
    name: string,
    box: BoxData,
};

const defaultMargin: Margin = {
    left: 40,
    right: 10,
    top: 10,
    bottom: 20,
};

function getBoxValues(b: BoxData): number[] {
    return [b.q0, b.q1, b.q2, b.q3, b.q4].concat(b.outliers);
}

export function interpretBoxPlotData(apiDatapoint: any, value: string): BoxplotDataPoint {
    return {
        name: apiDatapoint.description,
        box: {
            q0: apiDatapoint[value][0]['whislo'],
            // q0: apiDatapoint[value][0]['cilo'],
            q1: apiDatapoint[value][0]['q1'],
            q2: apiDatapoint[value][0]['mean'],
            q3: apiDatapoint[value][0]['q3'],
            q4: apiDatapoint[value][0]['whishi'],
            // q4: apiDatapoint[value][0]['cihi'],
            outliers: apiDatapoint[value][0]['fliers']
        },
    }
}

// Taken from: https://www.d3-graph-gallery.com/graph/boxplot_basic.html

type Props = {
    data: BoxplotDataPoint[];
    height: number;
    width?: number;
    onCategoryClick?: (d: BoxplotDataPoint) => void,
}
export const Boxplot: React.FC<Props> = ({data, height, width, onCategoryClick}) => {
    if (width === undefined) {
        width = 255;
    }
    const margin = {
        left: defaultMargin.left,
        right: defaultMargin.right,
        top: defaultMargin.top,
        bottom: defaultMargin.bottom,
    }

    const svgRef = useRef<SVGSVGElement>(null);
    const graphWidth = width - margin.left - margin.right;
    const graphHeight = height - margin.top - margin.bottom;

    // const FONT_SIZE = 12;
    // const COLOR_DATA_PLOT = 'steelblue';
    useEffect(() => {
        // if (!data || !svgRef.current) {
        //     console.log('CumulativeCurveBarChart.render: REJECT', svgRef.current);
        // }
        // console.log('CumulativeCurveBarChart.render: ACCEPT', svgRef.current);

        const svg = d3.select(svgRef.current as SVGElement);
        svg.html(''); // clear

        // // DEBUG: show margins
        // svg.append('rect')
        //     .attr('x', margin.left)
        //     .attr('y', margin.top)
        //     .attr('width', graphWidth)
        //     .attr('height', graphHeight);

        const root = svg
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


        // Process the data
        const allValues = data.reduce<number[]>((arr, d) => arr.concat(getBoxValues(d.box)), []);
        const max = d3.max(allValues) || 1;

        const HIDE_ZEROLINE = false;
        const min = HIDE_ZEROLINE ? 0 : (d3.min(allValues) || 0);

        // set the range and domain for the axis
        let catAxis = d3.scaleBand()
            .domain(data.map(d => d.name))
            .range([0, graphWidth])
        const BAR_PADDING = 0.2;
        const BAR_SPACING = BAR_PADDING / 2 * catAxis.bandwidth();

        const valRange = [graphHeight, 0]
        let valueAxis = d3.scaleLinear()
            .domain([min, max])
            .range(valRange)

        // append the rectangles for the bar chart
        const barGroups = root
            .append('g')
            .classed('data', true)
            .selectAll('g.box-wrapper')
            .data(data)
            .join('g')
            .classed('box-wrapper', true);

        // Make sure the parent-group size to the full field
        barGroups.append('rect')
            .attr('x', d => (catAxis(d.name) as number))
            .attr('y', 0)
            .attr('width', catAxis.bandwidth())
            .attr('height', graphHeight)
            .classed('hover-overlay', true)

        barGroups.on('mouseenter', function (event) {
            const data = d3.select(this).datum()
            console.log('data', data);
            // const [x, y] = d3.pointer(event)
            // var mouse = d3.mouse(this);
            // var x = mouse[0];
            // var y = mouse[1];

            // console.log('x, y', x, y);

        })

        if (onCategoryClick) {
            barGroups
                .classed('clickable', true)
                .on('click', function () {
                    const data = d3.select(this).datum() as BoxplotDataPoint;
                    onCategoryClick(data);
                })
        }

        // Draw the boxplot
        // - Main line
        barGroups.append('line')
            .classed('backbone', true)
            .attr("x1", d => (catAxis(d.name) as number) + catAxis.bandwidth() / 2)
            .attr("x2", d => (catAxis(d.name) as number) + catAxis.bandwidth() / 2)
            .attr("y1", d => valueAxis(d.box.q0) as number)
            .attr("y2", d => valueAxis(d.box.q4) as number)
        // - Box
        barGroups.append('rect')
            .classed('box', true)
            .attr("x", d => (catAxis(d.name) as number) + BAR_SPACING)
            .attr("y", d => valueAxis(d.box.q3) as number)
            .attr("width", catAxis.bandwidth() * (1 - BAR_PADDING))
            .attr("height", d => valueAxis(d.box.q1) - valueAxis(d.box.q3))
            .attr('fill', d => vizColor(d.name))
        // - Horizontal lines
        barGroups.append('line')
            .classed('line line-min', true)
            .attr("x1", d => (catAxis(d.name) as number) + BAR_SPACING)
            .attr("x2", d => (catAxis(d.name) as number) + catAxis.bandwidth() - BAR_SPACING)
            .attr("y1", d => valueAxis(d.box.q0) as number)
            .attr("y2", d => valueAxis(d.box.q0) as number)
        barGroups.append('line')
            .classed('line line-med', true)
            .attr("x1", d => (catAxis(d.name) as number) + BAR_SPACING)
            .attr("x2", d => (catAxis(d.name) as number) + catAxis.bandwidth() - BAR_SPACING)
            .attr("y1", d => valueAxis(d.box.q2) as number)
            .attr("y2", d => valueAxis(d.box.q2) as number)
        barGroups.append('line')
            .classed('line line-max', true)
            .attr("x1", d => (catAxis(d.name) as number) + BAR_SPACING)
            .attr("x2", d => (catAxis(d.name) as number) + catAxis.bandwidth() - BAR_SPACING)
            .attr("y1", d => valueAxis(d.box.q4) as number)
            .attr("y2", d => valueAxis(d.box.q4) as number)

        // Add the horizontal Axis
        root.append("g")
            .classed('category-axis', true)
            .attr("transform", "translate(0," + graphHeight + ")")
            .call(d3.axisBottom(catAxis)
                    // .tickFormat(() => '') // Hide the labels
                    .tickSize(0) // Hide ticks
                // .tickValues([]) // Hide the ticks AND labels
            )
            .call(g => g.select('.domain').remove())
        if (min !== 0) {
            root.append('g')
                .classed('zero-axis', true)
                .append('line')
                .attr("x1", 0)
                .attr("x2", graphWidth)
                .attr("y1", valueAxis(0) as number)
                .attr("y2", valueAxis(0) as number)
        }

        // Add the vertical axis
        // Build sensible graph for values [0-100], [0-1000], [0-10K], [0-100K], etc
        root.append("g")
            .classed('value-axis', true)
            .call(
                d3.axisLeft(valueAxis)
                    .ticks(5)
                    .tickFormat(v => d3.format("~s")(v))
            )

    }, [data])

    return <svg
        className={'boxplot'}
        ref={svgRef}
        viewBox={`0 0 ${width} ${height}`}
        style={{width: '100%', height: 'auto'}}
    />;
};
