import {ApiStore} from "../services/Http";
import {action, makeObservable, observable} from "mobx";
import MithraApi from "../services/MithraApi";
import {History, Location} from "history";
import {generatePath, match as Match} from "react-router";
import {isTokenExpired} from "../services/ApiHelpers";
import {JobHistoryState, JobRouteMatch, routes} from "../routing/routes";
import {JobStore} from "./JobStore";
import LoadingBarStore from "./LoadingBarStore";
import AuthStore from "./AuthStore";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, map, mergeMap, takeUntil, tap} from "rxjs/operators";
import {DropInterfaceT} from "../components/drop-file/dropSpecification";
import {
    canChangeOnServer,
    convertJobInput,
    getPendingJobInputs,
    getStartableDataInputs,
    Job,
    JobInput
} from "../services/classes/JobProcessing";
import {InputStatusEnum, JobStatusEnum} from "../services/classes/StatusEnums";
import {TIMINGS} from "../env";
import {Observable, of, timer} from "rxjs";
import {cum_sum, normalize_arr} from "../utils/js-utils";

const t = TIMINGS['input_loading'];
const _timings = normalize_arr(t);
const timings = cum_sum(_timings);
const UPLOAD_PERCENT = timings[0] * 100;
const PARSE_PERCENT = timings[1] * 100;
const MERGE_PERCENT = timings[2] * 100;
const END_PERCENT = 100;

const estimateUpdateDt = 350;

type LoadSpec = { timeSeconds: number, loadStart: number, loadEnd: number };
const NO_LOAD_SPEC: LoadSpec = {timeSeconds: 0, loadStart: 0, loadEnd: 98};
const PARSE_LOAD_SPEC: LoadSpec = {timeSeconds: t[1] * 1000, loadStart: UPLOAD_PERCENT, loadEnd: PARSE_PERCENT - 1}
// const OPTIMIZE_LOAD_SPEC: LoadSpec = {timeSeconds: t[2] * 1000, loadStart: PARSE_PERCENT, loadEnd: MERGE_PERCENT - 1}
const MERGE_LOAD_SPEC: LoadSpec = {timeSeconds: t[3] * 1000, loadStart: MERGE_PERCENT, loadEnd: END_PERCENT - 1}

function getLoadSpec(status: InputStatusEnum): LoadSpec {
    switch (status) {
        case InputStatusEnum.I_CONFIGURING:
        case InputStatusEnum.I_PARSING:
            return PARSE_LOAD_SPEC;
        default:
            return NO_LOAD_SPEC;
    }
}

export class JobLoadingStore extends ApiStore {

    private _isInSync = true;

    jobLoading = true;

    jobNotFound = false;

    error: any;

    isUploading = false;

    droppedFile?: File;

    constructor(
        api: MithraApi,
        private jobStore: JobStore,
        private loadingBarStore: LoadingBarStore,
        private authStore: AuthStore,
    ) {
        super(api)
        // TODO: verify correct migration of mobx5 -> 6
        makeObservable(this, {
            jobLoading: observable,
            jobNotFound: observable,
            error: observable,
            isUploading: observable,
            droppedFile: observable,
            loadJob: action,
            isInSync: observable
        });
    }

    loadJob(jobId: number): Promise<Job> {
        // console.log('retrieveJob.loading bar start');
        this.loadingBarStore.start(true);
        // TODO: Should we remove the job form jobstore when loading?
        // this.step = -1;
        // this.job = undefined;
        this._isInSync = false;
        return this.api.getJob(jobId)
            .then(job => {
                console.debug('retrieveJob.setComplete');
                this.loadingBarStore.setComplete();
                this.jobStore.setJob(job);
                return job;
            })
            .finally(() => this._isInSync = true)
    }

    getUploadNewJobDropSpec(history: History<JobHistoryState>): DropInterfaceT {
        return {
            onDrop: file => {
                this.isUploading = true;
                this.loadingBarStore.start();
                this.error = '';
                this.droppedFile = file;

                this.api.createJobAndInput({
                    data_bag: {
                        name: 'My Test Job',
                        // merge_setting
                    },
                    author: this.authStore.getUserId(),
                    input_file: file,
                }, (event) => {
                    // Progress
                    const progress = Math.round(UPLOAD_PERCENT * event.loaded / event.total);
                    this.loadingBarStore.setProgress(progress);
                }).then(resp => {
                    // Complete
                    this.loadingBarStore.setProgress(UPLOAD_PERCENT);
                    const job: Job = resp.data.data_bag_obj;
                    const job_input_id = resp.data.id;
                    const job_input: JobInput = convertJobInput(resp.data); // Not needed?

                    console.log('Created job and job input:', job, job_input);
                    const selected_job_input = job.inputs.findIndex(x => x.id === job_input_id);

                    const state: JobHistoryState = {job, selected_job_input};
                    history.push(generatePath(routes.job, {id: job.id}), state);
                    // Do not execute anything here anymore!
                }).catch(reason => {
                    console.error(reason);
                    this.isUploading = false;
                    this.loadingBarStore.setError();
                    this.error = 'Could not upload the file';
                });

                // this.api.createJob({
                //     name: 'My Test Job',
                // })
                //     .then(resp => {
                //         this.loadingBarStore.setProgress(10);
                //         const created_job = resp.data;
                //         this.api.createJobInput({
                //             job: created_job.id,
                //             author: this.authStore.getUserId(),
                //             input_file: file,
                //         }, (event) => {
                //             // Progress
                //             const progress = Math.round(10 + 90 * event.loaded / event.total);
                //             this.loadingBarStore.setProgress(progress);
                //         }).then(resp => {
                //             // Complete
                //             this.loadingBarStore.setProgress(90);
                //             const job: Job = resp.data.job_obj; // Should contain the current job_input
                //             const job_input_id = resp.data.id;
                //             const job_input: JobInput = convertJobInput(resp.data); // Not needed?
                //
                //             console.log('Created job and job input:', job, job_input);
                //             const selected_job_input = job.inputs.findIndex(x => x.id === job_input_id);
                //
                //             const state: JobHistoryState = {job, selected_job_input};
                //             history.push(generatePath(routes.job, {job_input_id}), state);
                //             // Do not execute anything here anymore!
                //         }).catch(reason => {
                //             console.error(reason);
                //             this.isUploading = false;
                //             this.loadingBarStore.setError();
                //             this.error = 'Could not upload the file';
                //         });
                //     })
                //     .catch(reason => {
                //         console.error(reason);
                //         this.isUploading = false;
                //         this.loadingBarStore.setError();
                //         this.error = 'Could not create job';
                //     });
            },
            onError: err => {
                this.error = '' + err;
            },
        }
    }

    isInSync() {
        return this._isInSync;
    }

    initJob(
        location: Location<JobHistoryState>,
        history: History<unknown>,
        match: Match<JobRouteMatch>
    ) {
        // TODO: performance gain can be achieved by limiting this call (i.e. deps = [])
        if (!this.isInSync()) {
            // Job is already being retrieved
            return;
        }
        if (this.jobStore.job || this.jobNotFound) {
            // Job is already present
            return;
        }
        if (location.state?.job) {
            // TODO: this is more complicated then it looks,
            // - How to check how much time has passed?
            // - Tokens might need to be refreshed
            // Attempt to interpret job from Redirect
            const job = location.state.job;
            const dNow = new Date()
            const dJob = new Date(job.current_status.timestamp);
            const dDiff = dNow.getTime() - dJob.getTime();
            const isRecent = dDiff < 1000;
            // Check if this is a intermediate state
            if (!isRecent && canChangeOnServer(job)) {
                console.debug(`Reloading job ${job.id} (${dDiff / 1000}s old) from location.state`, job);
                // Retrieve from server anyways
                this.loadJob(job.id)
                    .catch(err => {
                        if (isTokenExpired(err)) {
                            this.authStore.expire();
                            history.push(routes.login);
                        } else {
                            this.jobNotFound = true;
                            // Remove state, as it is invalid
                            console.warn('UNTESTED: What to do when a job is failed to reload from STATE HISTORY?')
                            history.replace(location.pathname, undefined);
                        }
                    });
            } else {
                console.debug(`Loading job ${job.id} (${dDiff / 1000}s old) from location.state`, job);
                this.jobStore.setJob(job);
            }
        } else {
            // Attempt to interpret job from URL
            console.debug(`Load job ${match.params.id} from URL`);
            const jobId = Number(match.params.id);
            if (isNaN(jobId)) {
                this.jobNotFound = true;
            }
            this.loadJob(jobId)
                .catch(err => {
                    if (isTokenExpired(err)) {
                        this.authStore.expire();
                        history.push(routes.login);
                    } else {
                        // TODO what to do when job cannot be found on the server anymore?
                        this.jobNotFound = true;
                    }
                });
        }
    }

    /**
     * Gets called when we have received the first response from the backend
     */
    onLoadJob(): Observable<number | false> | false {
        const bag = this.jobStore.getJob();
        console.log('onLoadJob:', bag.current_status.name, {...bag});
        switch (bag.current_status.status) {
            case JobStatusEnum.B_CONFIGURING:
                // Initial state
                // See if we should proceed
                const pendingInputs = getPendingJobInputs(bag);
                if (pendingInputs.length === 0) {
                    console.debug(`${bag.inputs.length} are completed, advancing Job`, MERGE_LOAD_SPEC);
                    return this.showLoadingOnly(MERGE_LOAD_SPEC).pipe(
                        takeUntil(
                            fromPromise(this.api.advanceJob(bag.id)).pipe(
                                tap(r => console.debug(`Job ${bag.id} advanced from`, r.data.old_status, ' -> ', r.data.job.current_status)),
                                tap(r => {
                                    this.jobStore.setJob(r.data.job);
                                    this.loadingBarStore.setComplete();
                                }),
                                catchError(err => {
                                    this.error = err;
                                    console.error(err);
                                    return of(false);
                                })
                            )
                        )
                    )
                } else {
                    // There are inputs that are still pending for user input

                    // Check if some of them can be started already
                    const startableInputs = getStartableDataInputs(bag);
                    if (startableInputs.length > 0) {
                        if (startableInputs.length === 1) {
                            // Special case: There is just one data_input that needs to be started
                            // process input + process bag
                            return this.showLoadingOnly(MERGE_LOAD_SPEC).pipe(
                                takeUntil(
                                    fromPromise(this.api.advanceBagAndInput(bag.id, startableInputs[0].id)).pipe(
                                        tap(r => console.debug(`Bag+1Input.advanced: `
                                            + `Bag{${bag.id}} from ${r.data.data_bag_prev_status.name} -> ${r.data.data_bag.current_status.name} `
                                            + `Input{${r.data.data_input.id}} from ${r.data.data_input_prev_status.name} -> ${r.data.data_input.current_status.name}`
                                        )),
                                        tap(r => {
                                            // DataBag is updated
                                            this.jobStore.setJob(r.data.data_bag);
                                            this.loadingBarStore.setComplete();
                                        }),
                                        catchError(err => {
                                            this.error = err;
                                            console.error(err);
                                            return of(false);
                                        })
                                    )
                                )
                            );
                        }
                    }
                    console.debug(`${pendingInputs.length}/${bag.inputs.length} are still pending...`);
                }
                break;
            case JobStatusEnum.B_PROCESSING:
                break;
            case JobStatusEnum.B_READY:
                break;
            case JobStatusEnum.B_BUSY:
                break;
            case JobStatusEnum.B_ERROR:
                break;
            case JobStatusEnum.B_DISCARDED:
                break;
        }
        return false;
    }

    /**
     * Do all automatic actions
     */
    onLoadJobInput(jobInput: JobInput): undefined | Observable<number> {
        const jobId = this.jobStore.getJobId();
        const status = jobInput.current_status.status;
        const loadSpec = getLoadSpec(status);
        console.log('onLoadJobInput:', jobInput.current_status.name, loadSpec, {...jobInput});

        switch (status) {
            case InputStatusEnum.I_CONFIGURING:
                // console.log('on load jobinput, progress was=', this.loadingBarStore.mainState.progress);
                // this.loadingBarStore.setMainStart()
                return this.showLoadingOnly(loadSpec).pipe(
                    takeUntil(
                        fromPromise(this.api.advanceJobInput(jobInput.id)).pipe(
                            tap(ji_r => console.debug('JobInput advanced from', ji_r.data.old_job_input_status, ' -> ', ji_r.data.job_input.current_status)),

                            // If some job inputs were processed, update the job with the latest status
                            mergeMap(() => fromPromise(this.api.getJob(jobId))),

                            tap(job => {
                                this.jobStore.setJob(job);
                                console.debug(`onLoadJobInput (${jobInput.id}) completed`);
                            }),
                            catchError(err => {
                                this.error = err;
                                return of(false);
                            })
                        )
                    ),
                )
            case InputStatusEnum.I_PARSING:
                return this.showLoadingOnly(loadSpec);
            case InputStatusEnum.I_READY:
            case InputStatusEnum.I_ERROR:
            case InputStatusEnum.I_CANCELLED:
            case InputStatusEnum.I_DISCARDED:
                // No action on load
                return undefined;
        }
    }

    private showLoadingOnly(s: LoadSpec): Observable<number> {
        const dLoad = Math.min(100, Math.max(0, s.loadEnd - s.loadStart));
        return timer(0, estimateUpdateDt).pipe(
            map(i => {
                const time = i * estimateUpdateDt;
                return Math.min(time / s.timeSeconds, 1);
            }),
            tap(p => {
                if (p === 0) {
                    this.loadingBarStore.startAt(s.loadStart);
                } else {
                    this.loadingBarStore.setProgress(s.loadStart + p * dLoad);
                }
            }),
        );
    }
}
