import {HomePage} from "../jobs/home/HomePage";
import {Route} from 'react-router-dom';
import {AuthRoute} from "./AuthRoute";
import React from "react";
import {NotFound} from "./NotFound";
import {MinimalJobComponent} from "../jobs/simple/MinimalJobComponent";
import {MinimalJobWrapper} from "../jobs/wrapper/MinimalJobWrapper";
import {JobDemo} from "../jobs/JobDemo";
import {LoginPage} from "../auth/LoginPage";
import {JobDashBoard} from "../jobs/dashboard/JobDashBoard";
import {SimpleJobWrapper} from "../jobs/wrapper/SimpleJobWrapper";
import {KOISpendConcentration} from "../jobs/koi/spend-concentration/KOISpendConcentration";
import {KOIPurchasePriceVariance} from "../jobs/koi/purchase-price-variance/KOIPurchasePriceVariance";
import {JobStepChooser} from "../jobs/stepper/JobStepChooser";
import {MainComponent} from "../components/main/MainComponent";
import {WrappedRoute} from "./WrappedRoute";
import {KoiKeyDataFacts} from "../jobs/koi/key-data-facts/KoiKeyDataFacts";
import {KoiTaxonomyFacts} from "../jobs/koi/taxonomy-facts/KoiTaxonomyFacts";
import {KoiSpendClassification} from "../jobs/koi/spend-classification/KoiSpendClassification";
import {KoiSupplierHierarchy} from "../jobs/koi/supplier-hierarchy/KoiSupplierHierarchy";
import {Job, JobInputIndex} from "../services/classes/JobProcessing";

export type JobRouteMatch = { id: string };
export type JobHistoryState = undefined | {
    job: Job,
    selected_job_input: JobInputIndex, // Not used yet
};

export const routes = {
    home: '/',
    login: '/login',
    job: '/job/:id',

    job_koi_sc: '/job/:id/spend_concentration',
    job_koi_ppv: '/job/:id/price_variance',
    job_koi_kdf: '/job/:id/key_data_facts',
    job_koi_tf: '/job/:id/taxonomy_facts',
    job_koi_scl: '/job/:id/spend_classification',
    job_koi_sh: '/job/:id/supplier_hierarchy',

    job_ai: '/job/:id/automatic_classification',

    job_simple: '/job/:id/simple',
    job_demo: '/job/:id/demo',
    job_dashboard: '/job/:id/dashboard',
    job_koi_bu: '/job/:id/koi_3',
}

/**
 * Default wrapper
 */
const W = SimpleJobWrapper;
// const W = ProcessJobWrapper;

// Matches the route top to bottom
export const allRoutes = [
    // Landing page
    <AuthRoute key={0} exact path={routes.home} component={HomePage}/>,

    // App logic
    <AuthRoute key={10} strict path={routes.job_dashboard} wrapper={W} component={JobDashBoard}/>,
    <AuthRoute key={11} strict path={routes.job_koi_sc} wrapper={W} component={KOISpendConcentration}/>,
    <AuthRoute key={12} strict path={routes.job_koi_ppv} wrapper={W} component={KOIPurchasePriceVariance}/>,
    <AuthRoute key={13} strict path={routes.job_koi_kdf} wrapper={W} component={KoiKeyDataFacts}/>,
    <AuthRoute key={14} strict path={routes.job_koi_tf} wrapper={W} component={KoiTaxonomyFacts}/>,
    <AuthRoute key={15} strict path={routes.job_koi_scl} wrapper={W} component={KoiSpendClassification}/>,
    <AuthRoute key={16} strict path={routes.job_koi_sh} wrapper={W} component={KoiSupplierHierarchy}/>,

    // Demonstration purposes
    <AuthRoute key={80} strict path={routes.job_demo} wrapper={W} component={JobDemo}/>,
    <AuthRoute key={81} strict path={routes.job_simple} wrapper={MinimalJobWrapper} component={MinimalJobComponent}/>,

    // Default app logic, catch for /job/:id
    <AuthRoute key={90} strict path={routes.job} wrapper={W} component={JobStepChooser}/>,

    // Authentication
    <Route key={403} exact path={routes.login} component={LoginPage}/>,

    // Last catch is a 404
    <WrappedRoute key={404} wrapper={MainComponent} component={NotFound}/>,
]
