import React from "react";
import {Button, List, ListItem, ListItemText} from "@material-ui/core";
import {useStores} from "../../stores";
import moment from "moment";
import 'moment/locale/nl';
import 'moment/locale/fa';

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         loadingListItem: {
//             margin: '0 auto',
//         },
//         acceptButton: {
//             margin: theme.spacing(2),
//         },
//         textRight: {
//             textAlign: 'right',
//         }
//     }),
// );

// @ts-ignore
// const locale = (window.navigator.userLanguage || window.navigator.language) || 'en-GB';
// moment.locale(locale);
moment.locale('en-GB');

export const ProcessDummyStepView: React.FC = () => {
    const {jobStore} = useStores();
    const job = jobStore.getJob();
    // const classes = useStyles();

    const startTime = new Date(job.current_status.timestamp);
    const expectedEndTime = new Date(startTime);
    expectedEndTime.setMinutes(startTime.getMinutes() + 28);

    const completeEstimateText =
        moment(expectedEndTime).isBefore(moment().subtract(1, 'hours')) ?
            'Is completed' : ('' + moment(expectedEndTime).fromNow())

    // expectedEndTime.setHours(startTime.getHours() + 2);
    console.log('startTime', startTime);

    return <>
        <ListItem>
            <ListItemText
                primary={<h2 className={'m-0'}>Enriching data</h2>}
                secondary={<span>Please check back in while</span>}
            />
        </ListItem>

        <List component="div">
            <ListItem>
                <ListItemText primary={'Start time'}
                              secondary={'' + moment(startTime).format('lll')}/>
                <ListItemText primary={'Expected completion time'}
                              secondary={completeEstimateText}/>
            </ListItem>
        </List>

        <p className="text-right">
            <Button
                variant="contained" color="secondary"
                style={{margin: '2em'}}
                // onClick={() => jobStore.setStep(3)}
            >Skip (Demo only)</Button>
        </p>
    </>
};
