import {Redirect, Route} from "react-router-dom";
import React, {ReactNode} from "react";
import {RouteComponentProps, useLocation} from "react-router";
import {useStores} from "../stores";
import {routes} from "./routes";

interface Props {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;

    [routeProps: string]: ReactNode;
}

export const AuthRoute: React.FC<Props> = ({component: Component, ...routeProps}) => {
    const {authStore} = useStores();
    const location = useLocation();
    const loggedIn = authStore.isLoggedIn();
    console.log('AuthenticatedRoute', {requestedUrl: location.pathname, loggedIn});

    const render = props => {
        if (!loggedIn) {
            return <Redirect to={{pathname: routes.login, state: location.pathname}}/>;
        }
        if (routeProps.wrapper) {
            const Wrapper = routeProps.wrapper as any;
            return <Wrapper><Component {...props}/></Wrapper>;
        }
        return <Component {...props}/>
    }

    return <Route {...routeProps} render={render}/>;
}
