import React from "react";

export const SpendConcentrationIcon: React.FC = () => {
    return <svg viewBox="0 0 100.787 100.787" width={50} height={50}>
        <g>
            <g>
                <path d="M96.394,97.787h-8.167v-4.016c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5v4.016h-9v-4.016c0-0.276-0.224-0.5-0.5-0.5
			s-0.5,0.224-0.5,0.5v4.016h-9v-4.016c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5v4.016h-9v-4.016c0-0.276-0.224-0.5-0.5-0.5
			s-0.5,0.224-0.5,0.5v4.016h-9v-4.016c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5v4.016h-9v-4.016c0-0.276-0.224-0.5-0.5-0.5
			s-0.5,0.224-0.5,0.5v4.016h-9v-4.016c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5v4.016h-9v-4.016c0-0.276-0.224-0.5-0.5-0.5
			s-0.5,0.224-0.5,0.5v4.016H5.894V79.621h4.834c0.275,0,0.5-0.225,0.5-0.5s-0.225-0.5-0.5-0.5H5.894v-9h4.834
			c0.275,0,0.5-0.225,0.5-0.5s-0.225-0.5-0.5-0.5H5.894v-9h4.834c0.275,0,0.5-0.225,0.5-0.5s-0.225-0.5-0.5-0.5H5.894v-9h4.834
			c0.275,0,0.5-0.224,0.5-0.5s-0.225-0.5-0.5-0.5H5.894v-9h4.834c0.275,0,0.5-0.224,0.5-0.5s-0.225-0.5-0.5-0.5H5.894v-9h4.834
			c0.275,0,0.5-0.224,0.5-0.5s-0.225-0.5-0.5-0.5H5.894v-9h4.834c0.275,0,0.5-0.224,0.5-0.5s-0.225-0.5-0.5-0.5H5.894v-9h4.834
			c0.275,0,0.5-0.224,0.5-0.5s-0.225-0.5-0.5-0.5H5.894V1.5c0-0.828-0.672-1.5-1.5-1.5s-1.5,0.672-1.5,1.5v97.787
			c0,0.828,0.672,1.5,1.5,1.5h92c0.829,0,1.5-0.672,1.5-1.5S97.223,97.787,96.394,97.787z"/>
                <g>
                    <path
                        d="M75.29,86.143H15.497v-12.25h59.795v12.25H75.29z M17.497,84.143h15.795v-8.25H17.497V84.143z"/>
                </g>
                <g>
                    <path d="M58.997,64.393h-43.5v-12.25h43.5V64.393z M17.497,62.393h32.5v-8.25h-32.5V62.393z"/>
                </g>
                <g>
                    <path d="M73.247,42.893h-57.75v-12.25h57.75V42.893z M17.497,40.893h23.75v-8.25h-23.75V40.893z"/>
                </g>
                <g>
                    <path d="M66.497,20.393h-51V8.143h51V20.393z M17.497,18.393h37v-8.25h-37V18.393z"/>
                </g>
            </g>
        </g>
    </svg>
}
