import React from "react";

export const SpendClassificationIcon: React.FC = () => {
    return <svg viewBox="0 0 64 64" width={50} height={50}>
        <g>
            <path
                d="m19 5c-7.72 0-14 6.28-14 14s6.28 14 14 14 14-6.28 14-14-6.28-14-14-14zm0 26c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12z"/>
            <path
                d="m19 53c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm0 4c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1z"/>
            <path
                d="m19 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm0 4c-.552 0-1-.449-1-1s.448-1 1-1 1 .449 1 1-.448 1-1 1z"/>
            <path
                d="m11 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm0 4c-.552 0-1-.449-1-1s.448-1 1-1 1 .449 1 1-.448 1-1 1z"/>
            <path
                d="m24 19c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3zm4 0c0 .551-.448 1-1 1s-1-.449-1-1 .448-1 1-1 1 .449 1 1z"/>
            <path d="m47 49h12v2h-12z"/>
            <path d="m47 53h8v2h-8z"/>
            <path d="m57 53h2v2h-2z"/>
            <path d="m47 57h12v2h-12z"/>
            <path d="m47 27h12v2h-12z"/>
            <path d="m47 31h12v2h-12z"/>
            <path d="m57 35h2v2h-2z"/>
            <path d="m47 35h8v2h-8z"/>
            <path
                d="m46 19h14c1.654 0 3-1.346 3-3v-12c0-1.654-1.346-3-3-3h-14c-1.654 0-3 1.346-3 3v5h-4.414l-3.002 3.002c-2.736-6.459-9.14-11.002-16.584-11.002-9.925 0-18 8.075-18 18 0 8.109 5.393 14.978 12.779 17.222l.34.57c.561.943.864 2.018.881 3.112l-2.863 14.317c-.091.453-.137.914-.137 1.373v.406c0 3.859 3.141 7 7 7s7-3.141 7-7v-.406c0-.459-.046-.92-.136-1.373l-2.863-14.316c.017-1.095.32-2.169.881-3.111l.34-.571c.513-.156 1.014-.339 1.507-.539l12.733 19.316h4.538v5c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-12c0-1.654-1.346-3-3-3h-14c-1.654 0-3 1.346-3 3v5h-3.462l-11.976-18.171c2.496-1.355 4.631-3.289 6.24-5.613l3.784 3.784h5.414v5c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-12c0-1.654-1.346-3-3-3h-14c-1.654 0-3 1.346-3 3v5h-4.586l-3.537-3.537c1.352-2.525 2.123-5.405 2.123-8.463 0-1.698-.252-3.335-.694-4.893l3.108-3.107h3.586v5c0 1.654 1.346 3 3 3zm-1 29c0-.552.448-1 1-1h14c.552 0 1 .448 1 1v12c0 .552-.448 1-1 1h-14c-.552 0-1-.448-1-1zm0-22c0-.551.448-1 1-1h14c.552 0 1 .449 1 1v12c0 .552-.448 1-1 1h-14c-.552 0-1-.448-1-1zm-21.097 28.612c.065.324.097.654.097.982v.406c0 2.757-2.243 5-5 5s-5-2.243-5-5v-.406c0-.328.032-.658.097-.98l2.723-13.614h4.379zm-2.836-15.612h-4.135c-.093-.763-.287-1.51-.586-2.218.869.129 1.75.218 2.654.218s1.785-.089 2.653-.218c-.299.708-.492 1.455-.586 2.218zm-2.067-4c-8.822 0-16-7.178-16-16s7.178-16 16-16 16 7.178 16 16-7.178 16-16 16zm26-31c0-.551.448-1 1-1h14c.552 0 1 .449 1 1v12c0 .551-.448 1-1 1h-14c-.552 0-1-.449-1-1z"/>
            <path d="m47 5h12v2h-12z"/>
            <path d="m47 9h2v2h-2z"/>
            <path d="m51 9h8v2h-8z"/>
            <path d="m47 13h12v2h-12z"/>
        </g>
    </svg>
}
