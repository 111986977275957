import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {generatePath} from "react-router";
import {routes} from "../../../routing/routes";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import './KoiSupplierHierarchy.scss';
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import {Button, Grid} from "@material-ui/core";
import {forkJoin} from "rxjs";
import {fromPromise} from "rxjs/internal-compatibility";
import {map} from "rxjs/operators";
import {DataColumn, DataTable} from "../../../components/data-table/DataTable";
import {environment} from "../../../env";
import {RESULT_DATA} from "./DemoData";
import {Job} from "../../../services/classes/JobProcessing";

type KeyValues = {
    total_suppliers: number;
    parent_suppliers: number;
    child_suppliers: number;
    stand_alone: number;
}

const RESULT_COLUMNS: DataColumn[] = [
    {
        dataIndex: 'supplier_name',
        headerText: 'Supplier name',
        ltr: true,
    },
    {
        dataIndex: 'parent_supplier',
        headerText: 'Identified parent supplier',
        ltr: true,
    },
];

// const PROCESSING_DURATION_HACK_MINUTES = 7;

export const KoiSupplierHierarchy: React.FC = observer(() => {
    // const theme = useTheme();

    const {jobStore} = useStores();
    const job = jobStore.job as Job;
    if (!job) throw new Error();

    const [activeStep, setActiveStep] = React.useState<number>(0);

    const [keyValues, setKeyValues] = React.useState<KeyValues | undefined>(undefined);
    useEffect(() => {
        const sub = forkJoin([
            fromPromise(jobStore.getJobStats()),
            // fromPromise(jobStore.getJobKoiCumSuppliersL1Summary()),
            // fromPromise(jobStore.getJobKoiCumSuppliers(1)),
        ])
            .pipe(map(([
                           stats,
                           // l1Resp,
                           // topResp
                       ]) => ({
                // total_suppliers: totalSuppliers,
                total_suppliers: stats.data.n_suppliers,
                parent_suppliers: 610, // TODO: Remove hardcoded
                child_suppliers: 2978, // TODO: Remove hardcoded
                stand_alone: 5818, // TODO: Remove hardcoded
            })))
            .subscribe(keyValues => {
                setKeyValues(keyValues);
            });
        return () => sub.unsubscribe();
    }, [jobStore])

    // TODO: This is hardcoded
    useEffect(() => {
        if (activeStep === 1) {
            setTimeout(() => {
                setActiveStep(2);
            }, 6000);
        }
    }, [activeStep]);

    const step0 = <div className="page-item">

        <div style={{minHeight: '10em'}}>
            <Grid container justify="space-evenly">
                <Grid item xs={4}
                      container justify="space-around" style={{alignContent: 'space-around'}} alignItems="center">
                    <Grid className="key-value-container text-center"
                          item xs={11}
                          container spacing={4}>
                        {/* Row 1 */}
                        <Grid item
                              container
                              style={{justifyContent: "space-between"}}>
                            <Grid item>
                                <Typography variant="caption">
                                    Total
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.total_suppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Parent
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `?`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Child
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `?`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Standalone
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `?`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>

        <Grid container justify="space-evenly">
            <Grid item xs={3} className="text-center">
                <Button variant="contained" color="primary" onClick={() => setActiveStep(1)}>
                    Find supplier hierarchy
                </Button>
            </Grid>
        </Grid>
    </div>;

    const step1 = <div className="page-item">
        {/*/!* Header *!/*/}
        {/*<Grid container justify="space-evenly">*/}
        {/*    <Grid item xs={5}>*/}
        {/*        <Typography variant="h6" align="center">Determining the parent and child suppliers ...</Typography>*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={4}>*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}

        <Grid container justify="space-evenly">
            <Grid item xs={4}
                  container
                  justify="space-around" style={{alignContent: 'space-around'}} alignItems="center">
                <Grid item
                      container direction="column"
                      justify="space-around" style={{alignContent: 'space-around'}} alignItems="center"
                      className="text-center">
                    {/*<Grid item>*/}
                    {/*    <p>*/}
                    {/*        Estimated completion time:*/}
                    {/*    </p>*/}
                    {/*</Grid>*/}
                    <Grid item className="font-bigger">
                        <Typography variant="caption">
                            Estimated completion time:
                            {/*<br/>suppliers*/}
                        </Typography>
                        <Typography variant="h5" className="font-weight-bold key-value">
                            {/*{showEstimation(PROCESSING_DURATION_HACK_MINUTES)}*/}
                            in a few seconds
                        </Typography>
                    </Grid>
                    <div style={{width: '30px', height: '30px'}} onClick={() => setActiveStep(2)}/>
                    {/*<Grid item>*/}
                    {/*    <Button*/}
                    {/*        variant="contained" color="secondary"*/}
                    {/*        style={{margin: '3em'}}*/}
                    {/*        onClick={() => setActiveStep(2)}>Skip (Demo only)</Button>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
        </Grid>
    </div>;

    const step2 = <div className="page-item">
        <div style={{minHeight: '8em'}}>
            <Grid container justify="space-evenly">
                <Grid item xs={6}
                      container justify="space-around" style={{alignContent: 'space-around'}} alignItems="center">
                    <Grid className="key-value-container text-center"
                          item xs={11}
                          container spacing={4}>
                        {/* Row 1 */}
                        <Grid item
                              container
                              style={{justifyContent: "space-between"}}>
                            <Grid item>
                                <Typography variant="caption">
                                    Total
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.total_suppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Parent
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.parent_suppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Child
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.child_suppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Standalone
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.stand_alone}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <a className="button" href={environment.demoFiles.supplierHierarchy.url}>
                                    <Button variant="contained" color="secondary" style={{margin: '2em'}}>
                                        Download results
                                    </Button>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>

        <Grid container justify="space-evenly">
            {/*<Grid item xs={8}>*/}
            {/*    <Typography variant="h6">Result</Typography>*/}
            {/*</Grid>*/}
            <Grid item xs={8} style={{minHeight: '10em'}}>
                <DataTable
                    className="supplier-hierarchy-table alt-rows"
                    tableColumns={RESULT_COLUMNS}
                    data={RESULT_DATA.map(([supplier_name, parent_supplier]) =>
                        ({supplier_name, parent_supplier,})
                    )}
                    emptyCaption="Loading"
                    pageSize={12}
                />
            </Grid>
        </Grid>


    </div>;


    return <KoiFacade
        title="Supplier hierarchy"
        intro={<></>}
        back={generatePath(routes.job_dashboard, {id: job.id})}
        className="koi-sh-page">

        <Grid container justify="space-evenly">
            <Grid item xs={8}>
                <Stepper orientation={"horizontal"} activeStep={activeStep}>
                    <Step>
                        <StepLabel>Capture all supplier profiles</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Determine the parent and child suppliers</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Download results</StepLabel>
                    </Step>
                </Stepper>
            </Grid>
        </Grid>

        {activeStep === 0 && step0}
        {activeStep === 1 && step1}
        {activeStep === 2 && step2}

    </KoiFacade>
});
