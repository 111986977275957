import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {Grow, Menu, MenuItem} from "@material-ui/core";
import {AccountCircle} from "@material-ui/icons";
import {useHistory} from "react-router";
import {routes} from "../routing/routes";
import {useStores} from "../stores";

export const UserComponent: React.FC = () => {
    const {authStore} = useStores();
    const history = useHistory();

    const [menuElement, setMenuElement] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuElement);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuElement(null);
    };

    const handleLogout = () => {
        authStore.logout();
        history.push(routes.login);
    };

    const menuId = 'user-menu';
    const renderMenu = (
        <Menu
            anchorEl={menuElement}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
            TransitionComponent={Grow}
        >
            <MenuItem onClick={() => {
                // BUG: This does not work. Uploading the URL will not remove the local job ID!
                history.push(routes.home);
            }}>New file</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    return (
        <div>
            <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit">
                <AccountCircle/>
            </IconButton>
            {renderMenu}
        </div>
    );
}
