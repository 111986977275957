import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import * as d3 from "d3";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {PieView} from "../../../components/visualization/PieView";
import {PreVisualizationView} from "../../config/PreVisualization";
import {common_kpi_Response} from "../../../services/ApiTypes";
import {BarDataPoint} from "../../../components/visualization/BarChart";
import './KoiKeyDataFacts.scss';
import {useTheme} from "@material-ui/core/styles";
import {generatePath} from "react-router";
import {routes} from "../../../routing/routes";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import Typography from "@material-ui/core/Typography";
import {Job} from "../../../services/classes/JobProcessing";

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         acceptButton: {
//             margin: theme.spacing(2),
//         },
//         textRight: {
//             textAlign: 'right',
//         },
//     }),
// );

export const KoiKeyDataFacts: React.FC = observer(() => {
    const theme = useTheme();

    const {jobStore} = useStores();
    const job = jobStore.job as Job;
    if (!job) throw new Error();

    useEffect(() => {
        jobStore.loadKpis();
    }, [])

    const [jobStats, setJobStats] = React.useState<common_kpi_Response | undefined>(undefined);
    useEffect(() => {
        jobStore.getJobStats().then(resp => setJobStats(resp.data));
    }, [])

    const barKpis = ['is_labelled_l1', 'is_labelled_l2', 'is_labelled_l3'];
    const annotateData: (d) => BarDataPoint = d => {
        // d.valueLabel = toCurrency(d.value);
        // d.valueLabel = d3.format("~s")(d.value);
        d.valueLabel = d.value;
        return d;
    }
    const barData = [
        annotateData({
            value: Number(job.meta.find(m => m.key === 'n_parts')?.data),
            category: 'Total',
        })
    ].concat(
        jobStore.meta
            .filter(m => barKpis.includes(m.key))
            .map((m, i) => annotateData({
                value: Number(m.data),
                category: `L${i + 1}`,
            }))
            .filter(d => d.value > 0) //RASA DEMO
            .sort((a, b) => d3.ascending(a.category, b.category))
    );
    const pieData = jobStore.jobKpiTotalSpend.map(tl => ({
        'name': tl.label,
        'value': tl.value,
    }));

    // const clickContinue = () => {
    //     jobStore.setStep(2);
    //     // // Start enrichment
    //     // const oldJob = job;
    //     // loadingBar.start(true);
    //     // // Note: skipped enriching for now
    //     // JobService.startJobCategorization(oldJob.id).then(nextJob => {
    //     //     jobStore.setJob(nextJob);
    //     // }).finally(() => {
    //     //     loadingBar.setComplete();
    //     // })
    // };

    return <KoiFacade
        title="Spend visualized overview"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={generatePath(routes.job_dashboard, {id: job.id})}
        className="koi-kdf-page"
    >
        {/*<div className="page-item">*/}
        {/*    /!* Header *!/*/}
        {/*    <Grid container justify="space-evenly">*/}
        {/*        /!*<Grid item xs={2}>*!/*/}
        {/*        /!*    /!*<Typography variant="subtitle1">Spend per category</Typography>*!/*!/*/}
        {/*        /!*</Grid>*!/*/}
        {/*        <Grid item xs={5}>*/}
        {/*            <Typography variant="h6" align="center">Sum of variance cost per category</Typography>*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={4}/>*/}
        {/*    </Grid>*/}
        {/*</div>*/}

        {/*<Grid container justify="space-evenly">*/}
        {/*    /!*<Grid item xs={2}/>*!/*/}
        {/*</Grid>*/}

        <div className="page-item">
            <Grid container spacing={1} direction="row">
                <Grid item xs={6} sm={2}>
                    {/*Disabled for RASA demo*/}
                    {/*<JobKpis/>*/}
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div style={{height: '250px'}}>
                        <PieView data={pieData} loading={!pieData}/>
                    </div>
                    <p className="text-center mb-0">The spend per category (of L1)</p>
                    {/*{barData ? <>*/}
                    {/*        <BarChart data={barData} height={110 + 22} labelMargin={22} valueLabelPxW={30}/>*/}
                    {/*        <p className="text-center mb-0" style={{marginLeft: '12%'}}>*/}
                    {/*            The number of records per category*/}
                    {/*        </p>*/}
                    {/*    </>*/}
                    {/*    : <p>Loading</p>}*/}
                </Grid>
                <Grid item xs={6} sm={5}>
                    <Grid item
                          container
                          style={{justifyContent: "space-evenly"}}>
                        <Grid item>
                            <Typography variant="caption">
                                Total spend
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {jobStats
                                    ? <CurrencyComponent v={jobStats.total_spend} nDigits={3}/>
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Unclassified
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {jobStats
                                    ? <CurrencyComponent v={jobStats.unclassified_spend} nDigits={3}/>
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                    </Grid>
                    {/*<div style={{height: '250px'}}>*/}
                    {/*    <PieView data={pieData} loading={!pieData}/>*/}
                    {/*</div>*/}
                    {/*<p className="text-center mb-0">The spend per category (of L1)</p>*/}
                </Grid>
            </Grid>
        </div>

        <div className="page-item">
            <PreVisualizationView/>
        </div>
    </KoiFacade>
});
