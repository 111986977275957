import React from "react";
import {CircularProgress, Grid} from "@material-ui/core";

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//     }),
// );

export const LoadingJobComponent: React.FC<{ message: string }> = ({message}) => {
    // TODO: <div className="loading-spinner"><LoadingSpinnerPie/></div>
    return <Grid className="fill-content" container spacing={1} direction="column" justify="center" alignItems="center">
        <CircularProgress/>
        <p>{message}</p>
    </Grid>
};
