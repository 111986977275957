import React, {useEffect, useState} from "react";
import {routes} from "../routing/routes";
import {useHistory, useLocation} from "react-router";
import {Color as AlertColor} from "@material-ui/lab/Alert/Alert";
import {fromPromise} from "rxjs/internal-compatibility";
import axios, {AxiosResponse} from "axios";
import Button from "@material-ui/core/Button";
import {environment} from "../env";
import {FullPageCard} from "../components/full-page-card/FullPageCard";
import {timer} from "rxjs";
import {useStores} from "../stores";
import {switchMap} from "rxjs/operators";
import {MithraLoginResponse} from "../services/ApiTypes";
import {Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import GoogleLogin from "react-google-login";
import {Alert} from "@material-ui/lab";

// Taken from https://dev.to/sivaneshs/add-google-login-to-your-react-apps-in-10-mins-4del
// https://betterprogramming.pub/building-basic-react-authentication-e20a574d5e71

type AlertMsg = undefined | { msg: string, severity: AlertColor };

const LOGOUT_MSG: AlertMsg = {msg: 'Your are now logged out', severity: 'success'};
const EXPIRED_MSG: AlertMsg = {msg: 'Session is expired, login again', severity: 'info'};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alert: {
            marginTop: theme.spacing(2),
        },
    }),
);

export const LoginPage: React.FC = () => {
    const classes = useStyles();
    const {authStore} = useStores();
    const history = useHistory();
    const location = useLocation();

    const [isConnected, setIsConnected] = React.useState<boolean>(true);

    useEffect(() => {
        console.log('LoginPage opened, with redirect to', location.state);
    })

    const autoLogin = !authStore.isLoggedIn() && authStore.hasToken();

    const [alert, setAlert] = useState<AlertMsg>(authStore.isExpired() ? EXPIRED_MSG : authStore.isLogout() ? LOGOUT_MSG : undefined);
    const [isLoggingIn, setIsLoggingIn] = useState(autoLogin);
    useEffect(() => setIsLoggingIn(false), [alert]);

    const onLoggedIn = () => {
        console.assert(authStore.isLoggedIn());
        let redirect = (typeof location.state === 'string' && location.state) ? location.state : routes.home;
        if (redirect === routes.login) {
            redirect = routes.home;
        }
        console.log('onLoggedIn: redirect to', redirect);
        history.push(redirect);
    }

    const onDebugLogin = () => {
        setIsLoggingIn(true);
        timer(1000).subscribe(() => processMithraLogin(authStore.loginWithDebug()));
    };
    const onGoogleSuccess = googleResp => processMithraLogin(authStore.loginWithGoogle(googleResp));

    const processMithraLogin: (mithraPromise: Promise<AxiosResponse<MithraLoginResponse>>) => void = mithraPromise => {
        // setAlert(undefined);
        mithraPromise
            .then(() => onLoggedIn())
            .catch(reason => {
                console.error('Mithra login failed:', reason);
                let alert = '';
                if (reason.response) {
                    if (reason.response.data) {
                        if (reason.response.data.detail) {
                            alert = `${reason.response.data.detail}`;
                        }
                        if (!alert && reason.response.data.non_field_errors) {
                            alert = `${reason.response.data.non_field_errors}`;
                        }
                    }
                    if (!alert) {
                        setAlert({msg: `${reason.response.statusText}`, severity: 'error'});
                    }
                } else {
                    const msg = reason.message || reason || 'Login failed';
                    setAlert({msg: `${msg}, please try again`, severity: 'error'});
                }
            });
    }

    useEffect(() => {
        if (autoLogin) {
            // Trying to login with existing token
            console.log('Trying to login with existing token');
            const refreshSubscription = fromPromise(authStore.refreshToken()
                .then(user => {
                    // Able to login from stored token (JWT did not expire yet)
                    console.log('Refreshed login information', user);
                    onLoggedIn();
                })
                .catch(reason => {
                    // TODO: User flow for rejected authentication not implemented
                    // Token is not valid anymore probably
                    console.warn('Token no longer valid', reason);
                    setAlert({msg: 'Login session expired, please login again', severity: 'warning'});
                })).subscribe();
            return () => refreshSubscription.unsubscribe();
        }
    });


    useEffect(() => {
        if (!environment.production) {
            const sub = timer(0, 10000).pipe(
                switchMap(() => fromPromise(axios({url: `${environment.apiUrl}/ping`})))
            ).subscribe(() => setIsConnected(true), () => setIsConnected(false));
            return () => sub.unsubscribe();
        }
    }, []);

    const text = <Typography component="p">
        You have been given access to the Mithra AI demo.
        Please sign in with the email address you provided.
    </Typography>;

    if (!isConnected) {
        return <FullPageCard
            minHeight="12em"
            content={<>
                {text}
                <Alert className={classes.alert} severity="error">Cannot connect to the Mithra service</Alert>
            </>}/>
    }

    return <FullPageCard
        minHeight="12em"
        content={<>
            {text}
            {alert && <Alert severity={alert.severity}>{alert.msg}</Alert>}
        </>}
        actions={
            isLoggingIn
                ? <Typography component="p">Logging in...</Typography>
                : environment.production
                // https://github.com/anthonyjgrove/react-google-login#login-props
                ? <GoogleLogin
                    clientId={environment.googleClientId}
                    onRequest={() => {
                        setIsLoggingIn(true);
                    }}
                    onSuccess={onGoogleSuccess}
                    onFailure={response => {
                        console.error('GoogleLogin failed', response);
                        setAlert({msg: response.error, severity: 'error'});
                    }}
                    cookiePolicy={'single_host_origin'}
                    responseType="token"
                    prompt="select_account"
                />
                : <Button onClick={onDebugLogin} variant="contained" color="primary">Login</Button>
        }
    />;
};
