// const startTime = new Date(job.current_status.timestamp);
import moment from "moment";

export function showEstimation(overrideMinutes) {
    const startTime = new Date();
    const expectedEndTime = new Date(startTime);
    expectedEndTime.setMinutes(startTime.getMinutes() + overrideMinutes);
    return moment(expectedEndTime).isBefore(moment().subtract(1, 'hours')) ?
        'Is completed' : ('' + moment(expectedEndTime).fromNow());
}
