import React from "react";
import {Button} from "@material-ui/core";

// // Create styles
// const styles = StyleSheet.create({
//     page: {
//         flexDirection: 'row',
//         backgroundColor: '#E4E4E4'
//     },
//     section: {
//         margin: 10,
//         padding: 10,
//         flexGrow: 1
//     }
// });

/**
 * TODO: Add download PDF button
 * - https://medium.com/@shivekkhurana/how-to-create-pdfs-from-react-components-client-side-solution-7f506d9dfa6d
 */
export const DownloadPdfButton: React.FC<{ enabled: boolean }> = (enabled) => {
    // const document = <Document>
    //     <Page size="A4">
    //         <svg viewBox="-10 -10 20 20" width="50">
    //             <circle r="5"></circle>
    //         </svg>
    //     </Page>
    // </Document>;
    // return <div>
    //     {document}
    //     <Button className="download-pdf" variant="contained" color="secondary"
    //             disabled={!enabled}
    //             onClick={() => {
    //                 console.log('Clicked DownloadPdfButton');
    //                 ReactPDF.render(<Document>
    //                     <Page size="A4">
    //                         <View>
    //                             <Text>Section #1</Text>
    //                         </View>
    //                     </Page>
    //                 </Document>, `${__dirname}/example.pdf`).finally(() => {
    //                     console.log('Rendering finished');
    //                 });
    //             }}>
    //         Download as PDF
    //     </Button>
    // </div>
    return <>
        {/*<PDFDownloadLink document={document} fileName="example.pdf">*/}
        {/*    {({blob, url, loading, error}) => (*/}
        {/*        loading ? <Button className="download-pdf" variant="contained" color="secondary" disabled={true}>*/}
        {/*                Downloading ...*/}
        {/*            </Button>*/}
        {/*            : <Button className="download-pdf" variant="contained" color="secondary" disabled={!enabled}>*/}
        {/*                Download as PDF*/}
        {/*            </Button>*/}
        {/*    )}*/}
        {/*</PDFDownloadLink>*/}
        <Button className="download-pdf" variant="contained" color="secondary" disabled={!enabled}>
            Download as PDF<br/>
            <small>(Coming soon)</small>
        </Button>

    </>;
};
