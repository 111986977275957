import {JobStore} from "./JobStore";
import {createContext, useContext} from "react";
import {buildAxiosInstance} from "../services/Http";
import MithraApi from "../services/MithraApi";
import AuthStore from "./AuthStore";
import LoadingBarStore from "./LoadingBarStore";
import DebugStore from "./DebugStore";
import {JobLoadingStore} from "./JobLoadingStore";

const http = buildAxiosInstance();
const api = new MithraApi(http);
const jobStore = new JobStore(api);
const authStore = new AuthStore(api);
const loadingBarStore = new LoadingBarStore();
const jobLoadingStore = new JobLoadingStore(api, jobStore, loadingBarStore, authStore);
const stores = {
    jobStore, authStore, loadingBarStore, jobLoadingStore,
    debugStore: new DebugStore(api),
};
export type Stores = typeof stores;

/**
 * Custom defined function to get all stores of this app
 * @see https://mobx.js.org/defining-data-stores.html
 */
export const useStores: () => Stores = () => useContext(createContext(stores))
