import {ClickAwayListener, Fade, IconButton, Paper, Popper} from "@material-ui/core";
import React, {useEffect} from "react";
import {Help} from "@material-ui/icons";

export const HelpComponent: React.FC<{
    helpContent: React.ReactNode;
    onChange?: (prevState: boolean) => void,
}> = props => {
// React.FC<{ activeStep: number, error?: string }>
// export default (): JSX.Element => {

    const [popupElement, setPopupElement] = React.useState<null | HTMLElement>(null);
    const open = Boolean(popupElement);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(open);
        }
    }, [props, open])

    return <ClickAwayListener onClickAway={() => setPopupElement(null)}>
        <span>
            <IconButton onClick={(event: React.MouseEvent<HTMLElement>) => {
                setPopupElement(popupElement ? null : event.currentTarget);
            }}>
                <Help/>
            </IconButton>
            <Popper open={open} anchorEl={popupElement} placement="bottom" transition>
                {({TransitionProps}) =>
                    <Fade {...TransitionProps}>
                        <Paper variant="outlined" square className="dashboard-card-popout">{props.helpContent}</Paper>
                    </Fade>}
            </Popper>
        </span>
    </ClickAwayListener>;
};
