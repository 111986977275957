import {DEV_USER_ID, environment} from "../env";
import axios, {AxiosResponse} from "axios";
import {decode} from 'jsonwebtoken';
import {GoogleLoginResponse, GoogleLoginResponseOffline} from "react-google-login";
import {ApiStore} from "../services/Http";
import MithraApi from "../services/MithraApi";
import {DjangoUser, MithraLoginResponse} from "../services/ApiTypes";

interface GoogleAuthToken {
    access_token: string;
    id_token: string;
}

interface MithraAuthToken {
    access_token: string;
    refresh_token: string;
}

type User = { name: string, userId: number };

/**
 * TODO: Authentication can be improved
 * - Add refresh tokens
 * - Add support for automatic login
 * - Test by external party
 */
export default class AuthStore extends ApiStore {
    private authenticatedUser: User | undefined = undefined;
    private mithraAuthToken: MithraAuthToken | undefined = undefined;
    private googleAuthToken: GoogleAuthToken | undefined = undefined;
    private _isLogout = false;
    private _isExpired = false;

    constructor(api: MithraApi) {
        super(api);
        if (environment.devLogin) {
            this.authenticatedUser = {name: 'Developer', userId: DEV_USER_ID};
            this.mithraAuthToken = {access_token: 'DEV', refresh_token: 'DEV'};
            this.googleAuthToken = undefined;
        } else {
            // Retrieve session storage
            if (sessionStorage.getItem('user')) {
                this.authenticatedUser = JSON.parse(sessionStorage.getItem('user') as string)
            }
            if (sessionStorage.getItem('access_token') && sessionStorage.getItem('refresh_token')) {
                this.mithraAuthToken = {
                    access_token: sessionStorage.getItem('access_token') as string,
                    refresh_token: sessionStorage.getItem('refresh_token') as string,
                };
            }
            if (sessionStorage.getItem('g_access_token') && sessionStorage.getItem('g_refresh_token')) {
                this.googleAuthToken = {
                    access_token: sessionStorage.getItem('g_access_token') as string,
                    id_token: sessionStorage.getItem('g_id_token') as string,
                };
            }
            this.api.registerInterceptor(config => {
                if (this.mithraAuthToken) {
                    config.headers['Authorization'] = `Bearer ${this.mithraAuthToken.access_token}`;
                }
                return config;
            })
        }
    }

    public refreshToken(): Promise<DjangoUser> {
        if (this.mithraAuthToken?.access_token) {
            const localJwt = decode(this.mithraAuthToken.access_token)
            if (localJwt && localJwt['user_id']) {
                const user = Number(localJwt['user_id']);
                return this.api.getUser(user)
                    .then(resp => {
                        // Tokens are still valid
                        // Update the user data
                        this.setUser({name: resp.data.username, userId: user});
                        // Request a new one maybe?
                        return resp.data;
                    })
                    .catch(reason => {
                        console.warn('Token no longer valid', reason);
                        this.clear();
                        return Promise.reject(reason);
                    });
            } else {
                console.error('JWT token invalid');
            }
        } else {
            console.error('No token found');
        }
        this.clear();
        return Promise.reject();
    }

    public loginWithDebug(): Promise<AxiosResponse<MithraLoginResponse>> {
        this._isLogout = false;
        this._isExpired = false;
        // Login at Mithra with google auth token
        return axios.post<MithraLoginResponse>(`${environment.authUrl}/login/debug/`).then(resp => {
            // Login successful
            this.setToken(resp.data);
            this.setUser({name: resp.data.user.username, userId: resp.data.user.pk});
            return resp;
        });
    }

    public loginWithGoogle(googleResp: GoogleLoginResponse | GoogleLoginResponseOffline): Promise<AxiosResponse<MithraLoginResponse>> {
        this._isLogout = false;
        this._isExpired = false;
        // Verify google response
        if (googleResp.code) {
            console.error('Unexpected google auth response', googleResp);
            return Promise.reject('Unexpected google auth response');
        }

        const googleAuth = this.updateGoogleToken(googleResp as GoogleLoginResponse);
        if (!googleAuth) {
            // TODO: Improve userflow when Google Authentication fails
            return Promise.reject('Google authentication failed');
        }

        // Login at Mithra with google auth token
        return axios.post<MithraLoginResponse>(`${environment.authUrl}/login/google/`, {
            access_token: googleAuth.access_token,
            id_token: googleAuth.id_token,
        }).then(resp => {
            // Login successful
            this.setToken(resp.data);
            const user = {name: resp.data.user.username, userId: resp.data.user.pk};
            console.log('Mithra authentication successful', user);
            this.setUser(user);
            return resp;
        });
    }

    public isLogout() {
        return this._isLogout;
    }

    public logout() {
        this._isLogout = true;
        this.clear();
    }

    public isExpired() {
        return this._isExpired;
    }

    public expire() {
        this._isExpired = true;
        this.clear();
    }

    public hasToken(): boolean {
        return !!this.mithraAuthToken;
    }

    public isLoggedIn(): boolean {
        return Boolean(this.authenticatedUser);
    }

    private clear() {
        sessionStorage.clear();
        this.authenticatedUser = undefined;
        this.mithraAuthToken = undefined;
        this.googleAuthToken = undefined;
        console.log('Tokens cleared');
    }

    private setToken(serverResp: MithraLoginResponse) {
        this.mithraAuthToken = {
            access_token: serverResp.access_token,
            refresh_token: serverResp.refresh_token,
        };
        sessionStorage.setItem('access_token', this.mithraAuthToken.access_token);
        sessionStorage.setItem('refresh_token', this.mithraAuthToken.refresh_token);
    }

    private clearGoogleToken() {
        this.googleAuthToken = undefined;
        sessionStorage.removeItem('g_access_token');
        sessionStorage.removeItem('g_id_token');
    }

    private setGoogleToken(access_token: string, id_token: string): GoogleAuthToken {
        this.googleAuthToken = {
            access_token,
            id_token,
        };
        sessionStorage.setItem('g_access_token', this.googleAuthToken.access_token);
        sessionStorage.setItem('g_id_token', this.googleAuthToken.id_token);
        return this.googleAuthToken;
    }

    private updateGoogleToken(googleLogin: GoogleLoginResponse): GoogleAuthToken | undefined {
        const googleAuth = googleLogin.getAuthResponse(true);
        const access_token = googleAuth.access_token;
        const id_token = googleAuth.id_token;
        const prevId = this.googleAuthToken?.id_token;
        if (access_token && id_token) {
            // Update the local access_token
            console.info(`Authentication via Google successful (access_token and id_token found)`);
            return this.setGoogleToken(access_token, id_token);
        }
        // access_token is missing
        if (id_token && this.googleAuthToken) {
            // If same ID is used, use access_token from previous authentication
            if (id_token === prevId) {
                console.info(`Existing access token used for user ${id_token}`);
                return this.googleAuthToken;
            }
            // ID is changed, throw the old one away
            console.error(`Google access token missing for ID ${id_token}, could not get access token from ID ${prevId} because ID's mismatch`, googleAuth)
            this.clearGoogleToken();
            return undefined;
        }
        // access_token is missing and no old access_token can be used
        console.error('Could not authenticate via google, access_token is missing', googleAuth);
        return undefined;
    }

    private setUser(user: User) {
        this.authenticatedUser = user;
        sessionStorage.setItem('user', JSON.stringify(this.authenticatedUser));
    }

    getUserId(): number {
        if (typeof this.authenticatedUser?.userId !== 'number') {
            throw new Error('Could not find user_id');
        }
        return this.authenticatedUser?.userId;
    }
}
