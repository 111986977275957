import React from "react";
import {CardActions, CardContent, Grid, Paper} from "@material-ui/core";
import {SmallHeader} from "../main/SmallHeader";
import {Card} from "react-bootstrap";
import {Footer} from "../main/Footer";
import Container from "@material-ui/core/Container";

type Props = {
    content: JSX.Element,
    actions?: JSX.Element,
    minHeight?: string,
};

export const FullPageCard: React.FC<Props> = ({content, actions, minHeight, children}) => <>
    <Grid className="vh-100"
          container justify="center" direction="column">
        <Grid item>
            <Container>
                <Grid container justify="center">
                    <Grid item xs={9} sm={6} md={4}>
                        <SmallHeader/>
                        <Paper className="" variant="outlined" square>
                            <Card style={{minHeight: minHeight}}>
                                <CardContent>{content}</CardContent>
                                {actions &&
                                <CardActions
                                    className='text-center justify-content-center mb-3'>{actions}</CardActions>}
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    </Grid>
    <div style={{position: 'absolute', bottom: 0, left: 0, right: 0}}>
        <Footer/>
    </div>
</>;
