import React from "react";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from "@material-ui/core";
import {CurrencyComponent} from "../currency-component/CurrencyComponent";
import './DataTable.scss';
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {TablePaginationActionsProps} from "@material-ui/core/TablePagination/TablePaginationActions";
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';

const useStyles1 = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
    }),
);

const TablePaginationActions: React.FC<TablePaginationActionsProps> = ({count, page, rowsPerPage, onChangePage}) => {
    const classes = useStyles1();
    const theme = useTheme();

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPage/>
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                <KeyboardArrowLeft/>
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight/>
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPage/>
            </IconButton>
        </div>
    );
}

type DataCategory = 'currency';
export type DataColumn = {
    dataIndex: string;
    headerText: string;
    help?: string;
    dataCategory?: DataCategory;
    ltr?: boolean;
}

type Props = {
    tableColumns: DataColumn[];
    data?: any[];
    emptyCaption?: string;
    className?: string;
    pageSize?: number;
};
export const DataTable: React.FC<Props> = ({pageSize, tableColumns, data, emptyCaption, className,}) => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize || 12);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, (data?.length || 0) - page * rowsPerPage);
    console.log('emptyRows', emptyRows);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return <TableContainer component={Paper} className={'data-table' + (className ? (' ' + className) : '')}>
        <Table>
            <TableHead>
                <TableRow>
                    {tableColumns.map((col, index) => {
                        const align = col.ltr ? 'left' : 'right';
                        return <TableCell key={col.dataIndex} className={'col-' + col.dataIndex} align={align}>
                            {col.headerText}
                        </TableCell>
                    })}
                </TableRow>
            </TableHead>
            {data === undefined
                ? <caption style={{height: 53 * emptyRows}}>{emptyCaption || ''}</caption>
                : <>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : data
                        ).map((d, di) =>
                            <TableRow key={di}>
                                {tableColumns.map((col, index) => {
                                    const val = col.dataCategory === 'currency'
                                        ? <CurrencyComponent v={+d[col.dataIndex]}/>
                                        : '' + d[col.dataIndex]
                                    // const key = d.description + '_' + col.dataIndex;
                                    const key = di + '_' + col.dataIndex;
                                    const className = 'col-' + col.dataIndex;
                                    // const key = '';
                                    const component = index === 0 ? 'th' : undefined;
                                    const scope = index === 0 ? 'row' : undefined;
                                    const align = col.ltr ? 'left' : 'right';
                                    return <TableCell className={className} key={key} component={component}
                                                      scope={scope} align={align}>
                                        {val}
                                    </TableCell>
                                })}
                            </TableRow>
                        )}
                        {emptyRows > 0 && (
                            <TableRow style={{height: 53 * emptyRows}}>
                                <TableCell colSpan={tableColumns.length}/>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    5, 12, 25,
                                    // {label: 'All', value: -1},
                                ]}
                                colSpan={tableColumns.length}
                                count={data?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </>
            }
        </Table>
    </TableContainer>
};
