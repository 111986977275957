import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {App} from './App';
import {configure} from "mobx";

configure({enforceActions: "observed",}); // TODO: verify correct migration of mobx5 -> 6

let useTest: boolean = false;
/**
 * Uncomment the following line to run the tests from ./test/tests.tsx
 */
// useTest = true;

// Taken from https://prawira.medium.com/react-conditional-import-conditional-css-import-110cc58e0da6
const Tests = React.lazy(() => import('./test/tests'));
ReactDOM.render(
    <React.StrictMode>
        <CssBaseline/>
        {useTest
            ? <React.Suspense fallback={<></>}><Tests/></React.Suspense>
            : <App/>}
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
