import React, {useEffect} from "react";
import {useStores} from "../../../stores";
import {Button, Grid, Typography} from "@material-ui/core";
import {generatePath} from "react-router";
import {routes} from "../../../routing/routes";
import './Style.scss';
import {useTheme} from "@material-ui/core/styles";
import {BarChart, BarDataPoint} from "../../../components/visualization/BarChart";
import {forkJoin} from "rxjs";
import {fromPromise} from "rxjs/internal-compatibility";
import {map} from "rxjs/operators";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {CurrencyComponent, toCurrency} from "../../../components/currency-component/CurrencyComponent";
import {DataColumn, DataTable} from "../../../components/data-table/DataTable";
import {fakeItemNumberData, fakeSuppliersData} from "./fakeSuppliersData";
import './KoiPurchasePriceVariance.scss';
import PurchasePriceVarianceHelp from "./PurchasePriceVarianceHelp";
import {Job} from "../../../services/classes/JobProcessing";

const tableColumns: DataColumn[] = [
    // TODO-20211105: Why is this no longer in the API, is it a design decision or feature request to have:
    //  s__id in `/api/job/koi/:job/data/purchase_price_variance_data/`
    // {
    //     dataIndex: 's__id',
    //     headerText: 'Supplier name',
    //     ltr: true,
    // },

    // Should this not be removed? Because by definition there should be as many fields in this column, as the group size.
    // That ranges from 3 to 830891 for the first page already
    {
        dataIndex: 'item_id',
        headerText: 'Item number',
        ltr: true,
    },

    {
        dataIndex: 'p__description',
        headerText: 'Description',
        ltr: true,
    },
    {
        dataIndex: 'pv_opportunity',
        headerText: 'Price variance cost',
        dataCategory: 'currency',
        help: 'Total opportunity if all transactions were at the minimal unit price',
    },
    {
        dataIndex: 'p__spend__sum',
        headerText: 'Total spend',
        dataCategory: 'currency',
        help: 'Sum of all spend',
    },

    {
        dataIndex: 'p__unit_cost__min',
        headerText: 'Minimal cost',
        dataCategory: 'currency',
        help: 'Minimal unit cost'
    },
    {
        dataIndex: 'p__unit_cost__avg',
        headerText: 'Average unit cost',
        dataCategory: 'currency',
        help: 'The average unit cost in all transactions'
    },

    // {
    //     dataIndex: 'p__unit_cost__max',
    //     headerText: 'Max cost',
    //     dataCategory: 'currency',
    //     help: 'Maximal price for unit cost'
    // },

    {
        dataIndex: 'group_size',
        headerText: 'Trans-actions',
        help: 'Total number of transactions',
    },

    {
        dataIndex: 'p__quantity__sum',
        headerText: 'Total units',
        help: 'Sum of all the quantities',
    },

    // Remove for now
    // {
    //     dataIndex: 'pv_delta',
    //     headerText: 'PV_DELTA?',
    //     dataCategory: 'currency',
    // },
    // {
    //     dataIndex: 'pv',
    //     headerText: 'Price variance',
    //     dataCategory: 'currency',
    // },
    // {dataIndex: 'unit_cost__opportunity', headerText: ''}, // Same, but different calculation method
]

type KeyValues = {
    uncategorizedSpend: number,
    topXSpend: number,
    purchasePriceVariance: number,
    totalSpend: number,
    totalSuppliers: number,
};

function postProcessPPVTable(data: any[]) {
    data = data.filter((value, index) => index > 1) // Skip the first entry
    data.forEach((v, i) => {
        v['supplier_id'] = fakeSuppliersData[i % fakeSuppliersData.length];
        v['item_id'] = fakeItemNumberData[i % fakeItemNumberData.length];
        v['quantity__sum'] = Math.round(v['quantity__sum'] * 10000) / 10000;
    })

    return data;
}

export const KOIPurchasePriceVariance: React.FC = () => {
    const theme = useTheme();

    const {jobStore} = useStores();
    const job = jobStore.job as Job;
    if (!job) throw new Error();

    const [keyValues, setKeyValues] = React.useState<KeyValues | undefined>(undefined);
    const [ppvCategoryData, setPpvCategoryData] = React.useState<BarDataPoint[] | undefined>(undefined);

    // const [selectedCategoryL1, setSelectedCategoryL1] = React.useState<BarDataPoint | undefined>(undefined);

    useEffect(() => {
        const sub = forkJoin([
            fromPromise(jobStore.getJobStats()),
            fromPromise(jobStore.getPriceVarianceGroupedGroups()),
            fromPromise(jobStore.getJobKoiCumSuppliers(1)),
            fromPromise(jobStore.getTotalPriceVarianceGroups()),
        ])
            .pipe(map(([stats, ppvResp, topResp, pvvResp]) => {
                return [
                    {
                        uncategorizedSpend: stats.data.unclassified_spend,
                        topXSpend: topResp.data.stats.top_total_var__perc,
                        purchasePriceVariance: pvvResp,
                        totalSpend: stats.data.total_spend,
                        totalSuppliers: stats.data.n_suppliers,
                    },
                    ppvResp.data.ppv.map(d => ({
                        category: d.p__in_l1,
                        categoryLabel: d.p__in_l1 || '<uncategorized>',
                        value: d.pv_opportunity__sum,
                        valueLabel: toCurrency(d.pv_opportunity__sum),
                    }))
                ] as [KeyValues, BarDataPoint[]];
            }))
            .subscribe(([keyValues, ppvCategoryData]) => {
                setKeyValues(keyValues);
                setPpvCategoryData(ppvCategoryData);
            });
        return () => sub.unsubscribe();
    }, [jobStore]);

    const [pvvTable, setPpvTable] = React.useState<any[] | undefined>(undefined);
    useEffect(() => {
        const sub = fromPromise(jobStore.ppvDataAll())
            .pipe(map(resp => postProcessPPVTable(resp.data)))
            .subscribe(ppvTable => {
                setPpvTable(ppvTable)
            })
        return () => sub.unsubscribe();
    }, [jobStore])

    const [downloading, setDownloading] = React.useState<boolean>(false);
    const startKOI2Download = () => {
        setDownloading(true);
        jobStore.downloadPPV()
            .then(resp => {
                const url = URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ProductPriceVariance.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            })
            .finally(() => {
                setDownloading(false);
            })
    }

    return <KoiFacade
        title="Purchase price variance"
        helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={generatePath(routes.job_dashboard, {id: job.id})}
        className="koi-ppv-page"
    >
        <div className="page-item">
            {/* Header */}
            <Grid container justify="space-evenly">
                {/*<Grid item xs={2}>*/}
                {/*    /!*<Typography variant="subtitle1">Spend per category</Typography>*!/*/}
                {/*</Grid>*/}
                <Grid item xs={5}>
                    <Typography variant="h6" align="center">Sum of variance cost per category</Typography>
                </Grid>
                <Grid item xs={4}/>
            </Grid>

            <Grid container justify="space-evenly">
                {/*<Grid item xs={2}/>*/}
                <Grid item xs={5}>
                    <div style={{height: '352px'}}>
                        {!ppvCategoryData
                            ? <p>Loading</p>
                            : <BarChart
                                horizontal
                                width={500} height={350}
                                data={ppvCategoryData}
                                labelMargin={200}
                                categoryAxisTitle="L1 categories"
                                valueAxisTitle="Sum of variance cost"
                                // onClicked={setSelectedCategoryL1}
                            />
                        }
                    </div>

                </Grid>
                <Grid item xs={4}
                      container justify="space-around" style={{alignContent: 'space-around'}} alignItems="center">

                    <Grid className="key-value-container text-center"
                          item xs={11}
                          container spacing={4}>
                        {/* Row 1 */}
                        <Grid item
                              container
                              style={{justifyContent: "space-between"}}>
                            <Grid item>
                                <Typography variant="caption">
                                    Total<br/>
                                    Suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.totalSuppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Spend<br/>
                                    concentration
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? (Math.round(keyValues.topXSpend * 100) + '%')
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Purchase Price<br/>
                                    Variance
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? <CurrencyComponent v={keyValues.purchasePriceVariance} nDigits={3}/>
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* Row 2 */}
                        <Grid item
                              container
                              style={{justifyContent: "space-evenly"}}>
                            <Grid item>
                                <Typography variant="caption">
                                    Total spend
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? <CurrencyComponent v={keyValues.totalSpend} nDigits={3}/>
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Unclassified
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {(keyValues)
                                        ? <CurrencyComponent v={keyValues.uncategorizedSpend} nDigits={3}/>
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={9}>
                        <Typography variant="body1" style={{width: '17em', margin: '0 auto'}}>
                            Price variance cost reflects potential savings across the portfolio
                            to be explored and ultimately realized.
                        </Typography>
                    </Grid>

                </Grid>
                {/*<Grid item xs={2}/>*/}
            </Grid>
        </div>

        {/*<Grid container justify="space-evenly" className="page-item">*/}
        {/*    <Grid item xs={12}>*/}
        {/*        <p>clickedSupplier2 = {JSON.stringify(selectedCategoryL1)}</p>*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}

        <div className="page-item">
            <Grid container justify="flex-end">
                <Grid item xs={2} style={{textAlign: 'center', marginBottom: theme.spacing(2)}}>
                    <Button
                        style={{width: '13em'}}
                        variant="outlined"
                        color="secondary"
                        onClick={() => startKOI2Download()}
                        disabled={downloading}>
                        {downloading ? 'Downloading...' : 'Download all'}
                    </Button>
                </Grid>
                {/*<Grid item xs={2}/>*/}
            </Grid>
            <Grid container justify="space-evenly">
                {/*<Grid item xs={2}>*/}
                {/*{selectedSupplier === undefined*/}
                {/*    ? <></>*/}
                {/*    : <Typography variant="subtitle1">*/}
                {/*        Transactions related to supplier {selectedSupplier.name}*/}
                {/*    </Typography>}*/}
                {/*</Grid>*/}
                <Grid item xs={12} style={{minHeight: '10em'}}>
                    <DataTable
                        className="parts-table alt-rows"
                        tableColumns={tableColumns}
                        data={pvvTable}
                        emptyCaption="Loading"
                        pageSize={12}
                    />
                </Grid>
                {/*<Grid item xs={2}/>*/}
            </Grid>
        </div>
    </KoiFacade>
};
