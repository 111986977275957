import React from "react";
import {useStores} from "../../stores";
import {Grid, ListItem, ListItemText} from "@material-ui/core";
import {Job, JobMeta} from "../../services/classes/JobProcessing";

function showKpiLabel(kpiKey: string): string {
    switch (kpiKey) {
        case "n_suppliers":
            return 'Suppliers';
        case "n_unparseable":
            return 'Corrupt lines';
        case "n_parts":
            return 'Records';
        default:
            console.error(`Could not translate kpiKey ${kpiKey} to human readable`);
            return ''
    }
}

export const JobKpis: React.FC = () => {
    const {jobStore} = useStores();
    const job = jobStore.job as Job;
    if (!job) throw new Error();

    const key_value_to_show = ['n_parts', 'n_suppliers', 'n_unparseable']
        .map(key => job.meta.find(m => m.key === key))
        .filter(m => m) as JobMeta[];

    return <>
        <Grid container spacing={0} direction="column">
            {key_value_to_show.map(m =>
                <Grid item xs key={m.key}>{/*={m.key.length > 12 ? 2 : 1}*/}
                    <ListItem>
                        <ListItemText primary={showKpiLabel(m.key)} secondary={m.data}/>
                        {/*<ListItemText secondary={showKpiLabel(m.key)} primary={m.data}/>*/}
                    </ListItem>
                </Grid>
            )}
        </Grid>
    </>
};
