import React from "react";
import {Grid, Input, Slider, Tab, Tabs, Typography} from "@material-ui/core";
import {useStores} from "../../stores";
import {SankeyTreeView} from "../../components/visualization/SankeyTreeView";
import {DownloadPdfButton} from "../../components/tools/DownloadPdf";
import {environment} from "../../env";
import {kpiToReadableString} from "../../services/ApiHelpers";
import {Job} from "../../services/classes/JobProcessing";
import {KpiBuilder_tree_kpis} from "../../services/ApiTypes";

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         acceptButton: {
//             margin: theme.spacing(2),
//         },
//         textRight: {
//             textAlign: 'right',
//         }
//     }),
// );

const AVAILABLE_KPIS: KpiBuilder_tree_kpis[] = ['count_tree', 'spend_tree']

export const PreVisualizationView: React.FC = () => {
    // const [loadingData, setLoadingData] = useState(false);
    // const [rendered, setRendered] = useState(false);
    // const svgRef = useRef<SVGSVGElement>(null)

    const {jobStore} = useStores();
    const job = jobStore.job as Job;
    if (!job) throw new Error();
    // const classes = useStyles();

    // const [taxKpis, setTaxKpis] = React.useState<GraphResponse>();
    // const [error, setError] = React.useState('');

    const [kpis, setKpis] = React.useState(AVAILABLE_KPIS);
    const [selectedKpi, setSelectedKpi] = React.useState(AVAILABLE_KPIS[0]);
    const [minFilter, setMinFilter] = React.useState(0);

    if (kpis.length === 0) {
        return <></>
    }

    return <>
        <div className={'pre-viz'}>
            <Tabs
                centered={true}
                value={selectedKpi}
                onChange={(e, kpi) => setSelectedKpi(kpi)}>
                {kpis.map((kpi, i) => <Tab key={i} label={kpiToReadableString(kpi)} value={kpi}/>)}
            </Tabs>

            <div className={'pre-viz-settings'}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography id="filter-slider-label" gutterBottom>
                            Filter
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Slider
                            aria-labelledby="filter-slider-label"
                            valueLabelFormat={value => value === 0 ? 'Off' : `${value}`}
                            valueLabelDisplay="auto"
                            step={10000 / 10}
                            marks
                            min={0}
                            max={10000}
                            value={minFilter}
                            onChange={(_, v) => setMinFilter(v as any)}/>
                    </Grid>
                    <Grid item>
                        {/*onBlur={handleBlur}*/}
                        <Input
                            value={minFilter}
                            margin="dense"
                            onChange={event => setMinFilter(event.target.value === '' ? 0 : Number(event.target.value))}
                            inputProps={{
                                step: 10,
                                min: 0,
                                // max: 100,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>

        {selectedKpi ? <SankeyTreeView kpi={selectedKpi} minFilter={minFilter}/> : <></>}

        {environment.production
            ? <></>
            : <DownloadPdfButton enabled={true}/>
        }
    </>;
};
