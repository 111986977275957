import React from "react";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router";
import {Button, Card, CardActions, CardContent, CardHeader, Grid} from "@material-ui/core";
import './DashBoardCard.scss';
import {noop} from "rxjs";
import {HelpComponent} from "../help-component/HelpComponent";
import {ArrowForward} from "@material-ui/icons";

type Props = {
    avatar?: React.ReactNode;
    title: string;
    body: React.ReactNode;
    link?: string | false;
    helpContent?: React.ReactNode;
    className?: string;
    buttonText?: string;
}

export const DashBoardCard: React.FC<Props> = observer(({
                                                            link,
                                                            buttonText = 'Dig in',
                                                            avatar,
                                                            title,
                                                            body,
                                                            helpContent,
                                                            className
                                                        }) => {
    const history = useHistory();
    const [helpOpened, setHelpOpened] = React.useState(false);

    return <Grid item xs={12} sm={6} md={5}
                 lg={5}>{/*lg is in a new container*/}
        <Card className={"dashboard-card" + (helpOpened ? ' help-opened' : '') + (className ? ` ${className}` : '')}
              raised>
            <CardHeader
                avatar={avatar}
                action={!helpContent ? undefined : <HelpComponent onChange={setHelpOpened} helpContent={helpContent}/>}
                title={title}
                titleTypographyProps={{variant: "h6"}}
            />
            <CardContent>
                {body}
            </CardContent>
            {link !== false &&
            <CardActions className='justify-content-end'>
                <Button
                    color="primary"
                    variant="contained"
                    // startIcon={<ArrowBack/>}
                    endIcon={<ArrowForward/>}
                    onClick={() => link ? history.push(link) : noop()}>
                    {buttonText}
                </Button>
            </CardActions>
            }
        </Card>
    </Grid>;
});
