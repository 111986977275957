// const Main: React.FC<WithStyles<typeof styles>> = ({classes}) => {
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useHistory, useLocation} from "react-router";
import {JobHistoryState, JobRouteMatch} from "../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {NotFound} from "../../routing/NotFound";

export const MinimalJobWrapper: React.FC = observer(({children}) => {
    const [location, match, history] = [useLocation<JobHistoryState>(), useRouteMatch<JobRouteMatch>(), useHistory()];
    const {jobLoadingStore, jobStore} = useStores();

    // The magic that loads the job
    useEffect(() => jobLoadingStore.initJob(location, history, match), [])

    if (jobLoadingStore.jobNotFound) {
        return <NotFound msg="Could not find the file on the server anymore"/>
    }
    // Block all children from being rendered until the job is retrieved and set correctly
    if (!jobStore.job) return <>
        <h1>Loading</h1>
        <p>Please wait</p>
    </>
    return <>{children}</>
});
