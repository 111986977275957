// const Main: React.FC<WithStyles<typeof styles>> = ({classes}) => {
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useHistory, useLocation} from "react-router";
import {JobHistoryState, JobRouteMatch} from "../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {NotFound} from "../../routing/NotFound";
import {LoadingBarComponent} from "../../components/loading-bar/LoadingBarComponent";
import {Paper} from "@material-ui/core";
import {MainComponent} from "../../components/main/MainComponent";
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";

export const SimpleJobWrapper: React.FC = observer(({children}) => {
    const location = useLocation<JobHistoryState>();
    const match = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    const {jobStore, jobLoadingStore} = useStores();

    // The magic that loads the job
    useEffect(() => jobLoadingStore.initJob(location, history, match), [])

    return <MainComponent>
        <LoadingBarComponent/>
        <Paper className="main-paper" variant="outlined" square>
            {
                jobLoadingStore.jobNotFound
                    ? <NotFound msg={"Could not find data on the server anymore"}/>
                    : jobStore.job
                    ? children
                    : <div className="loading-spinner">
                        <LoadingSpinnerPie/>
                    </div>
            }
        </Paper>
    </MainComponent>;
});
