import React from 'react';
import './App.scss';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {ThemeProvider, unstable_createMuiStrictModeTheme} from '@material-ui/core/styles';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {environment} from "./env";
import {DebugStatus} from "./components/debug-components/DebugStatus";
import {BrowserRouter, Switch} from 'react-router-dom';
import {allRoutes} from './routing/routes';
import {theme} from "./style/theme";

// USED:
// https://react-bootstrap.github.io/layout/grid/#col-props
// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/function_components
// https://react-dnd.github.io/react-dnd/examples/other/native-files
// https://material-ui.com/guides/typescript/

// Future work?
// https://www.typescriptlang.org/docs/handbook/react.html
// https://reactjs.org/docs/hooks-intro.html
// Redux react
// https://www.bergqvist.it/blog/2019/5/17/typing_react_with_typescript

export const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ThemeProvider
                theme={environment.production ? createMuiTheme(theme) : unstable_createMuiStrictModeTheme(theme)}>
                <DndProvider backend={HTML5Backend}>
                    {environment.devMenu
                        ? <div className="position-absolute dev-menu" style={{zIndex: 1250}}><DebugStatus/></div>
                        : <></>}
                    <Switch>
                        {allRoutes}
                    </Switch>
                </DndProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}
