import React, {useEffect, useRef, useState} from "react";
import * as d3 from "d3";
import Margin from "../../../utils/margin";
import './ParetoChart.scss';
import {COLOR_UNCATEGORIZED, getColorByCat, PARETO_IDEAL_COLOR, SUPPLIER_CUMULATIVE_COLOR} from "../../../style/colors";
import {ParetoSpec} from "./pareto-util";
import {D3Selection} from "../../../utils/global";
import useTheme from "@material-ui/core/styles/useTheme";

export type CombinedParetoChartGroupedData = {
    supplier_val: number;
    cumulative_val: number;
    cumulative_val__perc: number;
    cumulative?: number;
}
export type CombinedParetoChartDataPoint = {
    s__name: string;
    total: CombinedParetoChartGroupedData;
    groups: { [group: string]: CombinedParetoChartGroupedData }
};

export type SubData = {
    key: string;
    group_value: number;
    group_start_value: number;
    group_cumulative_value: number;
    highlighted: boolean;
}
export type Data = {
    name: string;
    label: string;
    total: {
        value: number;
        cumulative_value: number;
    },
    active?: {
        value: number;
        cumulative_value: number;
    }
    graph_parts: SubData[];
}
export type SubDataPoint = [Data, SubData];

const DATA_ORIGIN: Data = {
    name: '__ORIGIN__',
    label: '',
    total: {value: 0, cumulative_value: 0},
    active: {value: 0, cumulative_value: 0},
    graph_parts: [],
}

export const defaultParetoParam = 0.8; // A : (1-A)
export const defaultMargin: Margin = {
    left: 40,
    right: 0,
    top: 10 + 12,
    bottom: 14,
};

export const byActiveThenTotal = (a: Data, b: Data) => {
    if (b.active || a.active) {
        const va = a.active ? a.active.value : 0;
        const vb = b.active ? b.active.value : 0;
        const cmp = vb - va;
        if (cmp !== 0) {
            return cmp;
        }
    }
    return b.total.value - a.total.value;
}

// const INIT_N1 = 1;
// const INIT_N2 = 17;
const INIT_N1 = 0;
const INIT_N2 = 20;
const showRightAxis = true;

// const rightAxisTitle = 'Cumulative spend lines (0 - 100%)';

function getViewPortion(n1: number, n2: number, totalDataPoints: number) {
    const realN1 = n1 === -1 ? 0 : n1;
    const realN2 = n2 === -1 ? totalDataPoints : n2;
    const viewPortionStart = realN1 / totalDataPoints;
    const nPointsInView = realN2 - realN1
    const viewPortionSize = nPointsInView / totalDataPoints;
    if (viewPortionStart >= 1) {
        return [0, 0];
    }
    if (viewPortionSize <= 0) {
        return [0, 0]
    }
    return [viewPortionStart, viewPortionSize];
}

type Props = {
    data?: CombinedParetoChartDataPoint[];
    selectedGroup?: string;
    hoveredGroup?: string;
    height: number;
    width?: number;
    labelMargin?: number;
    paretoParam?: number; // between ~0.6 and 1.0
    showPP?: boolean;
    onClick?: (d: Data) => void;
    onSubClick?: (d: SubDataPoint) => void;
}

// TODO: Remove animation and add ParetoGraph, invertarize comments of gdrive
export const AnimatedCombinedParetoChart: React.FC<Props> =
    ({
         data,
         selectedGroup,
         hoveredGroup,
         height,
         width,
         labelMargin,
         paretoParam,
         showPP,
         onClick,
         onSubClick,
     }) => {
        const theme = useTheme();
        if (width === undefined) {
            width = 255;
        }
        if (paretoParam === undefined) {
            paretoParam = defaultParetoParam;
        }
        if (onSubClick === undefined && onClick) {
            // When no special sub data point on-click is defined, just use the same as on-click for data
            onSubClick = ([d]) => onClick(d);
        }
        const margin = {
            left: labelMargin !== undefined ? labelMargin : defaultMargin.left,
            right: defaultMargin.right,
            top: defaultMargin.top,
            bottom: defaultMargin.bottom,
        }
        if (showRightAxis) {
            margin.right += 40;
        }
        const [n1, setN1] = React.useState<number>(INIT_N1);
        const [n2, setN2] = React.useState<number>(INIT_N2);

        let plotPercentage: number;
        let barData: Data[];
        let dataValueMax: number | undefined;
        let dataCumulativeValueMax: number | undefined;
        let showAxis: boolean;
        let dataLabel: string | undefined;
        let plotCumulativeLine;
        let [viewPortionStart, viewPortionSize] = [0, 1];
        let redLineX: number | undefined;
        let redLineY: number | undefined;
        let catAxisTitleEnd: string | undefined;
        const totalDataPoints = data?.length || 0;
        let dataPointsInView = totalDataPoints;
        if (data) {
            let filteredData: CombinedParetoChartDataPoint[];

            if (selectedGroup === undefined) {
                // No group selected
                if (n2 === -1) {
                    filteredData = data.filter((_, i) => i >= n1);
                } else {
                    filteredData = data.filter((_, i) => i >= n1 && i < n2);
                }
                dataCumulativeValueMax = data.length === 0 ? 1 : data[data.length - 1].total.cumulative_val;
                [viewPortionStart, viewPortionSize] = getViewPortion(n1, n2, totalDataPoints);
            } else {
                // Filtering can only happen afterwards
                filteredData = data;
            }
            barData = filteredData
                .map(d => ({
                    name: d.s__name,
                    label: d.s__name || '<empty>',
                    graph_parts: Object.entries(d.groups)
                        .map(([group_name, g_data]) => ({
                            key: group_name,
                            group_value: g_data.supplier_val,
                            group_start_value: 0,
                            group_cumulative_value: g_data.cumulative_val,
                            highlighted: // If something is selected, show only that group
                                selectedGroup !== undefined ? selectedGroup === group_name
                                    // If a hoverGroup is active, show only that group
                                    : hoveredGroup !== undefined ? hoveredGroup === group_name
                                    // Otherwise highlight all
                                    : true
                        })),
                    total: {
                        value: d.total.supplier_val,
                        cumulative_value: d.total.cumulative_val,
                    },
                    active: {
                        value: 0,
                        cumulative_value: 0,
                    },
                    total_combined_value: d3.sum(Object.values(d.groups).map(g_data => g_data.supplier_val)),
                }));

            if (selectedGroup !== undefined) {
                barData.forEach(d => {
                    const i = d.graph_parts.map(d => d.key).indexOf(selectedGroup);
                    if (i !== -1) {
                        const selectedData = d.graph_parts.splice(i, 1)[0];
                        d.graph_parts.unshift(selectedData);
                        d.active = {
                            value: selectedData.group_value,
                            cumulative_value: selectedData.group_cumulative_value,
                        }
                    } else {
                        d.active = undefined;
                    }
                    return d;
                })
                // barData = barData.filter(d => d.active_combined_value !== 0);
                barData.sort(byActiveThenTotal);

                const lastIndex1 = barData.findIndex(d => !d.active);
                const lastData = (lastIndex1 === -1 || lastIndex1 === 0) ? undefined : barData[lastIndex1 - 1];
                const groupedDataPoints = lastIndex1 === -1 ? 0 : (lastIndex1 + 1)
                dataCumulativeValueMax = lastData?.active?.cumulative_value || 1;
                if (n2 === -1) {
                    barData = barData.filter((_, i) => i >= n1);
                } else {
                    barData = barData.filter((_, i) => i >= n1 && i < n2);
                }
                [viewPortionStart, viewPortionSize] = getViewPortion(n1, n2, groupedDataPoints);
                dataPointsInView = groupedDataPoints;
                console.log('dataPointsInView', dataPointsInView);
            } else {
                barData.forEach(d => d.active = d.total);
            }

            // NOTE: This will change the axis when the category selection changes
            dataValueMax = d3.max(barData.map(d => d.active?.value || 0));
            // dataValueMax = d3.max(barData.map(d => d.total.value));

            plotCumulativeLine = {
                valueFun: d => d.active?.cumulative_value,
                color: SUPPLIER_CUMULATIVE_COLOR,
                label: 'Cumulative spend'
            };
            // dataCumulativeValueMax = d3.max(barData.map(d => d.active?.cumulative_value || 0));
            console.log('dataCumulativeValueMax', dataCumulativeValueMax);

            barData.forEach(d => d.graph_parts.forEach((d, i, arr) => {
                if (i > 0) {
                    d.group_start_value = arr[i - 1].group_start_value + arr[i - 1].group_value;
                }
            }))

            showAxis = true;
            dataLabel = 'Cumulative spend';

            if (!showPP) {
                redLineX = undefined;
                redLineY = undefined;
            } else {
                const viewPortionEnd = viewPortionStart + viewPortionSize;
                const paretoX = 1 - paretoParam;
                if (viewPortionStart <= paretoX && viewPortionEnd >= paretoX) {
                    // In view
                    redLineX = (paretoX - viewPortionStart) / viewPortionSize;
                    redLineY = paretoParam * (dataCumulativeValueMax || 1);
                } else {
                    // Out of view
                    redLineX = undefined;
                    redLineY = undefined;
                }
            }
            catAxisTitleEnd = `Suppliers (${Math.min(barData.length, dataPointsInView)} of ${dataPointsInView})`;

        } else {
            barData = [];
            plotPercentage = 1.0;
            dataValueMax = 1_000;
            plotCumulativeLine = undefined;
            dataCumulativeValueMax = undefined;
            showAxis = false;
            dataLabel = undefined;
            redLineX = showPP ? (1 - paretoParam) / plotPercentage : undefined;
            redLineY = showPP && (redLineX && redLineX <= 1) ? paretoParam * dataValueMax : undefined;
            catAxisTitleEnd = 'Suppliers';
        }
        if (catAxisTitleEnd) {
            margin.bottom += FONT_SIZE + 2;
        }


        // const plotGroupedCumulativeLine: (d: SubDataPoint) => number = ([,sd]) =>
        //     sd.group_value;


        const paretoSpec = new ParetoSpec(paretoParam);
        const paretoPercentageA = Math.round(paretoParam * 100);
        // const fitSamples = Math.round(data.length / 10);
        const fitSamples = width / 5;
        const paretoData = paretoSpec.getData(fitSamples, viewPortionStart, viewPortionSize);

        return <>
            {/*<div>*/}
            {/*    {[0, 10, 50, 1000, 1500].map(i => <button onClick={() => setN1(i)} key={i}>Head {i}</button>)}*/}
            {/*    <button onClick={() => setN1(n1 + 1)}>Head ++</button>*/}
            {/*    <button onClick={() => setN1(n1 - 1)}>Head --</button>*/}
            {/*    <br/>*/}
            {/*    {[10, 50, 100, 150, 300, 1000, 1500, 30000].map(i => <button onClick={() => setN2(i)}*/}
            {/*                                                                 key={i}>Tail {i}</button>)}*/}
            {/*    <button onClick={() => setN2(n2 * 1.5)}>Tail *1.5</button>*/}
            {/*    <button onClick={() => setN2(n2 / 1.5)}>Tail /1.5</button>*/}
            {/*    <button onClick={() => setN2(0.20 * totalDataPoints)}>20%</button>*/}
            {/*    <button onClick={() => setN2(0.25 * totalDataPoints)}>25%</button>*/}
            {/*    <button onClick={() => setN2(0.50 * totalDataPoints)}>50%</button>*/}
            {/*    <button onClick={() => setN2(0.75 * totalDataPoints)}>75%</button>*/}
            {/*    <button onClick={() => setN2(1.00 * totalDataPoints)}>100%</button>*/}
            {/*    <hr/>*/}
            {/*    <p style={{textAlign: 'center'}}>Top {n1} - {n2} suppliers only</p>*/}
            {/*</div>*/}
            <CombinedAnimatedCumulativeCurveBarChart
                width={width}
                height={height}
                margin={margin}
                data={{data: barData, label: dataLabel, max: dataValueMax}}
                selectedGroup={selectedGroup}
                // axis
                leftAxis={showAxis}
                rightAxis={showAxis}
                // data plot
                plotDataLine={plotCumulativeLine}
                plotDataMax={dataCumulativeValueMax}
                // line plot
                linePlot={{
                    points: paretoData,
                    label: `Pareto ${paretoPercentageA}/${100 - paretoPercentageA}`,
                    color: PARETO_IDEAL_COLOR,
                }}
                // Lines
                redLineX={redLineX} redLineY={redLineY}
                onClick={onClick}
                onSubClick={onSubClick}
                catAxisTitleEnd={catAxisTitleEnd}
                // TODO: This must be taken out, it just triggers a rendering, that's it
                x={n1 * 10000000 + n2}
            />
        </>;
    };

const FONT_SIZE = 12;
const BAR_PADDING = 0.2;
const LABEL_SPACING = 4;

const D = 0.5;
// const DURATION_1 = 800;
// const DURATION_2 = 200;
const DURATION_1 = 700 * D; // Reorder stacks
const DURATION_2 = 125 * D; // Moving animation
const DURATION_3 = 250 * D; // Sorting animation

export type DOP = (x: SubDataPoint) => any;

const hasLabels = true;
const setFill: DOP = ([d, sd]) => sd.highlighted
    ? getColorByCat(sd.key)
    : d3.hsl(getColorByCat(sd.key)).copy({opacity: .2});

const CombinedAnimatedCumulativeCurveBarChart: React.FC<{
    width: number, height: number, margin: Margin,
    data: { data: Data[], max?: number, label?: string },
    selectedGroup?: string,
    leftAxis?: boolean, // $
    rightAxis?: boolean, // %
    onClick?: (d: Data) => void,
    onSubClick?: (d: SubDataPoint) => void,
    plotDataLine?: { valueFun: (d: Data) => number, label?: string, color: any },
    plotDataMax?: number,
    linePlot?: { points: { x: number, y: number }[], label: string, color: any },
    redLineX?: number,
    redLineY?: number,
    catAxisTitleEnd?: string,
    x?: number,
}> = (props) => {
    const d3Container = useRef<SVGSVGElement>(null);
    const graphWidth = props.width - props.margin.left - props.margin.right;
    const graphHeight = props.height - props.margin.top - props.margin.bottom;
    const xRange = [0, graphWidth];
    const yRange = [graphHeight, 0];

    const [oldSelectedGroup, setOldSelectedGroup] = useState<string | undefined | null>(null);

    const barMax = props.data.max !== undefined
        ? props.data.max
        // : (d3.barMax(data, d => Math.barMax(d.valueBar, d.valuePlot)) || 1)
        : 1

    const barFill = setFill;

    // set the range and domain for the axis
    let catAxis = d3.scaleBand()
        .domain(props.data.data.map(d => d.name))
        .range(xRange)
    const BAR_SIZE = catAxis.bandwidth();
    const BAR_WIDTH = BAR_SIZE * (1 - BAR_PADDING);
    const BAR_SPACING = BAR_PADDING / 2 * BAR_WIDTH;

    let barValueAxis = d3.scaleLinear().domain([0, barMax]).range(yRange)
    let lineValueAxis = !props.plotDataMax
        ? barValueAxis
        : d3.scaleLinear().domain([0, props.plotDataMax]).range(yRange)
    let lineFitXAxis = d3.scaleLinear().domain([0, 1]).range(xRange);
    let lineFitYAxis = d3.scaleLinear().domain([0, 1]).range(yRange);

    function fixBarLabel(this: any) {
        const d3Element = d3.select(this);
        const d: Data = d3Element.datum() as any;
        const x = catAxis(d.name) as number + BAR_SPACING;
        d3Element.attr('x', x);
        return;
        const todo = () => {
            // TODO: Automatically pull labels back into view, and reset after animations
            const element = this as SVGTextElement;
            const bbox = element.getBBox();
            const d3Element = d3.select(this);
            const d: Data = d3Element.datum() as any;
            // const x = Number(d3Element.attr('x')) || 0
            const x = catAxis(d.name) as number + BAR_SPACING;
            // const overflow = bbox.x + bbox.width - graphWidth
            const overflow = x + bbox.width - graphWidth
            console.log('overflow', overflow, 'bbox.width', bbox.width, element);
            if (overflow > 0) {
                // If the text will overflow the graph, adjust the position
                const newX = x - overflow;
                console.log(`x => ${x} -> ${newX} (${d.name})`)
                d3Element.attr('x', newX);
            }
        }
    }

    function barsOnEnter(s: D3Selection<Data>) {
        const barWrapper = s.append('rect')
            .classed('hover-overlay', true)
            .attr('height', graphHeight)
            .attr('width', BAR_SIZE)
        // dataOnEnter.append('rect')
        //     .classed('bar-gb', true)
        if (props.onClick) {
            const onClick = props.onClick;
            barWrapper
                .classed('clickable', true)
                .on('click', function () {
                    const data = d3.select(this).datum() as Data;
                    onClick(data);
                })
        }
    }

    function barsOnUpdate(s: D3Selection<Data>) {
        s.select('rect.hover-overlay')
            .attr('x', d => (catAxis(d.name) as number))
            .attr('y', 0)
    }

    function subBarsOnEnter(s: D3Selection<SubDataPoint>) {
        const subBar = s.append('rect')
            .classed('bar-part', true) // .attr('data-group', ([, sd]) => '' + sd.key)
            .attr('width', BAR_WIDTH)
            .attr('height', ([, sd]) => barValueAxis(sd.group_start_value) - barValueAxis(sd.group_start_value + sd.group_value))
            .attr('fill', barFill)
            .attr('x', ([d]) => (catAxis(d.name) as number) + BAR_SPACING)
            .attr('y', ([, sd]) => barValueAxis(sd.group_start_value + sd.group_value))

        if (props.onSubClick) {
            const onSubClick = props.onSubClick;
            subBar
                .classed('clickable', true)
                .on('click', function () {
                    const data = d3.select(this).datum() as SubDataPoint;
                    onSubClick(data);
                })
        }
    }

    function subBarsOnUpdate(subDataOnUpdate: D3Selection<SubDataPoint>, dataOnUpdate: D3Selection<Data>) {
        subDataOnUpdate.classed('active', ([, sd]) => sd.highlighted)
        const onReorderTransitionEnd = subDataOnUpdate
            .select<SVGRectElement>('rect')
            .attr('fill', setFill)
            .transition('t1')
            .duration(DURATION_1)
            .attr('fill', barFill)
            .attr('height', ([, sd]) => barValueAxis(sd.group_start_value) - barValueAxis(sd.group_start_value + sd.group_value))
            .attr('y', ([, sd]) => barValueAxis(sd.group_start_value + sd.group_value))
            .end()

        let labelAnimation = dataOnUpdate
            .select('text')
            .transition('t2')
            .duration(DURATION_1)
            .attr("y", d => barValueAxis(d.active?.value || 0) - LABEL_SPACING)

        // const onReorderTransitionEnd = subDataOnUpdate.select<SVGRectElement>('rect').transition('t2').delay(DURATION_1 / 4).end()

        return onReorderTransitionEnd.then(() => {
            const TOP_SORT = props.data.data.findIndex(d => d.active === undefined);
            console.log('TOP_SORT = ' + TOP_SORT)
            const isSmallTop = TOP_SORT <= 3;

            let labelAnimation = dataOnUpdate.select('text').transition('t2')
            let barAnimation = subDataOnUpdate.select<SVGRectElement>('rect').transition('t2');
            if (isSmallTop) {
                if (hasLabels) {
                    labelAnimation = labelAnimation.duration(DURATION_3);
                }
                barAnimation = barAnimation.duration(DURATION_3)
            } else {
                const PARAM_SORT_TRANSITION_DELAY = DURATION_3 / (TOP_SORT + 1);
                const delaysMap = Object.fromEntries(props.data.data.map((d, i) => {
                    let delay: number;
                    if (i < TOP_SORT) {
                        // Delay to top
                        delay = (TOP_SORT - i - 1) * PARAM_SORT_TRANSITION_DELAY;
                    } else {
                        // Do not delay the tail
                        delay = 0;
                    }
                    return [d.name, delay];
                }));
                if (hasLabels) {
                    labelAnimation = labelAnimation.duration(DURATION_2).delay(d => delaysMap[d.name])
                }
                barAnimation = barAnimation.duration(DURATION_2).delay(([d]) => delaysMap[d.name])
            }

            if (labelAnimation) {
                labelAnimation
                    // .attr("x", d => catAxis(d.name) as number + BAR_SPACING)
                    .each(fixBarLabel)
            }
            barAnimation
                .attr('width', BAR_WIDTH)
                .attr('x', ([d]) => (catAxis(d.name) as number) + BAR_SPACING)

            return barAnimation.end();
        })
            .catch(e => {
                console.error(e);
            })
    }

    useEffect(() => {
        if (!d3Container.current) {
            return;
        }
        console.log('CombinedCumulativeCurveBarChart.change!');
        const groupChanged = oldSelectedGroup !== props.selectedGroup;
        if (!groupChanged) {
            if (props.selectedGroup !== undefined)
                return;
        }
        const isToReset = oldSelectedGroup !== undefined && props.selectedGroup === undefined;
        console.log('isToReset=', isToReset);

        const rootG = d3.select(d3Container.current as SVGElement)
            .attr("transform", "translate(" + props.margin.left + "," + props.margin.top + ")");

        // append the rectangles for the bar chart
        const dataSelection = rootG
            .select('g.data-bars')
            .selectAll<SVGGElement, Data>('g.bar-wrapper')
            .data(props.data.data, (d: Data) => d.name);

        // Draw the bar wrappers
        const dataOnEnter = dataSelection.enter()
            .append('g')
            .classed('bar-wrapper', true)
            .call(barsOnEnter)

        // Update the bars
        const dataOnUpdate = dataSelection.merge(dataOnEnter).call(barsOnUpdate);

        // Add the sub bars
        const subDataSelection = dataOnUpdate.selectAll<SVGGElement, SubDataPoint>('g.bar-parts')
            .data<SubDataPoint>(d => d.graph_parts.map(p => [d, p]), ([, sd]) => sd.key)
        const subDataOnEnter = subDataSelection.enter()
            .append('g')
            .classed('bar-parts', true)
            .call(subBarsOnEnter)

        // Update the sub bars
        const onReorderTransitionEnd =
            subBarsOnUpdate(subDataOnEnter.merge(subDataSelection), dataOnUpdate);

        // Remove bars out of view
        dataSelection.exit()
            .remove()

        // const barGroupWrapper = dataOnEnter
        //     .selectAll('rect.bar-part')
        //     .join('rect')
        //     .classed('bar-part', true)
        //     .attr('data-group', ([, sd]) => '' + sd.key)
        //     .attr('x', ([d]) => (catAxis(d.name) as number))
        //     .attr('y', ([, sd]) => barValueAxis(sd.group_start_value + sd.group_value))
        //     .attr('width', catAxis.bandwidth())
        //     .attr('height', ([, sd]) => barValueAxis(sd.group_start_value) - barValueAxis(sd.group_start_value + sd.group_value))
        //     // .attr('fill', ([d, sd]) => vizColor(d.name + '__' + sd.key))
        //     // .attr('fill', ([d, sd]) => vizColor(sd.key))
        //     .attr('fill', ([, sd]) =>
        //         sd.key === selectedGroup ? 'red' : vizColor(sd.key)
        //     )

        if (hasLabels) {
            const barLabels = dataOnEnter.append('text')
                .classed('bar-label', true)
                .text(d => d.label ? d.label : `${d.name}`)
                // .attr("x", d => catAxis(d.name) as number + BAR_SPACING)
                .attr("y", d => barValueAxis(d.active?.value || 0) - LABEL_SPACING)

                // dataOnUpdate.select('text')
                //     .transition()
                //     // .delay(DURATION_1)
                //     .delay((d, i) => DURATION_1 + (i < TOP_SORT ? i * 10 : 0))
                //     .duration(DURATION_2)
                //     .attr("x", d => catAxis(d.name) as number + BAR_SPACING)
                //     .attr("y", d => barValueAxis(d.active.value) - LABEL_SPACING)

                // const barLabels = dataOnEnter.append('text')
                .attr('dominant-baseline', 'text-bottom')

            // dataOnUpdate.select('text')
            //     .transition('t1')
            //     // .delay(DURATION_1)
            //     .delay((d, i) => DURATION_1 + (i < TOP_SORT ? (TOP_SORT - i - 1) * PARAM_SORT_TRANSITION_DELAY : 0))
            //     .duration(DURATION_2)
            //     .attr("x", d => catAxis(d.name) as number + BAR_SPACING)
            //     .attr("y", d => barValueAxis(d.active.value) - LABEL_SPACING)

            // const barLabels = dataOnEnter.append('text')

            barLabels.each(fixBarLabel)
        }

        // add the horizontal Axis
        const hAxisGroup = rootG.select<SVGGElement>("g.cat-axis");
        const hAxisLine: d3.Selection<SVGLineElement, unknown, null, undefined> = hAxisGroup.select('line').node()
            ? hAxisGroup.select('line')
            : hAxisGroup.append('line');
        hAxisLine
            .attr('x1', 0)
            .attr('y1', graphHeight)
            .attr('x2', graphWidth)
            .attr('y2', graphHeight)
        if (props.catAxisTitleEnd) {
            // Add horizontal axis at the end
            const hAxisTitleGroup = rootG.select<SVGGElement>("g.cat-axis-title");
            const hAxisTitle: d3.Selection<SVGTextElement, unknown, null, undefined> = hAxisTitleGroup.select('text').node()
                ? hAxisTitleGroup.select('text')
                : hAxisTitleGroup.append('text')
            hAxisTitle
                .text(`${props.catAxisTitleEnd}`)
                .attr("x", graphWidth)
                .style('text-anchor', 'end')
                .attr("y", props.height)
                .attr("dy", "-2em")
        }
        // rootG.append("g")
        //     .classed('category-axis', true)
        //     .attr("transform", "translate(0," + graphHeight + ")")
        //     .call(d3.axisBottom(catAxis)
        //         // .tickFormat(() => '') // Hide the labels
        //         // .tickSize(0) // Hide ticks
        //         .tickValues([]) // Hide the ticks AND labels
        //     )

        // add the vertical Axis
        // Build sensible graph for values [0-100], [0-1000], [0-10K], [0-100K], etc
        const barAxis = rootG.select<SVGGElement>("g.bar-value-axis");
        barAxis.call(props.leftAxis
            ? d3.axisLeft(barValueAxis).ticks(5).tickFormat(v => d3.format("~s")(v))
            : d3.axisLeft(barValueAxis).tickValues([]) // Hide the ticks AND labels
        )
        const lineAxis = rootG.select<SVGGElement>("g.line-value-axis")
            .attr('transform', `translate(${graphWidth}, 0)`)
        lineAxis.call(props.rightAxis
            ? d3.axisRight(lineFitYAxis).ticks(5).tickFormat(v => Math.round(Number(v) * 100) + '%')
            : d3.axisRight(lineFitYAxis).tickValues([]) // Hide the ticks AND labels
        )

        const drawLineThroughOrigin = true;
        const curveDataAnimation = false;
        const curveDataUpdateAfterAnimation = true;
        const LOWER_P = .8
        if (props.plotDataLine) {
            const plotFunc = props.plotDataLine.valueFun;
            // Add cumulative line
            const cumulativeLine = d3.line<Data>(
                d => d.name === '__ORIGIN__' ? 0 : (catAxis(d.name) as number + BAR_SIZE),
                d => d.name === '__ORIGIN__' ? lineValueAxis(0) : lineValueAxis(plotFunc(d)) as number,
            )

            const lowerLine = d3.line<Data>(
                d => d.name === '__ORIGIN__' ? 0 : (catAxis(d.name) as number + BAR_SIZE),
                d => d.name === '__ORIGIN__' ? lineValueAxis(0) : lineValueAxis(plotFunc(d) * LOWER_P) as number,
            )


            let curveData: Data[] = props.data.data;
            if (drawLineThroughOrigin) {
                curveData = [DATA_ORIGIN].concat(curveData);
            }
            curveData = curveData.filter(d => d.active);
            const zeroData = curveData.map<[number, number]>((d, i) => ([i * graphWidth / (curveData.length - 1), graphHeight]));
            const zeroLine = d3.line()(zeroData);

            const curveDataSelection = rootG.select('g.data-curve')
                .selectAll<SVGPathElement, Data>('path')
                .data([curveData])

            const curveDataEnter = curveDataSelection.enter()
                .append('path')
                .attr("stroke", props.plotDataLine.color)
            const curveDataUpdate = curveDataSelection.merge(curveDataEnter)
                .attr('stroke-opacity', 1)

            if (curveDataAnimation) {
                curveDataSelection
                    .transition('t1')
                    .duration(100)
                    .attr('stroke-opacity', 0)

                onReorderTransitionEnd.then(() => {
                    curveDataUpdate
                        // .attr('d', () => zeroLine)
                        .attr('d', lowerLine)
                        .transition('t1')
                        .ease(d3.easeExpOut)
                        .duration(1000)
                        .attr('d', cumulativeLine)
                        .attr('stroke-opacity', 1)
                    console.log('onReorderTransitionEnd')
                })
            } else if (curveDataUpdateAfterAnimation) {
                curveDataUpdate
                    .attr('stroke-opacity', 0)
                onReorderTransitionEnd.then(() => {
                    curveDataUpdate
                        .attr('d', cumulativeLine)
                        .attr('stroke-opacity', 1)
                })
            } else {
                curveDataUpdate
                    .attr('d', cumulativeLine)
            }


            // // Draw the bar wrappers
            // const dataOnEnter = dataSelection.enter()
            //     .append('g')
            //     .classed('bar-wrapper', true)
            //     .call(barsOnEnter)

            // .join('path')
            // .attr("fill", "none")
            // .attr("stroke", props.plotDataLine.color)
            // .attr("stroke-width", 1.5)
            // .attr("d", cumulativeLine)
        }

        if (props.linePlot) {
            type XY = {
                x: number;
                y: number;
            }
            const line = d3.line<XY>(
                d => lineFitXAxis(d.x),
                d => lineFitYAxis(d.y),
            )
            const fitDataSelection = rootG.select('g.fit-curve')
                .selectAll<SVGPathElement, XY>('path')
                .data([props.linePlot.points]);

            const fitDataEnter = fitDataSelection.enter()
                .append('path')
                .attr('stroke', props.linePlot.color)
            const fitDataUpdate = fitDataSelection.merge(fitDataEnter)
                .attr('stroke-opacity', 1)

            if (curveDataAnimation) {
                fitDataSelection
                    .transition('t1')
                    .duration(100)
                    .attr('stroke-opacity', 0)
                const lowerLine = d3.line<XY>(
                    d => lineFitXAxis(d.x),
                    d => lineFitYAxis(d.y * LOWER_P),
                )
                onReorderTransitionEnd.then(() => {
                    fitDataUpdate
                        .attr('d', lowerLine)
                        .transition('t1')
                        .ease(d3.easeExpOut)
                        .duration(1000)
                        .attr('d', line)
                        .attr('stroke-opacity', 1)
                })
            } else if (curveDataUpdateAfterAnimation) {
                fitDataUpdate
                    .attr('stroke-opacity', 0)
                onReorderTransitionEnd.then(() => {
                    fitDataUpdate
                        .attr('d', line)
                        .attr('stroke-opacity', 1)
                })
            } else {
                fitDataUpdate
                    .attr('d', line)
            }
        }

        // redLineX
        const redLineXData: number[] = props.redLineX ? [props.redLineX * graphWidth] : [];
        console.log('redLineXData', redLineXData)
        const rlXData = rootG.select('g.reference-line-x')
            .selectAll<SVGGElement, number>('g')
            .data(redLineXData)
        const rlXEnter = rlXData.enter()
            .append('g')
        rlXEnter
            .append('line')
            .attr("y1", 0)
            .attr("y2", graphHeight)
            .attr('stroke', props.linePlot?.color || 'black')
        rlXEnter
            .append('text')
            .attr('fill', props.linePlot?.color || 'black')
            .text('20%')
            .attr('dominant-baseline', 'hanging')
            .attr('y', graphHeight + 2)
        const rlXUpdate = rlXEnter.merge(rlXData)
        rlXUpdate.select('line')
            .attr("x1", d => d)
            .attr("x2", d => d)
        rlXUpdate.select('text')
            .attr('x', d => d)
        rlXData.exit().remove()

        // redLineY
        const redLineYData: number[] = props.redLineY ? [lineValueAxis(props.redLineY)] : [];
        console.log('redLineYData', redLineYData)
        const rlYData = rootG.select('g.reference-line-y')
            .selectAll<SVGGElement, number>('g')
            .data(redLineYData)
        const rlYEnter = rlYData.enter()
            .append('g')
        rlYEnter
            .append('line')
            .attr('stroke', props.linePlot?.color || 'black')
            .attr("x1", 0)
            .attr("x2", graphWidth)
        rlYEnter
            .append('text')
            .attr('fill', props.linePlot?.color || 'black')
            .text('80%')
            .attr('dominant-baseline', 'hanging')
            .attr('x', 2)
        const rlYUpdate = rlYEnter.merge(rlYData)
        rlYUpdate.select('line')
            .attr("y1", d => d)
            .attr("y2", d => d)
        rlYUpdate.select('text')
            .attr('y', d => d + 1)
        rlYData.exit().remove()

        if (curveDataAnimation) {
            // N/A
        } else if (curveDataUpdateAfterAnimation) {
            rlXUpdate.select('line, text')
                .attr('stroke-opacity', 0)
            rlYUpdate.select('line, text')
                .attr('stroke-opacity', 0)
            onReorderTransitionEnd.then(() => {
                rlXUpdate.select('line, text')
                    .attr('stroke-opacity', 1)
                rlYUpdate.select('line, text')
                    .attr('stroke-opacity', 1)
            })
        } else {
            // Keep opacity
        }

        const showLegend = true;
        if (showLegend) {
            const legendData: { color: any, label: string }[] = [];
            const legendWidth = 150;

            legendData.push({color: COLOR_UNCATEGORIZED, label: '<uncategorized>'});
            if (props.data) {
                // legendData.push({color: COLOR_DATA_PLOT, label: props.data.label || 'Bars'});
            }
            if (props.plotDataLine) {
                legendData.push({color: props.plotDataLine.color, label: props.plotDataLine.label || 'Line'});
            }
            if (props.linePlot) {
                legendData.push({color: props.linePlot.color, label: props.linePlot.label || 'Fit'});
            }

            const legendOnEnter = rootG.select('g.legend')
                .selectAll('g.legend-entry')
                .data(legendData)
                .enter()
                .append('g')
                .classed('legend-entry', true)

            legendOnEnter
                .append('circle')
                .attr('cx', graphWidth - legendWidth)
                .attr('cy', (d, i) => graphHeight * (1 / 3) - i * FONT_SIZE * 1.5)
                .attr('r', 6)
                .style("fill", d => d.color)
                .style("font-size", FONT_SIZE)
            legendOnEnter.append('text')
                .text(d => d.label)
                .attr('x', graphWidth - legendWidth + 12)
                .attr('y', (d, i) => graphHeight * (1 / 3) + 1 - i * FONT_SIZE * 1.5)
                .attr('alignment-baseline', 'middle')
                .style("fill", d => d.color)
        }


        setOldSelectedGroup(props.selectedGroup);

    }, [props, d3Container.current])

    return <svg
        className={'combined pareto-chart'}
        viewBox={`0 0 ${props.width} ${props.height}`}
        style={{width: '100%', height: 'auto'}}>
        <g ref={d3Container}>
            <g className="data-bars"/>
            <g className="data-curve"/>
            <g className="fit-curve"/>
            <g className="bar-value-axis axis"/>
            <g className="line-value-axis axis"/>
            <g className="cat-axis axis"/>
            <g className="cat-axis-title axis-title"/>
            <g className="legend"/>
            <g className="reference-line reference-line-x"/>
            <g className="reference-line reference-line-y"/>
        </g>
    </svg>;
};
