import React from "react";
import {useStores} from "../../stores";
import {useHistory} from "react-router";
import {observer} from "mobx-react-lite";
import {useDrop} from "react-dnd";
import {DropFile} from "../../components/drop-file/DropFile";
import {MainComponent} from "../../components/main/MainComponent";
import {Paper} from "@material-ui/core";
import {LoadingBarComponent} from "../../components/loading-bar/LoadingBarComponent";
import {JobHistoryState} from "../../routing/routes";
import Typography from "@material-ui/core/Typography";
import './HomePage.scss';
import {TEMPLATE_NAME, TEMPLATE_URL} from "../../env";
import Button from "@material-ui/core/Button";
import {buildDropSpec} from "../../components/drop-file/dropSpecification";


export const HomePage: React.FC = observer(() => {
    const history = useHistory<JobHistoryState>();
    const {jobLoadingStore} = useStores();

    const [dropProps, drop] = useDrop(buildDropSpec(jobLoadingStore.getUploadNewJobDropSpec(history)));

    return <MainComponent dropElement={drop}>
        <LoadingBarComponent/>
        <Paper className="main-paper" variant="outlined" square>
            <div className="home-page">
                <Typography variant="h3" component="h1" align="center">
                    Upload spend sheet
                </Typography>
                <Typography variant="subtitle1" component="p" align="center">
                    Use this template to start:
                    <a className="file-button" href={TEMPLATE_URL}>
                        <Button>{TEMPLATE_NAME}</Button>
                    </a>
                </Typography>
                {/*<Grid container item className="header" alignItems="center" justify="center">*/}
                {/*    <Grid item>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                <DropFile
                    uploadErrorMessage={jobLoadingStore.error}
                    dropProps={dropProps}
                    droppedFile={jobLoadingStore.droppedFile}
                    isBusy={jobLoadingStore.isUploading}/>
            </div>
        </Paper>
    </MainComponent>;
});
